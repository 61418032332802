<template>
<div>
  <v-fade-transition mode="out-in">
    <router-view v-if="render" />
    <loading-screen v-else />
  </v-fade-transition>
</div>
</template>

<script>
export default {
  name: 'App',
  components: {
    "loading-screen": () => import("./components/Utils/LoadingScreen.vue"),
  },
  data: () => ({
    render: false,
  }),
  created() {
    this.render = false;
    this.getUser();
  },
  methods: {
    getUser() {
      if (localStorage.getItem("token")) {
        let token = {token: localStorage.getItem("token")}
        this.axiosCall("/auth/current_user", "POST", token)
          .then((res) => {
            if (res.data.access_token) {
              localStorage.setItem("token", res.data.access_token);
              this.$store.dispatch("setUser", res.data.user);
              let data = res.data.user;
              if(this.$route.path == "/"){
                  this.$router.push("/" + data.role + "/dashboard", () => {});
                  setTimeout(() => {
                    this.$router.go(0)
                  }, 0);
                }
            }
            else{
              localStorage.clear();
              this.$router.push("/", () => {});
            }
            setTimeout(() => {
            this.render = true;
            }, 1000);
          })
          .catch((error) => {
            console.log(error);
            localStorage.clear();
            this.$router.push("/", () => {});
            setTimeout(() => {
            this.render = true;
            }, 1000);
          });
      } else {
        this.render = true;
        localStorage.clear();
        this.$router.replace("/", () => {});
      }
    },

  },
};
</script>