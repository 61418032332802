var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"card-style rounded-1"},[_c('v-row',{staticClass:"ma-2"},[_c('v-col',{staticClass:"flex-items",attrs:{"cols":"12","md":"9"}},[_c('h2',[_vm._v("My Pending Verification Applications")])]),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"prepend-inner-icon":"search","label":"Search applications","single-line":"","hide-details":"","color":"#38435a"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-card',{staticClass:"mb-1 dt-container",attrs:{"elevation":"0","outlined":""}},[_c('v-data-table',{attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.data,"item-key":"id","options":_vm.options,"hide-default-footer":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.applicant",fn:function(ref){
var item = ref.item;
return [(item.data)?_c('span',[_vm._v(_vm._s((_vm.urlDecode(item.data.first_given_names || '') || '').toUpperCase())+" "+_vm._s((_vm.urlDecode(item.data.family_names || '') || '').toUpperCase()))]):_vm._e()]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(_vm.$store.state.user.role == 'executive_supervisor' || _vm.$store.state.user.role == 'agent')?_c('div',[(item.submitted == 1)?_c('v-chip',{staticClass:"chip-status-1 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap",attrs:{"small":""}},[_c('span',[_vm._v("Completed")])]):_c('span',[(item.assignedTo == null || item.assignedTo == '')?_c('span',[_c('v-chip',{staticClass:"chip-status-3 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap",attrs:{"small":""}},[_c('span',[_vm._v("Available")])])],1):_c('span',[_c('v-chip',{staticClass:"chip-status-4 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap",attrs:{"small":""}},[_c('span',[_vm._v("Processing")])])],1)])],1):_vm._e()]}},{key:"item.submitted_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.submitted_at_timestamp))+" ")]}},{key:"item.time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.submitted_at_timestamp))+" ")]}},{key:"item.commission_status",fn:function(ref){
var item = ref.item;
return [(item.cardStatus == 'CLOSED')?_c('span',[(item.cardStatus == 'CLOSED')?_c('v-chip',{staticClass:"chip-status-1 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap",attrs:{"small":""}},[_c('span',[_vm._v("Payable")])]):_c('v-chip',{staticClass:"chip-status-4 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap",attrs:{"small":""}},[_c('span',[_vm._v("Validating Commission...")])])],1):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.cardStatus != 'CLOSED')?_c('v-btn',{attrs:{"outlined":"","color":"#166ecf","to":'/' + _vm.$store.state.user.role + '/search/view/'+ item.token + '/'}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-eye")]),_vm._v(" View ")],1):_vm._e()]}}],null,true)})],1),_c('v-row',{staticClass:"ma-2",attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"flex-items",attrs:{"cols":"auto"}},[_c('span',{staticClass:"px-2"},[_vm._v("Row Per Page: ")]),_c('span',[_c('v-select',{staticClass:"rounded-lg",staticStyle:{"max-width":"90px"},attrs:{"dense":"","outlined":"","hide-details":"","value":_vm.options.itemsPerPage,"items":_vm.perPageChoices},on:{"change":function($event){_vm.options.itemsPerPage = parseInt($event, 10)}}})],1)]),_c('v-col',{staticClass:"mx-auto text-truncate",attrs:{"cols":"auto","no-gutters":""}},[_vm._v(" Showing "+_vm._s(_vm.data.length > 0 ? ((_vm.paginationData.currentPage - 1) * _vm.options.itemsPerPage) + 1 : 0)+" to "+_vm._s(_vm.data.length > 0 ? ((_vm.paginationData.currentPage - 1) * _vm.options.itemsPerPage) + _vm.data.length : 0)+" of "+_vm._s(_vm.paginationData.count)+" entries ")]),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-pagination',{staticClass:"rounded-lg",attrs:{"total-visible":7,"color":"#38435a","length":_vm.paginationData.lastPage},model:{value:(_vm.options.page),callback:function ($$v) {_vm.$set(_vm.options, "page", $$v)},expression:"options.page"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }