<template>
  <div class="pa-5 content-db-style">
    <v-card class="card-style rounded-1" >
    <v-card-title class="pa-0">
       <v-row class="mb-1" no-gutters>
      <v-col cols="12" sm="6" :align="currentSize >= 2 ? 'left' : 'center'" class="px-5 py-2">
        <h4 class="mt-1">TOTAL</h4>
      </v-col>
      <v-col cols="12" sm="6" :align="currentSize >= 2 ? 'right' : 'center'" class="px-5 py-2">
          <div class="drp-div-style">
            <date-range-picker class="drp-style" v-model="dateRange" format="MMM D, YYYY" :options="dr_options" />
            <v-icon class="pa-0">mdi-chevron-down</v-icon>
          </div>
      </v-col>
      <v-col cols="12">
        <v-divider></v-divider>
      </v-col>
    </v-row>
    </v-card-title>
    <v-row class="ma-2">
      <v-col cols="12">
        <v-row class="stats-style">
          <v-col cols="12" md="6" class="align-center">
            <!--START MEDIA-->
            <div class="my-3 text-center">
              <div>
                <img src="../../assets/img/oc-growing.svg" class="stats-img" alt="">
              </div>
              <h5>APPLICATIONS PROCESSED</h5>
              <h1>0</h1>
              <div>
                <v-chip
                class="chip-status-2 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-if="getStatsComparedTrend(0) == 'red'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{getStatsComparedChange(0)}}%</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{getStatsComparedChange(0)}}%</span>
              </v-chip>

              <span class="chip-text">{{formatMoney(0) + " " + "Change"}}</span>
              </div>
              <div v-if="data.same_time_comparison">
              <v-chip
                class="chip-status-2 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-if="getStatsComparedTrend(0) == 'red'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{getStatsComparedChange(0,0)}}%</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{getStatsComparedChange(0,0)}}%</span>
              </v-chip>
              <span class="chip-text">{{formatMoney(0)+ " " + "yesterday" +  " " + "@" + "time" }}</span>
              </div>
            </div>
            <!--END MEDIA-->
          </v-col>
            <v-divider class="divider-style"
              vertical
            ></v-divider>
          <v-col cols="12" md="6" class="align-center">
             <!--START MEDIA-->
            <div class="my-3 text-center">
              <div>
                <img src="../../assets/img/oc-money-profits.svg" class="stats-img" alt="">
              </div>
              <h5>COMMISSIONS EARNED</h5>
              <h1>0</h1>
              <div>
                <v-chip
                class="chip-status-2 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-if="getStatsComparedTrend(0) == 'red'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{getStatsComparedChange(0)}}%</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{getStatsComparedChange(0)}}%</span>
              </v-chip>

              <span class="chip-text">{{formatMoney(0) + " " + "Change"}}</span>
              </div>
              <div v-if="data.same_time_comparison">
              <v-chip
                class="chip-status-2 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-if="getStatsComparedTrend(0) == 'red'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{getStatsComparedChange(0,0)}}%</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{getStatsComparedChange(0,0)}}%</span>
              </v-chip>
              <span class="chip-text">{{formatMoney(0)+ " " + "yesterday" +  " " + "@" + "time" }}</span>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    </v-card>
  </div>
</template>

<script>
import moment from "moment";
  export default {
    data: () => ({
        mini: false,
        startDate: null,
        endDate: null,
        search: '',
        data: {},
        loading: false,
        dateRange: [],
        dr_options: {
          ranges: {
          'Today': [moment(), moment()],
          'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
          'Last 7 Days': [moment().subtract(6, 'days'), moment()],
          'Last 30 Days': [moment().subtract(29, 'days'), moment()],
          'This Month': [moment().startOf('month'), moment().endOf('month')],
          'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
          },
          "buttonClasses": "btn btn-lg",
          "applyButtonClasses": "btn-primary"
          }
    }),
    computed: {
      currentSize() {
        const breakpoint = this.$vuetify.breakpoint;
        if (breakpoint.xs) return 1;
        if (breakpoint.sm) return 2;
        if (breakpoint.md) return 3;
        if (breakpoint.lg) return 4;
        if (breakpoint.xl) return 5;
        return 0;
      }
    },
    watch: {
    dateRange: {
        handler(data){
          if(data.length == 2){
          var date1 = new Date(data[0]);
          this.startDate = parseInt(date1.getDate()) + '-' + parseInt(String(date1.getMonth() + 1).padStart(2, '0')) + '-' + date1.getFullYear();
          var date2 = new Date(data[1]);
          this.endDate = parseInt(date2.getDate()) + '-' + parseInt(String(date2.getMonth() + 1).padStart(2, '0')) + '-' + date2.getFullYear();
          this.initialize();
          }
        }
      }
    },
    methods: {
        initialize(){
          // if(this.startDate && this.endDate){
          //   this.loading = true
          // this.axiosCall('/dashboard/getStatistics?start_date=' + this.startDate + '&end_date=' + this.endDate, 'GET').then((res) => {
          //     if(res.data){
          //       this.loading = false
          //       this.data = res.data
          //       console.log(this.data)
          //       this.data.stripe_stats.forEach(item => {
          //         if(item.settings !== null && item.revenue_limit > 0){
          //           this.active_stripe_accounts_count += 1;
          //         }
          //       });
          //     }
          //     else{
          //       this.showToast2("Something went wrong!", 'error')
          //     }
          // })
          // }
        },
    },
    mounted(){
        var date1 = new Date();
        this.startDate = parseInt(date1.getDate()) + '-' + parseInt(String(date1.getMonth() + 1).padStart(2, '0')) + '-' + date1.getFullYear();
        var date2 = new Date();
        this.endDate = parseInt(date2.getDate()) + '-' + parseInt(String(date2.getMonth() + 1).padStart(2, '0')) + '-' + date2.getFullYear();
        this.initialize()
    }
  }
</script>
<style scoped>
.chip-status-1{
  background-color: #e5f9f6 !important;
  color: #01c9a8 !important;
}
.chip-status-2{
  background-color: #fdf3f3 !important;
  color: #e12d2f !important;
}
.chip-status-3{
  background-color: #fbe996 !important;
  color: #413a29 !important;
}
.chip-status-4{
  background-color: #fefaf5 !important;
  color: #60b9cd !important;
}
.chip-status-5{
  background-color: #ebeef1 !important;
  color: #616f7e !important;
}
.stats-style h5{
  color: #8c98a4;
}
.stats-style .chip-text{
  color: #677788;
  font-weight: 600 ;
  font-size: .8125rem !important;
}
.divider-style{
  border-color: #eef0f7 !important;
}
.stats-img {
  width: 6.5625rem;
  height: auto;
  margin-bottom: 10px;
}
.content-db-style{
  margin: 0 10%;
}
@media only screen and (max-width: 2000px) {
    .content-db-style{
      margin: 0;
    }
}
</style>