import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store';
import OuterLayout from '../layouts/OuterLayout.vue';
import MainLayout from '../layouts/MainLayout.vue';
import Register from '../views/Auth/Register.vue';
import Login from '../views/Auth/Login.vue';
import ResetPassword from '../views/Auth/ResetPassword.vue';
import Dashboard from '../views/Pages/Dashboard.vue';
import AllApplications from '../views/Pages/AllApplications.vue';
import Applications from '../views/Pages/Applications.vue';
import Decisions from '../views/Pages/Decisions.vue';
import Search from '../views/Pages/Search.vue';
import Responses from '../views/Pages/Responses.vue';
import SearchView from '../views/Pages/SearchView.vue';
import Error404 from '../views/Pages/Error404.vue';
import StripeAccounts from '../views/Pages/StripeAccounts.vue';
import Clients from '../views/Pages/Clients.vue';
import Disputes from '../views/Pages/Disputes.vue';
import DisputeView from '../views/Pages/DisputeView.vue';
import Evidence from '../views/Pages/Evidence.vue';
import TransactionLedger from '../views/Pages/TransactionLedger.vue';
import BankAccounts from '../views/Pages/BankAccounts.vue';
import Campaigns from '../views/Pages/Campaigns.vue';
import Nationalities from '../views/Pages/Nationalities.vue';

Vue.use(VueRouter)

const routes = [
        {
        path: '/',
        component: OuterLayout,
            children: [
                {
                path: '/',
                alias: '/login',
                component: Login,
                },
                {
                path: '/register',
                component: Register,
                },
                {
                path: '/forgot-password',
                component: ResetPassword,
                }
            ]
    },
    {
        path: '/register/:email',
        name: 'OTP',
        component: Register
    },
    {
        path: '/admin',
        meta: { RouteForAdmin: true },
        component: MainLayout,
        children: [
                {
                path: '/admin',
                alias: '/admin/dashboard',
                component: Dashboard,
                meta: { title: 'Dashboard' }
                },
                {
                path: '/admin/applications-all',
                component: AllApplications,
                meta: { title: 'All Applications' }
                },
                {
                path: '/admin/decisions',
                component: Decisions,
                meta: { title: 'Decisions' }
                },
                {
                path: '/admin/search',
                component: Search,
                meta: { title: 'Search' }
                },
                {
                path: '/admin/responses',
                component: Responses,
                meta: { title: 'Responses' }
                },
                {
                path: '/admin/search/view/:ref/',
                component: SearchView,
                meta: { title: 'View Application' }
                },
                {
                path: '/admin/stripe/accounts',
                component: StripeAccounts,
                meta: { title: 'Stripe Accounts' }
                },
                {
                path: '/admin/clients',
                component: Clients,
                meta: { title: 'Clients' }
                },
                {
                path: '/admin/disputes',
                component: Disputes,
                meta: { title: 'Disputes' }
                },
                {
                path: '/admin/disputes/view/:ref/',
                component: DisputeView,
                meta: { title: 'View Dispute' }
                },
                {
                path: '/admin/evidence/:ref/',
                component: Evidence,
                meta: { title: 'Evidence' }
                },
                {
                path: '/admin/banking/accounts',
                component: BankAccounts,
                meta: { title: 'Bank Accounts' }
                },
                {
                path: '/admin/banking/ledger',
                component: TransactionLedger,
                meta: { title: 'Transaction Ledger' }
                },
                {
                path: '/admin/gads/campaigns',
                component: Campaigns,
                meta: { title: 'Campaigns' }
                },
                {
                path: '/admin/nationalities',
                component: Nationalities,
                meta: { title: 'Nationalities' }
                },
        ]
    },
    {
        path: '/executive_supervisor',
        meta: { RouteForExecutiveSupervisor: true },
        component: MainLayout,
        children: [
                {
                path: '/executive_supervisor',
                alias: '/executive_supervisor/dashboard',
                component: Dashboard,
                meta: { title: 'Dashboard' }
                },
                {
                path: '/executive_supervisor/applications-all',
                component: AllApplications,
                meta: { title: 'All Applications' }
                },
                {
                path: '/executive_supervisor/applications',
                component: Applications,
                meta: { title: 'My Applications' }
                },
                {
                path: '/executive_supervisor/decisions',
                component: Decisions,
                meta: { title: 'Decisions' }
                },
                {
                path: '/executive_supervisor/search',
                component: Search,
                meta: { title: 'Search' }
                },
                {
                path: '/executive_supervisor/responses',
                component: Responses,
                meta: { title: 'Responses' }
                },
                {
                path: '/executive_supervisor/search/view/:ref/',
                component: SearchView,
                meta: { title: 'View Application' }
                },
                {
                path: '/executive_supervisor/disputes',
                component: Disputes,
                meta: { title: 'Disputes' }
                },
                {
                path: '/executive_supervisor/disputes/view/:ref/',
                component: DisputeView,
                meta: { title: 'View Dispute' }
                },
                {
                path: '/executive_supervisor/evidence/:ref/',
                component: Evidence,
                meta: { title: 'Evidence' }
                },
            ]
    },
    {
        path: '/agent',
        meta: { RouteForAgent: true },
        component: MainLayout,
        children: [
                {
                path: '/agent',
                alias: '/agent/dashboard',
                component: Dashboard,
                meta: { title: 'Dashboard' }
                },
                {
                path: '/agent/applications',
                component: Applications,
                meta: { title: 'My Applications' }
                },
                {
                path: '/agent/decisions',
                component: Decisions,
                meta: { title: 'Decisions' }
                },
                {
                path: '/agent/search/view/:ref/',
                component: SearchView,
                meta: { title: 'View Application' }
                },
            ]
    },
    {
        path: '*',
        component: Error404
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) => {
    let user = store.state.user;
    if (to.matched.some(record => record.meta.RouteForAdmin)) {
        if (!user) {
            next({
                path: '/'
            })
        } else {
            if (user.role == 'admin') {
                next()
            } else {
                next({
                    path: '/'
                })
            }
        }
    }
    else if (to.matched.some(record => record.meta.RouteExecutiveSupervisor)) {
        if (!user) {
            next({
                path: '/'
            })
        } else {
            if (user.role == 'executive_supervisor') {
                next()
            } else {
                next({
                    path: '/'
                })
            }
        }
    }
    else if (to.matched.some(record => record.meta.RouteForAgent)) {
        if (!user) {
            next({
                path: '/'
            })
        } else {
            if (user.role == 'agent') {
                next()
            } else {
                next({
                    path: '/'
                })
            }
        }
    }
    else{
        next()
    }
})

export default router