<template>
  <div v-show="$route.path != '/' + $store.state.user.role + '/evidence/' + $route.params.ref">
   <v-app-bar
        elevate-on-scroll
        app
        class="main-appbar"
        extension-height="64"
        style="z-index: 10"
      >

        <v-img src="../../assets/img/vip-global-white-logo.svg" max-width="230" class="ml-3 logo-style"></v-img>

        <v-spacer></v-spacer>

        <template #extension>
        <v-toolbar flat>
            <v-tabs dense class="px-md-10" :show-arrows="true" color="#38435a">
              <v-menu
                  v-for="(link, index) in nav_links"
                  :key="index"
                  bottom
                  :offset-y="true"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-tab v-if="link.redirect" @click="goTo(link.route)">{{link.title}} <v-icon v-if="link.redirect" class="ml-1">mdi-arrow-top-right</v-icon></v-tab>
                    <v-tab v-bind="attrs" v-on="on" v-else-if="link.subLinks">{{link.title}} <v-icon class="ml-1">mdi-chevron-down</v-icon></v-tab>
                    <v-tab exact :to="'/' + $store.state.user.role + link.route" v-else>{{link.title}}</v-tab>
                  </template>

                  <v-list v-if="link.subLinks && link.subLinks.length > 0">
                    <v-list-item
                      v-for="(sublink, index2) in link.subLinks"
                      :key="index2"
                      router :to="'/' + $store.state.user.role + sublink.route"
                    >
                      {{ sublink.title }}
                    </v-list-item>
                  </v-list>
                </v-menu>
            </v-tabs>
        </v-toolbar>
      </template>
  
        <v-menu
        v-model="menu"
        bottom
        :offset-y="true"
        :close-on-content-click="true"
      >
        <template v-slot:activator="{ on }">
          <v-chip
            v-on="on"
            color="transparent"
            class="py-6 px-4 rounded-lg white--text"
          >
            <v-avatar left size="100" color="white">
              <img :src="$store.state.user.avatarUrl" />
            </v-avatar>
            <span><strong>{{$store.state.user.firstName + ' ' + $store.state.user.lastName.substring(0, 1) + '.'}} </strong></span>
            <v-icon right>
                mdi-chevron-down
            </v-icon>
          </v-chip>
        </template>
        <v-card width="300">
          <v-list>
            <v-list-item>
              <v-list-item-avatar>
                <v-img :src="$store.state.user.avatarUrl"></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title color="#38435a"><strong>{{$store.state.user.firstName + ' ' + $store.state.user.lastName}}</strong></v-list-item-title>
                <v-list-item-subtitle>
                  <div v-if="$store.state.user.role == 'agent'" class="Box-sc-kv6pi1-0 kJJUso VipBadge" style="margin-right: 10px;vertical-align: top;"><div class="Box-sc-kv6pi1-0 iQKWQz"><img alt="star_with_vip" src="https://portal.vipglobal.us.com/assets/svg/star.svg" style="height: 14px;margin-right: -9px;"></div><div class="Box-sc-kv6pi1-0 kednUW VipBadge__GradientContainer" style="display: flex; align-items: center;"><span class="Spanstyled__SpanStyled-sc-16tp9kb-0 dIscLj kite-js-Span VipBadge__InfoText VipBadge__InfoText--s">Agent</span><div class="Box-sc-kv6pi1-0 kUkAkB VipBadge__Line VipBadge__Line--vipBronze"></div><div class="Box-sc-kv6pi1-0 cfMoGs VipBadge__Line"></div></div></div>
                  <div v-if="$store.state.user.role == 'executive_supervisor'" class="Box-sc-kv6pi1-0 kJJUso VipBadge" style="margin-right: 10px;vertical-align: top;"><div class="Box-sc-kv6pi1-0 iQKWQz"><img alt="star_with_vip" src="https://portal.vipglobal.us.com/assets/svg/star.svg" style="height: 14px;margin-right: -9px;"></div><div class="Box-sc-kv6pi1-0 kednUWGold VipBadge__GradientContainer" style="display: flex; align-items: center;"><span class="Spanstyled__SpanStyled-sc-16tp9kb-0 dIscLj kite-js-Span VipBadge__InfoText VipBadge__InfoText--s text-no-wrap">Executive Supervisor</span><div class="Box-sc-kv6pi1-0 kUkAkB VipBadge__Line  VipBadge__Line--vipGold"></div><div class="Box-sc-kv6pi1-0 cfMoGs VipBadge__Line"></div></div></div>
                  <div v-if="$store.state.user.role == 'admin'" class="Box-sc-kv6pi1-0 kJJUso VipBadge" style="margin-right: 10px;vertical-align: top;"><div class="Box-sc-kv6pi1-0 iQKWQz"><img alt="star_with_vip" src="https://portal.vipglobal.us.com/assets/svg/star.svg" style="height: 14px;margin-right: -9px;"></div><div class="Box-sc-kv6pi1-0 kednUWGold VipBadge__GradientContainer" style="display: flex; align-items: center;"><span class="Spanstyled__SpanStyled-sc-16tp9kb-0 dIscLj kite-js-Span VipBadge__InfoText VipBadge__InfoText--s">Admin</span><div class="Box-sc-kv6pi1-0 kUkAkB VipBadge__Line  VipBadge__Line--vipGold"></div><div class="Box-sc-kv6pi1-0 cfMoGs VipBadge__Line"></div></div></div>
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  icon
                  @click="menu = false"
                >
                  <v-icon color="#38435a">mdi-close-circle</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <v-list>
            <v-list-item @click="logout()">
              <v-list-item-action>
                <v-icon>mdi-logout</v-icon>
              </v-list-item-action>
              <v-list-item-subtitle><strong>Sign Out</strong></v-list-item-subtitle>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
      <!-- <v-app-bar-nav-icon v-if="mini" @click="drawer = true;" color="white"></v-app-bar-nav-icon> -->
      </v-app-bar>
  </div>
</template>
<script>
  export default {
    components: {
    },
    data: () => ({
      drawer: true,
      menu: false,
      userType: '',
      mini: false,
      nav_links: [],
    }),
    beforeDestroy () {
      if (typeof window === 'undefined') return

      window.removeEventListener('resize', this.onResize, { passive: true })
    },
    mounted () {
      this.onResize()
      window.addEventListener('resize', this.onResize, { passive: true })
      if(!this.$vuetify.breakpoint.mdAndUp){
      this.drawer = false
    }
    },
    methods: {
      onResize () {
        this.mini = window.innerWidth < 700;
        if(window.innerWidth < 700 && !this.drawer){
          this.drawer = false;
        }
      },
      goTo(url) {
        window.open(url, '_blank')
      }
    },
    created() {
      let role = this.$store.state.user.role;
      if(role == 'agent'){
        this.nav_links = [
        { id: 1, title: 'Dashboard', route: '/dashboard' },
        { id: 2, title: 'Applications', route: '/applications'},
        { id: 3, title: 'Decisions', route: '/decisions' },
      ];
      }
      else if(role == 'executive_supervisor'){
        this.nav_links = [
        { id: 1, title: 'Dashboard', route: '/dashboard' },
        { id: 2, title: 'Applications', route: '/applications', subLinks: [
          {id: 1, title: 'All Applications', route: '/applications-all'},
          {id: 2, title: 'My Applications', route: '/applications'}
        ] },
        { id: 3, title: 'Decisions', route: '/decisions' },
        { id: 4, title: 'Search', route: '/search' },
        { id: 5, title: 'Responses', route: '/responses' },
        { id: 6, title: 'Clients Disputes', route: '/disputes' },
        { id: 7, title: 'Support Desk', route: 'https://estaapply.zendesk.com/agent/dashboard', redirect: true },
        { id: 8, title: 'DeepL', route: 'https://deepl.com/', redirect: true },

      ];
      }
      else if(role == 'admin'){
        this.nav_links = [
        { id: 1, title: 'Dashboard', route: '/dashboard' },
        { id: 2, title: 'Applications', route: '/applications-all'},
        { id: 3, title: 'Decisions', route: '/decisions' },
        { id: 4, title: 'Search', route: '/search' },
        { id: 5, title: 'Responses', route: '/responses' },
        { id: 6, title: 'Clients & Disputes', route: '/stripe', subLinks: [
          {id: 1, title: 'Clients', route: '/clients'},
          {id: 2, title: 'Disputes', route: '/disputes'}
        ] },
        { id: 7, title: 'Stripe', route: '/stripe', subLinks: [
          {id: 1, title: 'Payouts', route: '/stripe/payouts'},
          {id: 2, title: 'Accounts', route: '/stripe/accounts'}
        ] },
        { id: 8, title: 'Bank Transactions', route: '/banking', subLinks: [
          {id: 1, title: 'Bank Accounts', route: '/banking/accounts'},
          {id: 2, title: 'Transaction Ledger', route: '/banking/ledger'}
        ] },
        { id: 9, title: 'GAds', route: '/gads', subLinks: [
          {id: 1, title: 'Websites', route: '/gads/websites'},
          {id: 2, title: 'Campaigns', route: '/gads/campaigns'}
        ] },
         { id: 10, title: 'Nationalities', route: '/nationalities' },
      ];
      }
    }
  }
</script>
<style scoped>
.nav-drawer .item-title{
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
}
.v-application--is-ltr .v-list--dense.v-list--nav .v-list-group--no-action > .v-list-group__items > .v-list-item {
  padding: 0 8
}
.sub-item {
  border-radius: 16px;
  background: rgba(229, 229, 229, 0.3);
}
</style>