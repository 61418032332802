<template>
  <div class="pa-5">
    <v-card class="card-style rounded-1" >
    <v-row class="ma-2">
      <v-col cols="12" md="9" class="flex-items">
        <v-btn
          color="#38435a"
          fab
          small
          @click="add"
          class="elevation-0 mr-3"
        ><v-icon color="white">mdi-plus</v-icon></v-btn>
        <h2>Bank Accounts</h2>
      </v-col>
      <v-col cols="12" md="3">
      <v-text-field
          v-model="search"
          prepend-inner-icon="search"
          label="Search Bank Account"
          single-line
          hide-details
          color="#38435a"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-card class="mb-1 dt-container" elevation="0" outlined>
    <v-data-table
      :loading="loading"
       :headers="headers"
        :items="data"
        item-key="id"
        :options.sync="options"
        hide-default-footer
    >
    <template v-slot:[`item.account_no`]="{ item }">
      <span style="font-size:small;color: darkslateblue;">
        {{
          (item.iban ? `IBAN: ${item.iban} | ` : '') +
          (item.swift ? `SWIFT: ${item.swift}` : '') +
          (item.accountNumber ? `Acct #: ${item.accountNumber} | ` : '') +
          (item.ach ? `ACH #: ${item.ach} | ` : '') +
          (item.aba ? `ABA #: ${item.aba}` : '')
        }}
      </span>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <span class="align-center">
        <v-btn
          v-if="$store.state.user.role == 'admin'"
          outlined
          small
          class="mr-2"
          color="#166ecf"
          @click="edit(item)"
        >
        <v-icon class="mr-1">mdi-square-edit-outline</v-icon>
        Edit
        </v-btn>
      </span>   
    </template>
    </v-data-table>
    </v-card>
    <v-row class="ma-2" align="center" justify="center">
    <v-col cols="auto" class="flex-items">
        <span class="px-2">Row Per Page: </span>
        <span>
          <v-select
            dense
            outlined
            hide-details
            :value="options.itemsPerPage"
            style="max-width: 90px"
            class="rounded-lg"
            @change="options.itemsPerPage = parseInt($event, 10)"
            :items="perPageChoices"
          >
          </v-select>
        </span>
    </v-col>
    <v-col cols="auto" class="mx-auto text-truncate" no-gutters>
    Showing {{ data.length > 0 ? ((paginationData.currentPage - 1) * options.itemsPerPage) + 1 : 0 }} to {{ data.length > 0 ? ((paginationData.currentPage - 1) * options.itemsPerPage) + data.length : 0 }} of {{ paginationData.count }} entries
    </v-col>
    <v-col cols="auto">
        <v-pagination
            v-model="options.page"
            class="rounded-lg"
            :total-visible="7"
            color="#38435a"
            :length="paginationData.lastPage">
        </v-pagination>
    </v-col>
      </v-row>
    </v-card>
    <v-dialog
      v-model="dialogConfirmation"
      width="520"
      persistent
    >
      <v-card>
        <v-card-title class="text-subtitle-1">
          <strong>Confirmation</strong>
        </v-card-title>

        <v-card-text>
            <div class="subtitle-1">
              Are you sure you want to <strong>DELETE</strong> this transaction?
            </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            outlined
            class="mr-2"
            depressed
            @click="dialogConfirmation = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="red darken-3"
            class="white--text"
            depressed
            @click="confirmDelete()"
            :loading="loadingConfirm"
          >
            Yes, Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogForm"
      width="700"
      scrollable
      persistent
    >
      <v-card>
        <v-card-title>
          <strong v-if="action == 'add'">Add New Account</strong>
          <strong v-else-if="action == 'edit'">Update Account</strong>
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="closeFormDialog()"
          >
          <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pt-2" style="max-height: 900px;">
            <v-form ref="formRef">
              <v-row no-gutters>
                <v-col cols="12" class="py-0">
                  <div class="px-0 pb-1 text-subtitle1">Account Holder</div>
                  <v-text-field
                    v-model="formdata.name"
                    color="grey"
                    :rules="[formRules.required]"
                    placeholder="Name"
                    height="45"
                    outlined
                    ></v-text-field>
                </v-col>
                <v-col cols="12" class="py-0">
                  <div class="px-0 pb-1 text-subtitle1">Bank Name</div>
                  <v-text-field
                    v-model="formdata.bank"
                    color="grey"
                    :rules="[formRules.required]"
                    placeholder="Bank Name"
                    height="45"
                    outlined
                    ></v-text-field>
                </v-col>
                <v-col cols="12" class="py-0">
                  <div class="px-0 pb-1 text-subtitle1">Currency</div>
                    <v-autocomplete
                        v-model="formdata.currency"
                        color="grey"
                        :rules="[formRules.required]"
                        height="45"
                        :items="currency_list"
                        placeholder="Currency"
                        item-text="description"
                        item-value="id"
                        hide-details
                        class="mb-7"
                        outlined
                      ></v-autocomplete>
                </v-col>
                <v-col cols="12" class="py-0">
                  <div class="px-0 pb-1 text-subtitle1">IBAN</div>
                  <v-text-field
                    v-model="formdata.iban"
                    color="grey"
                    placeholder="IBAN"
                    height="45"
                    outlined
                    ></v-text-field>
                </v-col>
                <v-col cols="12" class="py-0">
                  <div class="px-0 pb-1 text-subtitle1">Swift</div>
                  <v-text-field
                    v-model="formdata.swift"
                    color="grey"
                    placeholder="SWIFT/BIC"
                    height="45"
                    outlined
                    ></v-text-field>
                </v-col>
                <v-col cols="12" class="py-0">
                  <div class="px-0 pb-1 text-subtitle1">Account #</div>
                  <v-text-field
                    v-model="formdata.accountNumber"
                    color="grey"
                    placeholder="Account #"
                    height="45"
                    outlined
                    ></v-text-field>
                </v-col>
                <v-col cols="12" class="py-0">
                  <div class="px-0 pb-1 text-subtitle1">ACH Routing #</div>
                  <v-text-field
                    v-model="formdata.ach"
                    color="grey"
                    placeholder="ACH Routing #"
                    height="45"
                    outlined
                    ></v-text-field>
                </v-col>
                <v-col cols="12" class="py-0">
                  <div class="px-0 pb-1 text-subtitle1">ABA Routing #</div>
                  <v-text-field
                    v-model="formdata.aba"
                    color="grey"
                    placeholder="ABA Routing #"
                    height="45"
                    outlined
                    ></v-text-field>
                </v-col>
                <v-col cols="12" class="py-0">
                  <div class="px-0 pb-1 text-subtitle1">Notes</div>
                  <v-textarea
                    v-model="formdata.notes"
                    color="grey"
                    placeholder="Notes"
                    rows="3"
                    outlined
                    ></v-textarea>
                </v-col>
              </v-row>
            </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="pa-3">
          <v-btn
            color="red darken-3"
            class="white--text"
            depressed
            outlined
            @click="deleteLedger()"
            v-if="formdata && formdata.active == 0"
          >
          <v-icon class="mr-1">mdi-delete</v-icon>
            Delete
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            outlined
            class="mr-2"
            depressed
            @click="closeFormDialog()"
          >
            Cancel
          </v-btn>
          <v-btn
            color="#166ecf"
            class="white--text mr-3"
            depressed
            @click="confirmUpdate()"
            v-if="action == 'edit'"
            :loading="loadingForm"
          >
          <v-icon class="mr-1">mdi-square-edit-outline</v-icon>
            Update
          </v-btn>
          <v-btn
            color="#00a34e"
            class="white--text mr-3"
            depressed
            @click="confirmAdd()"
            v-else-if="action == 'add'"
            :loading="loadingForm"
          >
          <v-icon class="mr-1">mdi-check-circle</v-icon>
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</div>
</template>
<script>
  export default {
    components: {
    },
    data: () => ({
      search: '',
      headers: [
          { text: 'NAME', value: 'name' },
          { text: 'BANK', value: 'bank'},
          { text: 'CURRENCY', value: 'currency'},
          { text: 'ACCOUNT #', value: 'account_no', sortable: false},
          { text: 'NOTES', value: 'notes'},
          { text: 'ACTIONS', value: 'actions', align: 'center', sortable: false, },
        ],
      data: [],
      perPageChoices: [
          {text:'5' , value: 5},
          {text:'10' , value: 10},
          {text:'20' , value: 20},
          {text:'50' , value: 50},
          {text:'100' , value: 100},
          {text:'250' , value: 250},
          {text:'500' , value: 500},
      ],
      options: {},
      paginationData: {},
      loading: false,
      dialogConfirmation: false,
      loadingConfirm: false,
      edit_item: {},
      dialogForm: false,
      formdata: {},
      action: '',
      loadingForm: false,
      accounts_list: [],
      currency_list: [],
    }),
    watch: {
      options: {
        handler () {
         this.initialize();
        },
        deep: true,
      },
      search: {
        handler () {
          setTimeout(() => {
            this.options.page = 1;
            this.initialize();
          }, 600);
        }
      },
    },
    mounted() {
      this.getCurrencyList();
    },
    methods: {
      initialize (){
        this.loading = true;
        let page = this.options.page;
        let itemPerPage = this.options.itemsPerPage;
        let sortBy = this.options.sortBy.length > 0 ? this.options.sortBy[0] : '';
        let order = this.options.sortBy.length > 0 ? this.options.sortDesc[0] ? 'desc' : 'asc' : '';
        let search = this.search;
        this.axiosCall('/banking-accounts/getAccounts?page=' + page + '&itemPerPage=' + itemPerPage + '&sortBy=' + sortBy + '&order=' + order + '&searchKey=' + search, 'GET').then((res) => {
              this.loading = false
              this.data = res.data.data
              this.paginationData = res.data.pagination;
          })
      },
       closeFormDialog(){
        this.$refs.formRef.resetValidation();
        this.dialogForm = false
       },
       async edit(item){
        this.action = 'edit';
        this.formdata = JSON.parse(JSON.stringify(item));
        this.dialogForm = true
       },
       async add(){
        this.action = 'add';
        this.formdata = {
          currency: 'USD',
        };
        this.dialogForm = true
       },
       async deleteLedger(){
        this.dialogConfirmation = true
       },
       confirmAdd(){
        if(this.$refs.formRef.validate()){
        this.loadingForm = true;
        let data = {
          name: this.formdata.name,
          bank: this.formdata.bank,
          currency: this.formdata.currency,
          iban: this.formdata.iban,
          swift: this.formdata.swift,
          accountNumber: this.formdata.accountNumber,
          ach: this.formdata.ach,
          aba: this.formdata.aba,
          notes: this.formdata.notes,
        };
        this.axiosCall("/banking-accounts/addAccount", "POST", data).then((res) => {
          if (res.data.status == 201) {
            this.formdata = {};
            this.initialize()
            this.showToast(res.data.msg, 'success')
            this.$refs.formRef.reset();
            this.closeFormDialog();
          }
          else{
            this.showToast(res.data.msg, 'error')
          }
          this.loadingForm = false;
        });
        }
       },
       confirmUpdate(){
        if(this.$refs.formRef.validate()){
        this.loadingForm = true;
        let data = {
          token: this.formdata.token,
          name: this.formdata.name,
          bank: this.formdata.bank,
          currency: this.formdata.currency,
          iban: this.formdata.iban,
          swift: this.formdata.swift,
          accountNumber: this.formdata.accountNumber,
          ach: this.formdata.ach,
          aba: this.formdata.aba,
          notes: this.formdata.notes,
        };
        this.axiosCall("/banking-accounts/updateAccount", "POST", data).then((res) => {
          if (res.data.status == 200) {
            this.formdata = {};
            this.initialize()
            this.showToast(res.data.msg, 'success')
            this.$refs.formRef.reset();
            this.closeFormDialog();
          }
          else{
            this.showToast(res.data.msg, 'error')
          }
          this.loadingForm = false;
        });
        }
       },
       confirmDelete(){
        if(this.formdata.token){
        this.loadingConfirm = true;
        let data = {
          token: this.formdata.token,
        };
        this.axiosCall("/banking-accounts/deleteAccount", "POST", data).then((res) => {
          if (res.data.status == 200) {
            this.formdata = {};
            this.initialize()
            this.showToast(res.data.msg, 'success')
            this.$refs.formRef.reset();
            this.closeFormDialog();
            this.dialogConfirmation = false;
          }
          else{
            this.showToast(res.data.msg, 'error')
          }
          this.loadingConfirm = false;
        });
        }
       },
       getCurrencyList(){
        this.axiosCall('/banking-accounts/getCurrencyList', 'GET').then((res) => {
            this.currency_list = res.data;
          })
      },
    }
  }
</script>
<style scoped>
.chip-status-1{
  background-color: #d7f7c2 !important;
  color: #2f8547 !important;
}
.chip-status-2{
  background-color: #cff5f6 !important;
  color: #1066c5 !important;
}
.chip-status-3{
  background-color: #ebeef1 !important;
  color: #616f7e !important;
}
.chip-status-4{
  background-color: #fcedb9 !important;
  color: #b02c00 !important;
}
.chip-status-5{
  background-color: #ffe7f2 !important;
  color: #c4093c !important;
}
.chip-status-6{
  background-color: #F0FFF8 !important;
  color: #18AB56 !important;
}
.chip-status-7{
  background-color: #fbe996 !important;
  color: #413a29 !important;
}
</style>