<template>
    <div class="py-8">
      <v-form @submit.prevent ref="form">
        <v-row class="flex-items">
          <v-col><v-divider color="#909fa9"></v-divider></v-col>
          <v-col cols="auto" class="mx-auto">
            <span class="text-subtitle1 view-main-title">Applicant Information</span>
          </v-col>
          <v-col><v-divider color="#909fa9"></v-divider></v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Applicant Photo
            <br />
            <v-chip
              class="chip-status-2 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap"
              small
              v-if="data.photo_details_optimized"
            >
            <span>Compressed</span>
            </v-chip>
            <v-chip
              class="chip-status-1 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap"
              small
              v-else
            >
            <span>Non-Compressed</span>
            </v-chip>
            </v-col> <v-col cols="12" sm="6" class="py-1">
            <div v-if="data.photo_details_optimized">
            <div><img class="image-fluid rounded-lg" height="300" :src="data.photo_details_optimized"/></div>
            <v-btn color="#f5ca99" class="px-15 py-4" depressed @click="downloadPhoto(data.photo_details_optimized, data.first_given_names, data.family_names, 'PHOTO')"><v-icon>mdi-download</v-icon> Download Photo</v-btn>
            </div>
            <div v-else>
            <div><img class="image-fluid rounded-lg" height="300" :src="'https://esta-apply.s3.amazonaws.com/' + data.photo_details"/></div>
            <v-btn color="#f5ca99" class="px-15 py-4" depressed @click="downloadPhoto('https://esta-apply.s3.amazonaws.com/' + data.photo_details, data.first_given_names, data.family_names, 'PHOTO')"><v-icon>mdi-download</v-icon> Download Photo</v-btn>
            </div>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Surname</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="edit_prop != 'family_names'"><strong class="clipboard-style" v-clipboard="(urlDecode(data.family_names)).toUpperCase()">{{(urlDecode(data.family_names)).toUpperCase()}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="edit('family_names', data.family_names)">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-text-field
                    v-model="edit_val"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">First (Given) Name</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="edit_prop != 'first_given_names'"><strong class="clipboard-style" v-clipboard="(urlDecode(data.first_given_names)).toUpperCase()">{{(urlDecode(data.first_given_names)).toUpperCase()}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="edit('first_given_names', data.first_given_names)">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-text-field
                    v-model="edit_val"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Telephone</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="edit_prop != 'phone_number'"><strong class="clipboard-style" v-clipboard="data.phone_number">{{data.phone_number}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="edit('phone_number', data.phone_number)">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-text-field
                    v-model="edit_val"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Email</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="edit_prop != 'email'"><strong class="clipboard-style" v-clipboard="data.email">{{data.email}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="edit('email', data.email)">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-text-field
                    v-model="edit_val"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Gender</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="edit_prop != 'sex'"><strong class="clipboard-style" v-clipboard="data.sex ? sex.find(item => item.id == data.sex).desc : ''">{{data.sex ? sex.find(item => item.id == data.sex).desc : ''}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="edit('sex', data.sex)">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-autocomplete
                    v-model="edit_val"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    item-text="desc"
                    item-value="id"
                    :items="sex"
                    hide-details="auto"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Date of Birth (Day/Month/Year)</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="!edit_prop_date || edit_prop_date.join(',') != 'dob_day,dob_month,dob_year'"><strong class="clipboard-style" v-clipboard="data.dob_day + ' / ' + monthNumToName(data.dob_month) + ' / ' + data.dob_year">{{data.dob_day + ' / ' + monthNumToName(data.dob_month) + ' / ' + data.dob_year}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="editDate(['dob_day','dob_month','dob_year'], [data.dob_day, data.dob_month, data.dob_year])">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-autocomplete
                    v-model="edit_day"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    placeholder="Day"
                    :items="days"
                    hide-details="auto"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" class="pb-1">
                  <v-autocomplete
                    v-model="edit_month"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    placeholder="Month"
                    item-text="desc"
                    item-value="id"
                    :items="months"
                    hide-details="auto"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" class="pb-1">
                  <v-autocomplete
                    v-model="edit_year"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    placeholder="Year"
                    :items="years"
                    hide-details="auto"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Country of Birth</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="edit_prop != 'country_of_birth'" class="flex-items"><span class="flex-items clipboard-style" v-clipboard="getCountry(data.country_of_birth)"><img v-if="data.country_of_birth" class="rounded-circle mr-2" width="18" :src="require('../../assets/img/flags/1x1/' + (data.country_of_birth.toLowerCase()).substring(0, 2) + '.svg')" /><strong>{{getCountry(data.country_of_birth)}}</strong></span> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="edit('country_of_birth', data.country_of_birth)">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-autocomplete
                    v-model="edit_val"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    item-text="country"
                    item-value="code"
                    :items="$store.state.countries"
                    hide-details="auto"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Country of Nationality</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="edit_prop != 'nationality'" class="flex-items"><span class="flex-items clipboard-style" v-clipboard="getCountry(data.nationality)"><img v-if="data.nationality" class="rounded-circle mr-2" width="18" :src="require('../../assets/img/flags/1x1/' + (data.nationality.toLowerCase()).substring(0, 2) + '.svg')" /><strong>{{getCountry(data.nationality)}}</strong></span> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="edit('nationality', data.nationality)">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-autocomplete
                    v-model="edit_val"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    item-text="country"
                    item-value="code"
                    :items="$store.state.countries"
                    hide-details="auto"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Residential address</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="edit_prop != 'residential_address'"><strong class="clipboard-style" v-clipboard="sanitizeString(urlDecode(data.residential_address))">{{urlDecode(data.residential_address)}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="edit('residential_address', data.residential_address)">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-text-field
                    v-model="edit_val"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">State/Province</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="edit_prop != 'address_state'"><strong class="clipboard-style" v-clipboard="sanitizeString(urlDecode(data.address_state))">{{urlDecode(data.address_state)}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="edit('address_state', data.address_state)">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-text-field
                    v-model="edit_val"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Address During Visit</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="edit_prop != 'cambodia_address'"><strong class="clipboard-style" v-clipboard="sanitizeString(urlDecode(data.cambodia_address))">{{urlDecode(data.cambodia_address)}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="edit('cambodia_address', data.cambodia_address)">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-text-field
                    v-model="edit_val"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Passport Number</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="edit_prop != 'passport_number'"><strong class="clipboard-style" v-clipboard="data.passport_number">{{data.passport_number}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="edit('passport_number', data.passport_number)">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-text-field
                    v-model="edit_val"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Passport Date Of Issue (Year/Month/Day)</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="!edit_prop_date || edit_prop_date.join(',') != 'passport_issuance_year,passport_issuance_month,passport_issuance_day'"><strong class="clipboard-style" v-clipboard="data.passport_issuance_year + ' / ' + monthNumToName(data.passport_expiry_month) + ' / ' + data.passport_issuance_day">{{data.passport_issuance_year + ' / ' + monthNumToName(data.passport_expiry_month) + ' / ' + data.passport_expiry_day}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="editDate(['passport_issuance_year','passport_issuance_month','passport_issuance_day'], [data.passport_issuance_year, data.passport_issuance_month, data.passport_issuance_day])">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-autocomplete
                    v-model="edit_year"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    placeholder="Year"
                    :items="years"
                    hide-details="auto"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" class="pb-1">
                  <v-autocomplete
                    v-model="edit_month"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    placeholder="Month"
                    item-text="desc"
                    item-value="id"
                    :items="months"
                    hide-details="auto"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" class="pb-1">
                  <v-autocomplete
                    v-model="edit_day"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    placeholder="Day"
                    :items="days"
                    hide-details="auto"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Passport Expiry Date (Year/Month/Day)</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="!edit_prop_date || edit_prop_date.join(',') != 'passport_expiry_year,passport_expiry_month,passport_expiry_day'"><strong class="clipboard-style" v-clipboard="data.passport_expiry_year + ' / ' + monthNumToName(data.passport_expiry_month) + ' / ' + data.passport_expiry_day">{{data.passport_expiry_year + ' / ' + monthNumToName(data.passport_expiry_month) + ' / ' + data.passport_expiry_day}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="editDate(['passport_expiry_year','passport_expiry_month','passport_expiry_day'], [data.passport_expiry_year, data.passport_expiry_month, data.passport_expiry_day])">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-autocomplete
                    v-model="edit_year"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    placeholder="Year"
                    :items="years2"
                    hide-details="auto"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" class="pb-1">
                  <v-autocomplete
                    v-model="edit_month"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    placeholder="Month"
                    item-text="desc"
                    item-value="id"
                    :items="months"
                    hide-details="auto"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" class="pb-1">
                  <v-autocomplete
                    v-model="edit_day"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    placeholder="Day"
                    :items="days"
                    hide-details="auto"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Country of Passport</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="edit_prop != 'passport_country'" class="flex-items"><span class="flex-items clipboard-style" v-clipboard="getCountry(data.passport_country)"><img v-if="data.nationality" class="rounded-circle mr-2" width="18" :src="require('../../assets/img/flags/1x1/' + (data.passport_country.toLowerCase()).substring(0, 2) + '.svg')" /><strong>{{getCountry(data.passport_country)}}</strong></span> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="edit('passport_country', data.passport_country)">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-autocomplete
                    v-model="edit_val"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    item-text="country"
                    item-value="code"
                    :items="$store.state.countries"
                    hide-details="auto"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Visa Type</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="edit_prop != 'visa_type'"><strong class="clipboard-style" v-clipboard="data.visa_type">{{data.visa_type}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="edit('visa_type', data.visa_type)">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-text-field
                    v-model="edit_val"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Intended Date of Entry (Year/Month/Day)</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="!edit_prop_date || edit_prop_date.join(',') != 'entry_date_year,entry_date_month,entry_date_day'"><strong class="clipboard-style" v-clipboard="data.entry_date_year + ' / ' + monthNumToName(data.entry_date_month) + ' / ' + data.entry_date_day">{{data.entry_date_year + ' / ' + monthNumToName(data.entry_date_month) + ' / ' + data.entry_date_day}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="editDate(['entry_date_year','entry_date_month','entry_date_day'], [data.entry_date_year, data.entry_date_month, data.entry_date_day])">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-autocomplete
                    v-model="edit_year"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    placeholder="Year"
                    :items="years2"
                    hide-details="auto"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" class="pb-1">
                  <v-autocomplete
                    v-model="edit_month"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    placeholder="Month"
                    item-text="desc"
                    item-value="id"
                    :items="months"
                    hide-details="auto"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" class="pb-1">
                  <v-autocomplete
                    v-model="edit_day"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    placeholder="Day"
                    :items="days"
                    hide-details="auto"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Port of entry</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="edit_prop != 'port_of_entry_text'"><strong v-if="data.port_of_entry_text" class="clipboard-style" v-clipboard="urlDecode(((data.port_of_entry_text).charAt(0).toUpperCase() + data.port_of_entry_text.slice(1)).replaceAll('_', ' '))">{{urlDecode(((data.port_of_entry_text).charAt(0).toUpperCase() + data.port_of_entry_text.slice(1)).replaceAll('+', ' '))}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="edit('port_of_entry_text', data.port_of_entry_text)">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-text-field
                    v-model="edit_val"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" v-if="data.visa_type == 'VISA-E'">
            <v-row>
                <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Purpose of entry</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
                <div v-if="edit_prop != 'purpose_of_entry'"><strong class="clipboard-style" v-clipboard="urlDecode(data.purpose_of_entry)">{{urlDecode(data.purpose_of_entry)}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="edit('purpose_of_entry', urlDecode(data.purpose_of_entry))">Edit</v-btn></div>
                <v-row v-else no-gutters>
                    <v-col cols="12" class="pb-1">
                    <v-text-field
                        v-model="edit_val"
                        color="#677788"
                        :rules="[formRules.required]"
                        height="45"
                        width="100%"
                        outlined
                        hide-details="auto"
                    ></v-text-field>
                    </v-col>
                    <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                    <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
                </v-row>
                </v-col>
            </v-row>
            <v-row v-if="data.purpose_of_entry == 'other'">
                <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Other Purpose</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
                <div v-if="edit_prop != 'purpose_of_entry_other'"><strong class="clipboard-style" v-clipboard="urlDecode(data.purpose_of_entry_other)">{{urlDecode(data.purpose_of_entry_other)}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="edit('purpose_of_entry_other', urlDecode(data.purpose_of_entry_other))">Edit</v-btn></div>
                <v-row v-else no-gutters>
                    <v-col cols="12" class="pb-1">
                    <v-text-field
                        v-model="edit_val"
                        color="#677788"
                        :rules="[formRules.required]"
                        height="45"
                        width="100%"
                        outlined
                        hide-details="auto"
                    ></v-text-field>
                    </v-col>
                    <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                    <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
                </v-row>
                </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" v-if="data.hotel_reservation_uploaded && data.hotel_reservation_uploaded.length > 0">
            <v-row v-for="(item, index) in data.hotel_reservation_uploaded" :key="index">
            <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Hotel Document #{{index + 1}}</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
                <a :href="data.hotel_reservation_uploaded_link[index]" class="text-decoration-none">{{data.hotel_reservation_uploaded_link[index]}}</a> <v-btn color="#f5ca99" class="px-15 py-4" depressed :href="data.hotel_reservation_uploaded_link[index]" download><v-icon>mdi-download</v-icon> Download Photo/File</v-btn>
            </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" v-if="data.flight_tickets_uploaded && data.flight_tickets_uploaded.length > 0">
            <v-row v-for="(item, index) in data.flight_tickets_uploaded" :key="index">
            <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Flight Ticket #{{index + 1}}</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
                <a :href="data.flight_tickets_uploaded_link[index]" class="text-decoration-none">{{data.flight_tickets_uploaded_link[index]}}</a> <v-btn color="#f5ca99" class="px-15 py-4" depressed :href="data.flight_tickets_uploaded_link[index]" download><v-icon>mdi-download</v-icon> Download Photo/File</v-btn>
            </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" v-if="data.bus_tickets_uploaded && data.bus_tickets_uploaded.length > 0">
            <v-row v-for="(item, index) in data.bus_tickets_uploaded" :key="index">
            <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Bus Ticket #{{index + 1}}</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
                <a :href="data.bus_tickets_uploaded[index]" class="text-decoration-none">{{data.bus_tickets_uploaded[index]}}</a> <v-btn color="#f5ca99" class="px-15 py-4" depressed :href="data.bus_tickets_uploaded[index]" download><v-icon>mdi-download</v-icon> Download Photo/File</v-btn>
            </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" v-if="data.other_uploaded && data.other_uploaded.length > 0">
            <v-row v-for="(item, index) in data.other_uploaded" :key="index">
            <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Other Document #{{index + 1}}</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
                <a :href="data.other_uploaded[index]" class="text-decoration-none">{{data.other_uploaded[index]}}</a> <v-btn color="#f5ca99" class="px-15 py-4" depressed :href="data.other_uploaded[index]" download><v-icon>mdi-download</v-icon> Download Photo/File</v-btn>
            </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </div>
</template>

<script>
  export default {
    props: {
        data: {}
    },
    components: {
    },
    data: () => ({
      edit_val: null,
      edit_prop: null,
      edit_day: null,
      edit_month: null,
      edit_year: null,
      edit_prop_date: null,
      days: ['01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31'],
      months: [
          {id: '01', desc: 'January'},
          {id: '02', desc: 'February'},
          {id: '03', desc: 'March'},
          {id: '04', desc: 'April'},
          {id: '05', desc: 'May'},
          {id: '06', desc: 'June'},
          {id: '07', desc: 'July'},
          {id: '08', desc: 'August'},
          {id: '09', desc: 'September'},
          {id: '10', desc: 'October'},
          {id: '11', desc: 'November'},
          {id: '12', desc: 'December'},
      ],
      years: [],
      years2: [],
      yearsAll: [],
      sex: [
        {id: 'M', desc: 'Male'},
        {id: 'F', desc: 'Female'},
      ],
      forbidden_countries: ["CU","IR","IQ","LY","KP","SO","SD","SY","YE"],
    }),
    created() {
        const currentYear = (new Date()).getFullYear();
        const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1}, (_, i) => start + (i * step));
        this.years = range(currentYear, currentYear - 100, -1); 
        this.years2 = range(currentYear, currentYear + 100, 1); 
        let years_all = range(currentYear - 100, currentYear + 100, 1);
        this.yearsAll = years_all.sort(function(a, b) {
        return b - a;
    });
    },
    methods: {
        setLanguage(lang){
          const languageNames = new Intl.DisplayNames(['en'], {
            type: 'language'
          });
          return languageNames.of(lang)
        },
        getCountry(code) {
          let res = 'None'
          if(code){
          this.$store.state.countries.forEach(item => {
            if(item.code.toLowerCase() == code.toLowerCase() || item.code_3.toLowerCase() == code.toLowerCase()){
              res = item.country
            }
          });
          }
          return res;
        },
        edit(prop, val){
          this.edit_val = val;
          this.edit_prop = prop
        },
        editDate(prop, val){
          this.edit_day = val[0];
          this.edit_month = val[1];
          this.edit_year = parseInt(val[2]);
          this.edit_prop_date = prop
        },
        updateData(){
          if(this.$refs.form.validate()){
          if(this.edit_prop_date){
              this.data[this.edit_prop_date[0]] = this.edit_day;
              this.data[this.edit_prop_date[1]] = this.edit_month;
              this.data[this.edit_prop_date[2]] = this.edit_year;
          }
          else if(this.edit_prop + '_text' in this.data){
            let country = this.getCountry(this.edit_val);
            if(country != 'None'){
              // console.log("updated the" + this.edit_prop + '_text')
              this.data[this.edit_prop] = this.edit_val
              this.data[this.edit_prop + '_text'] = country;
            }
          }
          else{
            this.data[this.edit_prop] = this.edit_val
          }
          let data = {
            token: this.data.token,
            data: JSON.stringify(this.data)
          };
          this.axiosCall("/applications/updateData", "POST", data).then((res) => {
            if (res.data.status == 200) {
              this.showToast("Application field [" + this.edit_prop + "] was successfully updated.", 'success')
              this.cancelEdit();
            }
            else{
              this.showToast(res.data.msg, 'error')
            }
          });
          }
        },
        cancelEdit(){
          this.edit_val = null;
          this.edit_prop = null;
          this.edit_day = null;
          this.edit_month = null;
          this.edit_year = null;
          this.edit_prop_date = null;
        }
    },
    mounted(){
      console.log(this.data)
    }
  }
</script>
<style scoped>
.view-main-title {
  color: #4d6585 !important;
  font-weight: 500;
  font-size: 16px;
}
.view-title {
  color: #4d6585 !important;
  font-weight: 500;
}
.chip-status-1{
  background-color: #ebeef1 !important;
  color: #616f7e !important;
}
.chip-status-2{
  background-color: #F0FFF8 !important;
  color: #18AB56 !important;
}
</style>