<template>
  <div class="pa-5">
    <v-card class="card-style rounded-1" >
    <v-row class="ma-2">
      <v-col cols="12" md="9" class="flex-items">
        <v-btn
          color="#38435a"
          fab
          small
          @click="add"
          class="elevation-0 mr-3"
        ><v-icon color="white">mdi-plus</v-icon></v-btn>
        <h2>Nationalities</h2>
      </v-col>
      <v-col cols="12" md="3">
      <v-text-field
          v-model="search"
          prepend-inner-icon="search"
          label="Search nationality"
          single-line
          hide-details
          color="#38435a"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-card class="mb-1 dt-container" elevation="0" outlined>
    <v-data-table
      :loading="loading"
       :headers="headers"
        :items="data"
        item-key="id"
        :options.sync="options"
        hide-default-footer
    >
    <template v-slot:[`item.countries`]="{ item }">
      <span v-for="country in item.countries.split(',')" :key="country">
        <v-chip
          class="chip-status-1 rounded px-2 py-1 mr-2 my-1 font-weight-bold text-wrap']"
          x-small
        >
        <span>{{country}}</span>
        </v-chip>
      </span>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <span class="align-center">
        <v-btn
          v-if="$store.state.user.role == 'admin'"
          outlined
          small
          class="mr-2"
          color="#166ecf"
          @click="edit(item)"
        >
        <v-icon class="mr-1">mdi-square-edit-outline</v-icon>
        Edit
        </v-btn>
      </span>   
    </template>
    </v-data-table>
    </v-card>
    <v-row class="ma-2" align="center" justify="center">
    <v-col cols="auto" class="flex-items">
        <span class="px-2">Row Per Page: </span>
        <span>
          <v-select
            dense
            outlined
            hide-details
            :value="options.itemsPerPage"
            style="max-width: 90px"
            class="rounded-lg"
            @change="options.itemsPerPage = parseInt($event, 10)"
            :items="perPageChoices"
          >
          </v-select>
        </span>
    </v-col>
    <v-col cols="auto" class="mx-auto text-truncate" no-gutters>
    Showing {{ data.length > 0 ? ((paginationData.currentPage - 1) * options.itemsPerPage) + 1 : 0 }} to {{ data.length > 0 ? ((paginationData.currentPage - 1) * options.itemsPerPage) + data.length : 0 }} of {{ paginationData.count }} entries
    </v-col>
    <v-col cols="auto">
        <v-pagination
            v-model="options.page"
            class="rounded-lg"
            :total-visible="7"
            color="#38435a"
            :length="paginationData.lastPage">
        </v-pagination>
    </v-col>
      </v-row>
    </v-card>
    <v-dialog
      v-model="dialogConfirmation"
      width="520"
      persistent
    >
      <v-card>
        <v-card-title class="text-subtitle-1">
          <strong>Confirmation</strong>
        </v-card-title>

        <v-card-text>
            <div class="subtitle-1">
              Are you sure you want to <strong>DELETE</strong> this nationality group?
            </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            outlined
            class="mr-2"
            depressed
            @click="dialogConfirmation = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="red darken-3"
            class="white--text"
            depressed
            @click="confirmDelete()"
            :loading="loadingConfirm"
          >
            Yes, Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogForm"
      width="900"
      scrollable
      persistent
    >
      <v-card>
        <v-card-title>
          <strong v-if="action == 'add'">Add New Nationality Group</strong>
          <strong v-else-if="action == 'edit'">Update Nationality Group</strong>
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="closeFormDialog()"
          >
          <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pt-2" style="max-height: 900px;">
            <v-form ref="formRef">
              <v-row no-gutters>
                <v-col cols="12" class="py-0">
                  <div class="px-0 pb-1 text-subtitle1">Group Name</div>
                  <v-text-field
                    v-model="formdata.groupName"
                    color="grey"
                    :rules="[formRules.required, checkGroupName()]"
                    placeholder="Name"
                    height="45"
                    outlined
                    ></v-text-field>
                </v-col>
                <v-col cols="12" class="py-0">
                  <div class="px-0 pb-1 text-subtitle1">Countries</div>
                    <v-autocomplete
                    v-model="formdata.countries"
                    :items="items"
                    item-text="country"
                    item-value="code"
                    multiple
                    :search-input.sync="searchQuery"
                    @change="onSelectionChange"
                    dense
                    :menu-props="{ maxHeight: '400' }"
                    :selection-text="customSelectionText"
                    color="grey"
                    :rules="[formRules.required]"
                    outlined
                  >
                    <template v-slot:prepend-item>
                      <v-list-item ripple @click="selectAllItems">
                        <v-list-item-content>
                          <v-list-item-title>Select All</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>

                    <!-- Custom slot to render the selected items in the input as an empty string -->
                    <template v-slot:selection>
                      <span></span>  <!-- Display nothing in the input field -->
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-card class="elevation-0 DarkWhite">
                    <v-card-title>
                      Selected Countries
                      <v-spacer></v-spacer>
                      <v-btn small outlined depressed class="white--text" v-if="formdata.countries && formdata.countries.length > 1" color="red darken-2" @click="formdata.countries = []">Remove All</v-btn>
                    </v-card-title>
                    <v-card-text>
                      <v-chip
                        v-for="(item, index) in formdata.countries"
                        :key="'country_' + index"
                        class="ma-2"
                        color="primary"
                        text-color="white"
                        close
                        @click:close="removeItem(item)"
                      >
                        {{ getCountryName(item) }}
                      </v-chip>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="pa-3">
          <v-btn
            color="red darken-3"
            class="white--text"
            depressed
            outlined
            @click="deleteNationality()"
            v-if="formdata && formdata.id"
          >
          <v-icon class="mr-1">mdi-delete</v-icon>
            Delete
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            outlined
            class="mr-2"
            depressed
            @click="closeFormDialog()"
          >
            Cancel
          </v-btn>
          <v-btn
            color="#166ecf"
            class="white--text mr-3"
            depressed
            @click="confirmUpdate()"
            v-if="action == 'edit'"
            :loading="loadingForm"
          >
          <v-icon class="mr-1">mdi-square-edit-outline</v-icon>
            Update
          </v-btn>
          <v-btn
            color="#00a34e"
            class="white--text mr-3"
            depressed
            @click="confirmAdd()"
            v-else-if="action == 'add'"
            :loading="loadingForm"
          >
          <v-icon class="mr-1">mdi-check-circle</v-icon>
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</div>
</template>
<script>
  export default {
    components: {
    },
    data: () => ({
      search: '',
      headers: [
          { text: 'GROUP', value: 'groupName' },
          { text: 'NATIONALITIES', value: 'countries', width: 800 },
          { text: 'ACTIONS', value: 'actions', align: 'left', sortable: false, width: 200 },
        ],
      data: [],
      perPageChoices: [
          {text:'5' , value: 5},
          {text:'10' , value: 10},
          {text:'20' , value: 20},
          {text:'50' , value: 50},
          {text:'100' , value: 100},
          {text:'250' , value: 250},
          {text:'500' , value: 500},
      ],
      options: {},
      paginationData: {},
      loading: false,
      dialogConfirmation: false,
      loadingConfirm: false,
      edit_item: {},
      dialogForm: false,
      formdata: {},
      action: '',
      loadingForm: false,
      nationality_group_list: [],
      temp_group_name: null,
      searchQuery: '',
      items: [],
    }),
    watch: {
      options: {
        handler () {
         this.initialize();
        },
        deep: true,
      },
      search: {
        handler () {
          setTimeout(() => {
            this.options.page = 1;
            this.initialize();
          }, 600);
        }
      },
    },
    computed: {
      filteredItems() {
        if (!this.searchQuery) return this.items;
        const query = this.searchQuery.toLowerCase();
        return this.items.filter(item => item.country.toLowerCase().includes(query));
      }
    },
    mounted() {
      this.getNationalityGroupList();
      this.items = JSON.parse(JSON.stringify(this.$store.state.countries))
    },
    methods: {
      initialize (){
        this.loading = true;
        let page = this.options.page;
        let itemPerPage = this.options.itemsPerPage;
        let sortBy = this.options.sortBy.length > 0 ? this.options.sortBy[0] : '';
        let order = this.options.sortBy.length > 0 ? this.options.sortDesc[0] ? 'desc' : 'asc' : '';
        let search = this.search;
        this.axiosCall('/nationalities/getNationalities?page=' + page + '&itemPerPage=' + itemPerPage + '&sortBy=' + sortBy + '&order=' + order + '&searchKey=' + search, 'GET').then((res) => {
              this.loading = false
              this.data = res.data.data
              this.paginationData = res.data.pagination;
          })
      },
       closeFormDialog(){
        this.$refs.formRef.resetValidation();
        this.dialogForm = false
       },
       async edit(item){
        this.action = 'edit';
        this.formdata = JSON.parse(JSON.stringify(item));
        this.temp_group_name = item.groupName;
        this.formdata.countries = item.countries.split(",")
        this.dialogForm = true
       },
       async add(){
        this.action = 'add';
        this.formdata = {};
        this.dialogForm = true
       },
       async deleteNationality(){
        this.dialogConfirmation = true
       },
       confirmAdd(){
        if(this.$refs.formRef.validate()){
        this.loadingForm = true;
        let countries = this.formdata.countries
        let data = {
          groupName: this.formdata.groupName,
          countries: countries.join(","),
        };
        this.axiosCall("/nationalities/addNationality", "POST", data).then((res) => {
          if (res.data.status == 201) {
            this.formdata = {};
            this.initialize()
            this.showToast(res.data.msg, 'success')
            this.$refs.formRef.reset();
            this.closeFormDialog();
          }
          else{
            this.showToast(res.data.msg, 'error')
          }
          this.loadingForm = false;
        });
        }
       },
       confirmUpdate(){
        if(this.$refs.formRef.validate()){
        this.loadingForm = true;
        let countries = this.formdata.countries
        let data = {
          id: this.formdata.id,
          groupName: this.formdata.groupName,
          countries: countries.join(","),
        };
        this.axiosCall("/nationalities/updateNationality", "POST", data).then((res) => {
          if (res.data.status == 200) {
            this.formdata = {};
            this.initialize()
            this.showToast(res.data.msg, 'success')
            this.$refs.formRef.reset();
            this.closeFormDialog();
          }
          else{
            this.showToast(res.data.msg, 'error')
          }
          this.loadingForm = false;
        });
        }
       },
       confirmDelete(){
        if(this.formdata.id){
        this.loadingConfirm = true;
        let data = {
          id: this.formdata.id,
        };
        this.axiosCall("/nationalities/deleteNationality", "POST", data).then((res) => {
          if (res.data.status == 200) {
            this.formdata = {};
            this.initialize()
            this.showToast(res.data.msg, 'success')
            this.$refs.formRef.reset();
            this.closeFormDialog();
            this.dialogConfirmation = false;
          }
          else{
            this.showToast(res.data.msg, 'error')
          }
          this.loadingConfirm = false;
        });
        }
       },
       getNationalityGroupList(){
        this.axiosCall('/nationalities/getNationalityGroupList', 'GET').then((res) => {
            this.nationality_group_list = res.data;
          })
      },
      checkGroupName(){
        let group_name = String(this.formdata.groupName).trim();
        if(String(this.formdata.groupName).includes(" ")){
          return 'Space is not allowed in this field!'
        }
        else if(this.action == 'edit' && this.temp_group_name == group_name){
          return true;
        }
        else{
          return !this.nationality_group_list.some(group => group.toLowerCase().includes(group_name.toLowerCase())) || 'The Group name you entered already exist!'
        }
      },
      selectAllItems() {
      this.formdata.countries = this.items.map(country => country.code);
      },
      onSelectionChange() {
        this.searchQuery = '';
      },
      removeItem(item) {
        this.formdata.countries = this.formdata.countries.filter(code => code !== item);
      },
      customSelectionText() {
        return '';
      },
      getCountryName(code){
        const data = this.items.filter(country => country.code == code)
        return data[0].country;
      }
    }
  }
</script>
<style scoped>
.chip-status-1{
  background-color: #d7f7c2 !important;
  color: #2f8547 !important;
}
.chip-status-2{
  background-color: #cff5f6 !important;
  color: #1066c5 !important;
}
.chip-status-3{
  background-color: #ebeef1 !important;
  color: #616f7e !important;
}
.chip-status-4{
  background-color: #fcedb9 !important;
  color: #b02c00 !important;
}
.chip-status-5{
  background-color: #ffe7f2 !important;
  color: #c4093c !important;
}
.chip-status-6{
  background-color: #F0FFF8 !important;
  color: #18AB56 !important;
}
.chip-status-7{
  background-color: #fbe996 !important;
  color: #413a29 !important;
}
</style>