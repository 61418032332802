<template>
  <div class="pa-5">
    <v-card class="card-style rounded-1" >
    <v-row class="ma-2">
      <v-col cols="12" md="6" class="flex-items">
        <h2>GAds Campaigns</h2>
      </v-col>
      <v-col cols="12" sm="6" :align="currentSize >= 2 ? 'right' : 'center'" class="px-5 pt-4">
          <div class="drp-div-style">
            <date-range-picker class="drp-style" v-model="dateRange" format="MMM D, YYYY" :options="dr_options" />
            <v-icon class="pa-0">mdi-chevron-down</v-icon>
          </div>
      </v-col>
    </v-row>
    <v-card class="mb-1 dt-container" elevation="0" outlined>
    <v-data-table
        ref="dataTable"
        :loading="loading"
        :headers="headers"
        :items="data"
        item-key="ad_account_id"
        :options.sync="options"
        hide-default-footer
        :single-expand="singleExpand"
        :expanded.sync="expanded"
        show-expand
        class="clickable-rows"
        @click:row="toggleExpand"
    >
    <template v-slot:[`item.row_count`]="{ item }">
       {{numberWithCommasRDash(item.row_count || 0)}}
    </template>
    <template v-slot:[`item.impressions`]="{ item }">
       {{numberWithCommasRDash(item.impressions)}}
    </template>
    <template v-slot:[`item.clicks`]="{ item }">
       {{numberWithCommasRDash(item.clicks)}}
    </template>
    <template v-slot:[`item.ctr`]="{ item }">
       <span class="text-no-wrap">{{formatNumberRDashWithPercent((item.ctr || 0) * 100)}}</span>
    </template>
    <template v-slot:[`item.cost`]="{ item }">
       {{formatNumberRDash(item.cost)}}
    </template>
    <template v-slot:[`item.cpc`]="{ item }">
       {{formatNumberRDash(item.cpc)}}
    </template>
    <template v-slot:[`item.conversions`]="{ item }">
       {{numberWithCommasRDash(item.conversions)}}
    </template>
    <template v-slot:[`item.cpa`]="{ item }">
       {{formatNumberRDash(item.cpa)}}
    </template>
    <template v-slot:[`item.conversions_value`]="{ item }">
       {{formatNumberRDash(item.conversions_value)}}
    </template>
    <template v-slot:[`item.profit`]="{ item }">
       {{formatNumberRDash(item.profit)}}
    </template>
    <template v-slot:[`item.profit_margin`]="{ item }">
       <span class="text-no-wrap">{{formatNumberRDashWithPercent((item.profit_margin || 0) * 100)}}</span>
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length" class="px-0">
        <v-data-table
            :headers="headers_sub"
            :items="item.campaign_group || []"
            hide-default-footer
            :options.sync="options2"
            :items-per-page="(item.campaigns || []).length"
            :single-expand="singleExpand2"
            :expanded.sync="expanded2"
            show-expand
            item-key="name"
            class="clickable-rows pl-10"
            @click:row="toggleExpand2"
        >
          <template v-slot:[`item.impressions`]="{ item }">
            {{numberWithCommasRDash(item.impressions)}}
          </template>
          <template v-slot:[`item.clicks`]="{ item }">
            {{numberWithCommasRDash(item.clicks)}}
          </template>
          <template v-slot:[`item.total_ctr`]="{ item }">
            <span class="text-no-wrap">{{formatNumberRDashWithPercent((item.total_ctr || 0) * 100)}}</span>
          </template>
          <template v-slot:[`item.total_cost`]="{ item }">
            {{formatNumberRDash(item.total_cost)}}
          </template>
          <template v-slot:[`item.total_average_cpc`]="{ item }">
            {{formatNumberRDash(item.total_average_cpc)}}
          </template>
          <template v-slot:[`item.total_conversions`]="{ item }">
            {{numberWithCommasRDash(item.total_conversions)}}
          </template>
          <template v-slot:[`item.total_average_cpa`]="{ item }">
            {{formatNumberRDash(item.total_average_cpa)}}
          </template>
          <template v-slot:[`item.total_conversions_value`]="{ item }">
            {{formatNumberRDash(item.total_conversions_value)}}
          </template>
          <template v-slot:[`item.profit`]="{ item }">
            {{formatNumberRDash(item.profit)}}
          </template>
          <template v-slot:[`item.profit_margin`]="{ item }">
            <span class="text-no-wrap">{{formatNumberRDashWithPercent((item.profit_margin || 0) * 100)}}</span>
          </template>

          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="px-0">
              <v-data-table
                  :headers="headers_sub2"
                  :items="item.campaigns || []"
                  hide-default-footer
                  :options.sync="options3"
                  :items-per-page="(item.campaigns || []).length"
                  class="pl-10"
              >
              <template v-slot:[`item.status`]="{ item }">
                
                <v-chip v-if="item.status == 'REMOVED'"
                    class="chip-status-5 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap"
                    small
                  >
                  <span>Removed</span>
                </v-chip>
                <v-chip v-else
                  class="chip-status-1 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap"
                  small
                >
                  <span>Enabled</span>
                </v-chip>
              </template>
                <template v-slot:[`item.impressions`]="{ item }">
                  {{numberWithCommasRDash(item.impressions)}}
                </template>
                <template v-slot:[`item.clicks`]="{ item }">
                  {{numberWithCommasRDash(item.clicks)}}
                </template>
                <template v-slot:[`item.total_ctr`]="{ item }">
                  <span class="text-no-wrap">{{formatNumberRDashWithPercent((item.total_ctr || 0) * 100)}}</span>
                </template>
                <template v-slot:[`item.total_cost`]="{ item }">
                  {{formatNumberRDash(item.total_cost)}}
                </template>
                <template v-slot:[`item.total_average_cpc`]="{ item }">
                  {{formatNumberRDash(item.total_average_cpc)}}
                </template>
                <template v-slot:[`item.total_conversions`]="{ item }">
                  {{numberWithCommasRDash(item.total_conversions)}}
                </template>
                <template v-slot:[`item.total_average_cpa`]="{ item }">
                  {{formatNumberRDash(item.total_average_cpa)}}
                </template>
                <template v-slot:[`item.total_conversions_value`]="{ item }">
                  {{formatNumberRDash(item.total_conversions_value)}}
                </template>
                <template v-slot:[`item.profit`]="{ item }">
                  {{formatNumberRDash(item.profit)}}
                </template>
                <template v-slot:[`item.profit_margin`]="{ item }">
                  <span class="text-no-wrap">{{formatNumberRDashWithPercent((item.profit_margin || 0) * 100)}}</span>
                </template>
              </v-data-table>
            </td>
          </template>

        </v-data-table>
      </td>
    </template>
    </v-data-table>
    </v-card>
    <v-row class="ma-2" align="center" justify="center">
    <v-col cols="auto" class="flex-items">
        <span class="px-2">Row Per Page: </span>
        <span>
          <v-select
            dense
            outlined
            hide-details
            :value="options.itemsPerPage"
            style="max-width: 90px"
            class="rounded-lg"
            @change="options.itemsPerPage = parseInt($event, 10)"
            :items="perPageChoices"
          >
          </v-select>
        </span>
    </v-col>
    <v-col cols="auto" class="mx-auto text-truncate" no-gutters>
    <!-- Showing {{ data.length > 0 ? ((paginationData.currentPage - 1) * options.itemsPerPage) + 1 : 0 }} to {{ data.length > 0 ? ((paginationData.currentPage - 1) * options.itemsPerPage) + data.length : 0 }} of {{ paginationData.count }} entries -->
    </v-col>
    <v-col cols="auto">
        <v-pagination
            v-model="options.page"
            class="rounded-lg"
            :total-visible="7"
            color="#38435a"
            :length="paginationData.lastPage">
        </v-pagination>
    </v-col>
      </v-row>
    </v-card>
    <v-dialog
      v-model="dialogConfirmation"
      width="520"
      persistent
    >
      <v-card>
        <v-card-title class="text-h6">
          <strong>CONFIRMATION</strong>
        </v-card-title>

        <v-card-text>
            <div class="subtitle-1">
              Are you sure you want to {{edit_item.switch_enabled ? 'enable' : 'disable'}} <strong>{{edit_item.name}}</strong>?
            </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            outlined
            class="mr-2"
            depressed
            @click="cancelSwitch()"
          >
            Cancel
          </v-btn>
          <v-btn
            color="#166ecf"
            class="white--text"
            depressed
            @click="confirmSetClient()"
            v-if="edit_item.switch_enabled"
            :loading="loadingConfirm"
          >
            Yes, Enable
          </v-btn>
          <v-btn
            color="#d13c56"
            class="white--text"
            depressed
            @click="confirmSetClient()"
            v-if="!edit_item.switch_enabled"
            :loading="loadingConfirm"
          >
            Yes, Disable
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</div>
</template>
<script>
import moment from "moment";
  export default {
    props: {
      tab: null,
    },
    components: {
    },
    data: () => ({
      search: '',
      headers: [
          { text: 'ACCOUNT', value: 'company_name' },
          { text: 'TOTAL ROWS', value: 'campaign_count', align: 'end'},
          { text: 'IMPR', value: 'impressions', align: 'end', width: 130},
          { text: 'CLICKS', value: 'clicks', align: 'end', width: 130},
          { text: 'CTR', value: 'ctr', align: 'end', width: 90},
          { text: 'COST', value: 'cost', align: 'end', width: 130},
          { text: 'AVG CPC', value: 'cpc', align: 'end', width: 101},
          { text: 'CONV', value: 'conversions', align: 'end', width: 100},
          { text: 'AVG CPA', value: 'cpa', align: 'end', width: 100},
          { text: 'REV', value: 'conversions_value', align: 'end', width: 130},
          { text: 'PROFIT', value: 'profit', align: 'end', width: 130},
          { text: 'PM', value: 'profit_margin', align: 'end', width: 90},
          // { text: "", value: "data-table-expand", width: 1, align: 'center', sortable: false }
        ],
        headers_sub: [
          { text: 'PRODUCT', value: 'name' },
          { text: 'IMPR', value: 'impressions', align: 'end', width: 130},
          { text: 'CLICKS', value: 'clicks', align: 'end', width: 130},
          { text: 'CTR', value: 'total_ctr', align: 'end', width: 90},
          { text: 'COST', value: 'total_cost', align: 'end', width: 130},
          { text: 'AVG CPC', value: 'total_average_cpc', align: 'end', width: 101},
          { text: 'CONV', value: 'total_conversions', align: 'end', width: 100},
          { text: 'AVG CPA', value: 'total_average_cpa', align: 'end', width: 100},
          { text: 'REV', value: 'total_conversions_value', align: 'end', width: 130},
          { text: 'PROFIT', value: 'profit', align: 'end', width: 130},
          { text: 'PM', value: 'profit_margin', align: 'end', width: 90},
          // { text: "", value: "data-table-expand", width: 1, align: 'center', sortable: false }
        ],
        headers_sub2: [
          { text: 'CAMPAIGN NAME', value: 'name' },
          { text: 'STATUS', value: 'status', align: 'center'},
          { text: 'IMPR', value: 'impressions', align: 'end', width: 130},
          { text: 'CLICKS', value: 'clicks', align: 'end', width: 130},
          { text: 'CTR', value: 'total_ctr', align: 'end', width: 90},
          { text: 'COST', value: 'total_cost', align: 'end', width: 130},
          { text: 'AVG CPC', value: 'total_average_cpc', align: 'end', width: 101},
          { text: 'CONV', value: 'total_conversions', align: 'end', width: 100},
          { text: 'AVG CPA', value: 'total_average_cpa', align: 'end', width: 100},
          { text: 'REV', value: 'total_conversions_value', align: 'end', width: 130},
          { text: 'PROFIT', value: 'profit', align: 'end', width: 130},
          { text: 'PM', value: 'profit_margin', align: 'end', width: 90},
          // { text: "", value: "data-table-expand", width: 1, align: 'center', sortable: false }
        ],
      data: [],
      perPageChoices: [
          {text:'5' , value: 5},
          {text:'10' , value: 10},
          {text:'20' , value: 20},
          {text:'50' , value: 50},
          {text:'100' , value: 100},
          {text:'250' , value: 250},
          {text:'500' , value: 500},
      ],
      options: {},
      options2: {},
      options3: {},
      paginationData: {},
      loading: false,
      formdata: [],
      loadingConfirm: false,
      edit_item: {},
      dialogConfirmation: false,
      dateRange: [],
      dr_options: {
        ranges: {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
        },
        "buttonClasses": "btn btn-lg",
        "applyButtonClasses": "btn-primary"
        },
      startDate: null,
      endDate: null,
      expanded: [],
      singleExpand: true,
      expanded2: [],
      singleExpand2: true,
    }),
    watch: {
      options: {
        handler () {
         this.initialize();
        },
        deep: true,
      },
      dateRange: {
        handler(data){
          if(data.length == 2){
          var date1 = new Date(data[0]);
          this.startDate = parseInt(date1.getDate()) + '-' + parseInt(String(date1.getMonth() + 1).padStart(2, '0')) + '-' + date1.getFullYear();
          var date2 = new Date(data[1]);
          this.endDate = parseInt(date2.getDate()) + '-' + parseInt(String(date2.getMonth() + 1).padStart(2, '0')) + '-' + date2.getFullYear();
          this.initialize();
          }
        }
      }
    },
    computed: {
      currentSize() {
        const breakpoint = this.$vuetify.breakpoint;
        if (breakpoint.xs) return 1;
        if (breakpoint.sm) return 2;
        if (breakpoint.md) return 3;
        if (breakpoint.lg) return 4;
        if (breakpoint.xl) return 5;
        return 0;
      }
    },
    mounted() {
      var date1 = new Date();
      this.startDate = parseInt(date1.getDate()) + '-' + parseInt(String(date1.getMonth() + 1).padStart(2, '0')) + '-' + date1.getFullYear();
      var date2 = new Date();
      this.endDate = parseInt(date2.getDate()) + '-' + parseInt(String(date2.getMonth() + 1).padStart(2, '0')) + '-' + date2.getFullYear();
      this.initialize();
    },
    methods: {
      initialize (){
        if(this.startDate && this.endDate){
        this.loading = true;
        let page = this.options.page;
        let itemPerPage = this.options.itemsPerPage;
        let sortBy = this.options.sortBy.length > 0 ? this.options.sortBy[0] : '';
        let order = this.options.sortBy.length > 0 ? this.options.sortDesc[0] ? 'desc' : 'asc' : '';
        let search = this.search;
        let startDate = this.startDate;
        let endDate = this.endDate;
        this.axiosCall('/dashboard/getCampaigns?page=' + page + '&itemPerPage=' + itemPerPage + '&sortBy=' + sortBy + '&order=' + order + '&searchKey=' + search + '&startDate=' + startDate + '&endDate=' + endDate, 'GET').then((res) => {
              if(res.data){
              this.loading = false
              res.data.forEach(item => {
                item.ctr = item.impressions > 0 ? item.clicks / item.impressions : 0;
                item.cpc = item.clicks > 0 ? item.cost / item.clicks : 0;
                item.cpa = item.conversions > 0 ? item.cost / item.conversions : 0;
                item.profit = item.conversions_value - item.cost;
                item.profit_margin = item.conversions_value > 0 ? (item.conversions_value - item.cost) / item.conversions_value : 0;
                  const groupedData = item.campaigns.reduce((acc, curr) => {
                  const groupName = curr.name.split(' - ')[0];
                  
                  if (!acc[groupName]) {
                    acc[groupName] = {
                      name: groupName,
                      campaigns: [],
                    };
                  }

                  // Add the current record to the group members
                  acc[groupName].campaigns.push(curr);

                  acc[groupName].impressions = (parseFloat(acc[groupName].impressions || 0) + parseFloat(curr.impressions)).toString();
                  acc[groupName].clicks = (parseInt(acc[groupName].clicks || 0) + parseInt(curr.clicks)).toString();
                  acc[groupName].total_ctr = ((parseFloat(acc[groupName].total_ctr || 0) + parseFloat(curr.total_ctr)) / 2).toString();
                  acc[groupName].total_cost = (parseFloat(acc[groupName].total_cost || 0) + parseFloat(curr.total_cost)).toString();
                  acc[groupName].total_average_cpc = (parseFloat(acc[groupName].total_average_cpc || 0) + parseFloat(curr.total_average_cpc)) / 2;
                  acc[groupName].total_conversions = (parseInt(acc[groupName].total_conversions || 0) + parseInt(curr.total_conversions)).toString();
                  acc[groupName].total_average_cpa = (parseFloat(acc[groupName].total_average_cpa || 0) + parseFloat(curr.total_average_cpa)) / 2;
                  acc[groupName].total_conversions_value = (parseFloat(acc[groupName].total_conversions_value || 0) + parseFloat(curr.total_conversions_value)).toString();
                  acc[groupName].profit = (parseFloat(acc[groupName].profit || 0) + parseFloat(curr.profit)).toString();
                  acc[groupName].profit_margin = (parseFloat(acc[groupName].profit_margin || 0) + parseFloat(curr.profit_margin)) / 2;
                  acc[groupName].revenue = (parseFloat(acc[groupName].revenue || 0) + parseFloat(curr.revenue)).toString();

                  return acc;
                }, {});

                item.campaign_group = Object.values(groupedData);
                });
              this.data = res.data || [];
              console.log(this.data)
              // this.paginationData = res.data.pagination;
              }
          })
        }
      },
      cancelSwitch(){
        this.edit_item.switch_enabled = !this.edit_item.switch_enabled;
        this.dialogConfirmation = false;
      },
      setClient(item){
        this.edit_item = item;
        this.dialogConfirmation = true;
       },
       confirmSetClient(){
        this.loadingConfirm = true;
        let data = {
          token: this.edit_item.token,
          active: this.edit_item.switch_enabled ? 1 : 0,
        };
        this.axiosCall("/clients/updateStatus", "POST", data).then((res) => {
          if (res.data.status == 200) {
            this.edit_item.active = this.edit_item.switch_enabled ? 1 : 0;
            this.edit_item = {};
            this.showToast(res.data.msg, 'success')
          }
          else{
            this.edit_item.switch_enabled = !this.edit_item.switch_enabled
            this.showToast(res.data.msg, 'error')
          }
          this.loadingConfirm = false;
          this.dialogConfirmation = false;
        });
       },
       toggleExpand(item) {
          const index = this.expanded.indexOf(item.id);
          if (index === -1) {
            this.expanded = [item];
          } else {
            this.expanded.splice(index, 1);
          }
       },
       toggleExpand2(item) {
          const index = this.expanded2.indexOf(item.id);
          if (index === -1) {
            this.expanded2 = [item];
          } else {
            this.expanded2.splice(index, 1);
          }
       }
    }
  }
</script>
<style scoped>
.chip-status-1{
  background-color: #d7f7c2 !important;
  color: #2f8547 !important;
}
.chip-status-2{
  background-color: #cff5f6 !important;
  color: #1066c5 !important;
}
.chip-status-3{
  background-color: #ebeef1 !important;
  color: #616f7e !important;
}
.chip-status-4{
  background-color: #fcedb9 !important;
  color: #b02c00 !important;
}
.chip-status-5{
  background-color: #ffe7f2 !important;
  color: #c4093c !important;
}
.chip-status-6{
  background-color: #F0FFF8 !important;
  color: #18AB56 !important;
}
</style>