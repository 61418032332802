<template>
    <div class="py-8">
      <v-form @submit.prevent ref="form">
        <v-row class="flex-items">
          <v-col><v-divider color="#909fa9"></v-divider></v-col>
          <v-col cols="auto" class="mx-auto">
            <span class="text-subtitle1 view-main-title">Representative Information</span>
          </v-col>
          <v-col><v-divider color="#909fa9"></v-divider></v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Applicant Relationship | (I Am?)</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="edit_prop != 'representative_relationship'"><strong class="clipboard-style" v-clipboard="data.representative_relationship">{{data.representative_relationship}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="edit('representative_relationship', data.representative_relationship)">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-text-field
                    v-model="edit_val"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Are you being paid to represent the applicant and complete the form on their behalf?</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="edit_prop != 'representative_compensated_bool'"><strong class="clipboard-style" v-clipboard="data.representative_compensated_bool ? 'YES' : 'NO'">{{data.representative_compensated_bool ? 'YES' : 'NO'}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor'" small @click="edit('representative_compensated_bool', data.representative_compensated_bool)">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-radio-group
                    v-model="edit_val"
                    :rules="[formRules.required3]"
                    row
                    class="mt-0"
                    >
                    <v-radio
                        label="No"
                        :value="false"
                        color="#00a34e"
                    ></v-radio>
                    <v-radio
                        label="Yes"
                        :value="true"
                        color="#00a34e"
                    ></v-radio>
                </v-radio-group>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" v-if="data.representative_relationship == 'A member of the College of Immigration and Citizenship Consultants (CICC)'">
            <v-row>
            <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Membership ID number?</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
              <strong class="clipboard-style" v-clipboard="data.representative_membership_id_number">{{data.representative_membership_id_number}}</strong>
            </v-col>
            <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Which province or territory?</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
              <strong class="clipboard-style" v-clipboard="data.representative_province">{{data.representative_province}}</strong>
            </v-col>
            <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Postal code</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
              <strong class="clipboard-style" v-clipboard="data.representative_postal_code_zip">{{data.representative_postal_code_zip}}</strong>
            </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Surname</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="edit_prop != 'representative_family_name'"><strong class="clipboard-style" v-clipboard="data.representative_family_name">{{data.representative_family_name}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="edit('representative_family_name', data.representative_family_name)">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-text-field
                    v-model="edit_val"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Given Names</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="edit_prop != 'representative_first_name'"><strong class="clipboard-style" v-clipboard="data.representative_first_name">{{data.representative_first_name}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="edit('representative_first_name', data.representative_first_name)">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-text-field
                    v-model="edit_val"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Name of firm, organization</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="edit_prop != 'representative_organization_name'"><strong class="clipboard-style" v-clipboard="data.representative_organization_name">{{data.representative_organization_name}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="edit('representative_organization_name', data.representative_organization_name)">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-text-field
                    v-model="edit_val"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Mailing address</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="edit_prop != 'representative_mailing_address'"><strong class="clipboard-style" v-clipboard="data.representative_mailing_address">{{data.representative_mailing_address}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="edit('representative_mailing_address', data.representative_mailing_address)">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-text-field
                    v-model="edit_val"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Email address</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="edit_prop != 'representative_email'"><strong class="clipboard-style" v-clipboard="data.representative_email">{{data.representative_email}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="edit('representative_email', data.representative_email)">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-text-field
                    v-model="edit_val"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Fax number</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="edit_prop != 'representative_fax_number'"><strong class="clipboard-style" v-clipboard="data.representative_fax_number">{{data.representative_fax_number}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="edit('representative_fax_number', data.representative_fax_number)">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-text-field
                    v-model="edit_val"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Telephone number</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="edit_prop != 'representative_phone_number'"><strong class="clipboard-style" v-clipboard="data.representative_phone_number">{{data.representative_phone_number}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="edit('representative_phone_number', data.representative_phone_number)">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-text-field
                    v-model="edit_val"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
        </v-row>
        <v-row class="flex-items">
          <v-col><v-divider color="#909fa9"></v-divider></v-col>
          <v-col cols="auto" class="mx-auto">
            <span class="text-subtitle1 view-main-title">Applicant Information</span>
          </v-col>
          <v-col><v-divider color="#909fa9"></v-divider></v-col>
        </v-row>
        <v-row>
            <!-- <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Applicant Photo
            <br />
            <v-chip
              class="chip-status-2 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap"
              small
              v-if="data.photo_details_optimized"
            >
            <span>Compressed</span>
            </v-chip>
            <v-chip
              class="chip-status-1 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap"
              small
              v-else
            >
            <span>Non-Compressed</span>
            </v-chip>
            </v-col> <v-col cols="12" sm="6" class="py-1">
            <div v-if="data.photo_details_optimized">
            <div><img class="image-fluid rounded-lg" height="300" :src="data.photo_details_optimized"/></div>
            <v-btn color="#f5ca99" class="px-15 py-4" depressed :href="data.photo_details_optimized" download><v-icon>mdi-download</v-icon> Download Photo</v-btn>
            </div>
            <div v-else>
            <div><img class="image-fluid rounded-lg" height="300" :src="'https://esta-apply.s3.amazonaws.com/' + data.photo_details"/></div>
            <v-btn color="#f5ca99" class="px-15 py-4" depressed :href="'https://esta-apply.s3.amazonaws.com/' + data.photo_details" download><v-icon>mdi-download</v-icon> Download Photo</v-btn>
            </div>
          </v-col> -->
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">What travel document do you plan to use to travel to Canada?</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <strong class="clipboard-style" v-clipboard="data.travel_document_type">{{data.travel_document_type}}</strong>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Select the code that matches the one on your passport.</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <strong class="clipboard-style" v-clipboard="data.country_of_citizenship">{{data.country_of_citizenship}}</strong>
          </v-col>
          <v-col cols="12" v-if="data.passport_noted_nationality_code">
            <v-row>
                <v-col cols="12" sm="6" class="text-sm-end view-title py-1">What is the nationality noted on this passport?</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
                    <span class="flex-items clipboard-style" v-clipboard="getCountry(data.passport_noted_nationality_code)"><img v-if="data.passport_noted_nationality_code" class="rounded-circle mr-2" width="18" :src="require('@/assets/img/flags/1x1/' + (data.passport_noted_nationality_code.toLowerCase()).substring(0, 2) + '.svg')" /><strong>{{data.passport_noted_nationality}}</strong></span>
                </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Passport Number</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="edit_prop != 'passport_number'"><strong class="clipboard-style" v-clipboard="sanitizeString(data.family_names)">{{sanitizeString(data.passport_number)}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="edit('passport_number', data.passport_number)">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-text-field
                    v-model="edit_val"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Surname</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="edit_prop != 'family_names'"><strong class="clipboard-style" v-clipboard="(urlDecode(data.family_names)).toUpperCase()">{{(urlDecode(data.family_names)).toUpperCase()}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="edit('family_names', data.family_names)">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-text-field
                    v-model="edit_val"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">First (Given) Name</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="edit_prop != 'first_given_names'"><strong class="clipboard-style" v-clipboard="(urlDecode(data.first_given_names)).toUpperCase()">{{(urlDecode(data.first_given_names)).toUpperCase()}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="edit('first_given_names', data.first_given_names)">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-text-field
                    v-model="edit_val"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Date of Birth (Year/Month/Day)</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="!edit_prop_date || edit_prop_date.join(',') != 'dob_month,dob_year,dob_day'"><strong class="clipboard-style" v-clipboard="data.dob_year + ' / ' + monthNumToName(data.dob_month) + ' / ' + data.dob_day">{{data.dob_year + ' / ' + monthNumToName(data.dob_month) + ' / ' + data.dob_day}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="editDate(['dob_year','dob_month','dob_day'], [data.dob_year, data.dob_month, data.dob_day])">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-autocomplete
                    v-model="edit_year"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    placeholder="Year"
                    :items="years"
                    hide-details="auto"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" class="pb-1">
                  <v-autocomplete
                    v-model="edit_month"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    placeholder="Month"
                    item-text="desc"
                    item-value="id"
                    :items="months"
                    hide-details="auto"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" class="pb-1">
                  <v-autocomplete
                    v-model="edit_day"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    placeholder="Day"
                    :items="days"
                    hide-details="auto"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Gender</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="edit_prop != 'gender'"><strong class="clipboard-style" v-clipboard="data.gender ? gender.find(item => item.id == data.gender).desc : ''">{{data.gender ? gender.find(item => item.id == data.gender).desc : ''}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="edit('gender', data.gender)">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-autocomplete
                    v-model="edit_val"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    item-text="desc"
                    item-value="id"
                    :items="sex"
                    hide-details="auto"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" v-if="data.country_of_birth_code">
            <v-row>
                <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Country of Birth</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
                    <span class="flex-items clipboard-style" v-clipboard="getCountry(data.country_of_birth_code)"><img v-if="data.country_of_birth_code" class="rounded-circle mr-2" width="18" :src="require('@/assets/img/flags/1x1/' + (data.country_of_birth_code.toLowerCase()).substring(0, 2) + '.svg')" /><strong>{{data.country_of_birth}}</strong></span>
                </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">City/Town of Birth</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="edit_prop != 'city_town_of_birth'"><strong class="clipboard-style" v-clipboard="sanitizeString(urlDecode(data.city_town_of_birth))">{{urlDecode(data.city_town_of_birth)}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="edit('city_town_of_birth', data.city_town_of_birth)">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-text-field
                    v-model="edit_val"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Date of issue of passport (Year/Month/Day)</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="!edit_prop_date || edit_prop_date.join(',') != 'issue_date_year,issue_date_month,issue_date_day'"><strong class="clipboard-style" v-clipboard="data.issue_date_year + ' / ' + monthNumToName(data.issue_date_month) + ' / ' + data.issue_date_day">{{data.issue_date_year + ' / ' + monthNumToName(data.issue_date_month) + ' / ' + data.issue_date_month}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="editDate(['issue_date_year','issue_date_month','issue_date_day'], [data.issue_date_year, data.issue_date_month, data.issue_date_day])">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-autocomplete
                    v-model="edit_year"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    placeholder="Year"
                    :items="years"
                    hide-details="auto"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" class="pb-1">
                  <v-autocomplete
                    v-model="edit_month"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    placeholder="Month"
                    item-text="desc"
                    item-value="id"
                    :items="months"
                    hide-details="auto"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" class="pb-1">
                  <v-autocomplete
                    v-model="edit_day"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    placeholder="Day"
                    :items="days"
                    hide-details="auto"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Date of expiry of passport (Year/Month/Day)</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="!edit_prop_date || edit_prop_date.join(',') != 'expiry_date_year,expiry_date_month,expiry_date_day'"><strong class="clipboard-style" v-clipboard="data.expiry_date_year + ' / ' + monthNumToName(data.expiry_date_month) + ' / ' + data.expiry_date_day">{{data.expiry_date_year + ' / ' + monthNumToName(data.expiry_date_month) + ' / ' + data.expiry_date_day}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="editDate(['expiry_date_year','expiry_date_month','expiry_date_day'], [data.expiry_date_year, data.expiry_date_month, data.expiry_date_day])">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-autocomplete
                    v-model="edit_year"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    placeholder="Year"
                    :items="years2"
                    hide-details="auto"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" class="pb-1">
                  <v-autocomplete
                    v-model="edit_month"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    placeholder="Month"
                    item-text="desc"
                    item-value="id"
                    :items="months"
                    hide-details="auto"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" class="pb-1">
                  <v-autocomplete
                    v-model="edit_day"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    placeholder="Day"
                    :items="days"
                    hide-details="auto"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" v-if="data.passport_noted_nationality_code">
            <v-row>
                <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Country of Birth</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
                    <span class="flex-items clipboard-style" v-clipboard="getCountry(data.passport_noted_nationality_code)"><img v-if="data.passport_noted_nationality_code" class="rounded-circle mr-2" width="18" :src="require('@/assets/img/flags/1x1/' + (data.passport_noted_nationality_code.toLowerCase()).substring(0, 2) + '.svg')" /><strong>{{data.passport_noted_nationality}}</strong></span>
                </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Preferred language to contact you</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="edit_prop != 'language_of_preference'"><strong class="clipboard-style" v-clipboard="data.language_of_preference">{{data.language_of_preference}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="edit('language_of_preference', data.language_of_preference)">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-text-field
                    v-model="edit_val"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Preferred language to contact you</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="edit_prop != 'language_of_preference'"><strong class="clipboard-style" v-clipboard="data.language_of_preference">{{data.language_of_preference}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="edit('language_of_preference', data.language_of_preference)">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-text-field
                    v-model="edit_val"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Have you ever applied for or obtained a visa, an eTA or a permit to visit, live, work or study in Canada?</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="edit_prop != 'has_previously_applied_to_canada'"><strong class="clipboard-style" v-clipboard="data.has_previously_applied_to_canada">{{data.has_previously_applied_to_canada}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="edit('has_previously_applied_to_canada', data.has_previously_applied_to_canada)">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-text-field
                    v-model="edit_val"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" v-if="data.has_previously_applied_to_canada == 'Yes'">
            <v-row>
                <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Unique client identifier (UCI) / Previous Canadian visa, eTA or permit number (optional)</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
                    <strong class="clipboard-style" v-clipboard="data.uci">{{data.uci}}</strong>
                </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" v-if="data.marital_status">
            <v-row>
                <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Marital</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
                    <strong class="clipboard-style" v-clipboard="data.marital_status">{{data.marital_status}}</strong>
                </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Email</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="edit_prop != 'email'"><strong class="clipboard-style" v-clipboard="data.email">{{data.email}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="edit('email', data.email)">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-text-field
                    v-model="edit_val"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12"><v-divider color="#909fa9"></v-divider></v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Apartment/unit number (if applicable)</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="edit_prop != 'apt_unit'"><strong class="clipboard-style" v-clipboard="data.apt_unit">{{data.apt_unit}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="edit('apt_unit', data.apt_unit)">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-text-field
                    v-model="edit_val"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Street/civic number or house name</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="edit_prop != 'street_no'"><strong class="clipboard-style" v-clipboard="data.street_no">{{data.street_no}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="edit('street_no', data.street_no)">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-text-field
                    v-model="edit_val"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Street address/name</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="edit_prop != 'address_line_2'"><strong class="clipboard-style" v-clipboard="data.address_line_2">{{data.address_line_2}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="edit('address_line_2', data.address_line_2)">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-text-field
                    v-model="edit_val"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Street address/name line 2 (if applicable)</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="edit_prop != 'street_address_alt'"><strong class="clipboard-style" v-clipboard="data.street_address_alt">{{data.street_address_alt}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="edit('street_address_alt', data.street_address_alt)">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-text-field
                    v-model="edit_val"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">City/town</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="edit_prop != 'city'"><strong class="clipboard-style" v-clipboard="data.city">{{data.city}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="edit('city', data.city)">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-text-field
                    v-model="edit_val"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Country/territory</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="edit_prop != 'country'"><strong class="clipboard-style" v-clipboard="data.country">{{data.country}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="edit('country', data.country)">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-text-field
                    v-model="edit_val"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">District</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="edit_prop != 'district'"><strong class="clipboard-style" v-clipboard="data.district">{{data.district}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="edit('district', data.district)">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-text-field
                    v-model="edit_val"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" v-if="data.occupation">
            <v-row>
                <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Occupation</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
                    <strong class="clipboard-style" v-clipboard="data.occupation">{{data.occupation}}</strong>
                </v-col>
            </v-row>
            <v-row v-if="data.title">
                <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Job Title</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
                    <strong class="clipboard-style" v-clipboard="data.title">{{data.title}}</strong>
                </v-col>
                <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Name of employer or school, as appropriate.</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
                    <strong class="clipboard-style" v-clipboard="data.company_employer_school_facility_name">{{data.company_employer_school_facility_name}}</strong>
                </v-col>
                <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Country</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
                    <strong class="clipboard-style" v-clipboard="data.occupation_country">{{data.occupation_country}}</strong>
                </v-col>
                <v-col cols="12" v-if="data.occupation_state">
                    <v-row>
                        <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Province/state</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
                            <strong class="clipboard-style" v-clipboard="data.occupation_state">{{data.occupation_state}}</strong>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" sm="6" class="text-sm-end view-title py-1">City</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
                    <strong class="clipboard-style" v-clipboard="data.occupation_city">{{data.occupation_city}}</strong>
                </v-col>
                <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Year</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
                    <strong class="clipboard-style" v-clipboard="data.occupation_from_date_year">{{data.occupation_from_date_year}}</strong>
                </v-col>
            </v-row>
          </v-col>
          <v-col cols="12"><v-divider color="#909fa9"></v-divider></v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Do you know when you will travel to Canada?</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
                <strong class="clipboard-style" v-clipboard="data.is_travel_date_known_bool ? 'Yes' : 'No'">{{data.is_travel_date_known_bool ? 'Yes' : 'No'}}</strong>
            </v-col>
            <v-col cols="12" v-if="data.is_travel_date_known_bool">
                <v-row>
                    <v-col cols="12" sm="6" class="text-sm-end view-title py-1">PWhen do you plan to travel to Canada? (Year/Month/Day)</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
                        <strong class="clipboard-style" v-clipboard="data.travel_date_year + '/' + monthNumToName(data.travel_date_month) + '/' + data.travel_date_day">{{data.travel_date_year + '/' + monthNumToName(data.travel_date_month) + '/' + data.travel_date_day}}</strong>
                    </v-col>
                    <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Please enter the time your flight to Canada will depart?</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
                        <span><span style="color:#677788">Hour: </span><strong class="clipboard-style" v-clipboard="data.travel_date_time_hour">{{data.travel_date_time_hour}}</strong></span>&nbsp;&nbsp;
                        <span><span style="color:#677788">Minutes: </span><strong class="clipboard-style" v-clipboard="data.travel_date_time_minute">{{data.travel_date_time_minute}}</strong></span>&nbsp;&nbsp;
                        <span><span style="color:#677788">Timezone: </span><strong class="clipboard-style" v-clipboard="data.travel_date_time_timezone">{{data.travel_date_time_timezone}}</strong></span>
                    </v-col>
                </v-row>
          </v-col>
          <v-col cols="12" v-if="data.refused_visa_or_permit_or_denied_entry_to_canada">
            <v-row class="flex-items">
                <v-col><v-divider color="#909fa9"></v-divider></v-col>
                <v-col cols="auto" class="mx-auto">
                    <span class="text-subtitle1 view-main-title">Background Questions</span>
                </v-col>
                <v-col><v-divider color="#909fa9"></v-divider></v-col>
            </v-row>
                <v-row>
                    <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Have you ever been refused a visa or permit, denied entry to, or ordered to leave Canada or any other country/territory?</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
                        <strong class="clipboard-style" v-clipboard="data.refused_visa_or_permit_or_denied_entry_to_canada">{{data.refused_visa_or_permit_or_denied_entry_to_canada}}</strong>
                    </v-col>
                </v-row>
                <v-row v-if="data.refused_visa_or_permit_or_denied_entry_to_canada_details">
                    <v-col cols="12" sm="6" class="text-sm-end view-title py-1">For each refusal, please indicate the country that refused you a visa or permit, or denied you entry, as well as the reasons provided to you by the country.</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
                        <strong class="clipboard-style" v-clipboard="data.refused_visa_or_permit_or_denied_entry_to_canada_details">{{data.refused_visa_or_permit_or_denied_entry_to_canada_details}}</strong>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Have you ever committed, been arrested for, been charged with or convicted of any criminal offence in any country/territory?</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
                        <strong class="clipboard-style" v-clipboard="data.committed_or_arrested_or_charged_or_convicted_of_criminal_offence_anywhere">{{data.committed_or_arrested_or_charged_or_convicted_of_criminal_offence_anywhere}}</strong>
                    </v-col>
                </v-row>
                <v-row v-if="data.committed_or_arrested_or_charged_or_convicted_of_criminal_offence_anywhere_details">
                    <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Have you ever committed, been arrested for, been charged with or convicted of any criminal offence in any country/territory?</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
                        <strong class="clipboard-style" v-clipboard="data.committed_or_arrested_or_charged_or_convicted_of_criminal_offence_anywhere_details">{{data.committed_or_arrested_or_charged_or_convicted_of_criminal_offence_anywhere_details}}</strong>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="6" class="text-sm-end view-title py-1">In the past two years, were you diagnosed with tuberculosis or have you been in close contact with a person with tuberculosis?</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
                        <strong class="clipboard-style" v-clipboard="data.in_the_past_two_years_were_you_diagnosed_or_in_close_contact_with_tuberculosis">{{data.in_the_past_two_years_were_you_diagnosed_or_in_close_contact_with_tuberculosis}}</strong>
                    </v-col>
                </v-row>
                <v-row v-if="data.in_the_past_two_years_were_you_diagnosed_or_in_close_contact_with_tuberculosis == 'Yes'">
                    <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Is your contact with tuberculosis the result of being a health care worker?</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
                        <strong class="clipboard-style" v-clipboard="data.is_your_contact_with_tuberculosis_the_result_of_being_a_heath_care_worker">{{data.is_your_contact_with_tuberculosis_the_result_of_being_a_heath_care_worker}}</strong>
                    </v-col>
                    <v-col cols="12" v-if="data.is_your_contact_with_tuberculosis_the_result_of_being_a_heath_care_worker == 'Yes'">
                        <v-row>
                            <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Have you ever been diagnosed with tuberculosis?</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
                                <strong class="clipboard-style" v-clipboard="data.have_you_ever_been_diagnosed_with_tuberculosis">{{data.have_you_ever_been_diagnosed_with_tuberculosis}}</strong>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Do you have one of these conditions?</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
                        <strong class="clipboard-style" v-clipboard="data.do_you_have_one_of_these_conditions">{{data.do_you_have_one_of_these_conditions}}</strong>
                    </v-col>
                    <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Please briefly indicate if there are additional details pertinent to your application. For example, an urgent need to travel to Canada. Provide relevant details to avoid delays in the processing of your application.</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
                        <strong class="clipboard-style" v-clipboard="data.have_or_will_have_health_insurance_valid_in_canada_during_stay_details">{{data.have_or_will_have_health_insurance_valid_in_canada_during_stay_details}}</strong>
                    </v-col>
                </v-row>
          </v-col>
          
        </v-row>
      </v-form>
    </div>
</template>

<script>
  export default {
    props: {
        data: {}
    },
    components: {
    },
    data: () => ({
      edit_val: null,
      edit_prop: null,
      edit_day: null,
      edit_month: null,
      edit_year: null,
      edit_prop_date: null,
      days: ['01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31'],
      months: [
          {id: '01', desc: 'January'},
          {id: '02', desc: 'February'},
          {id: '03', desc: 'March'},
          {id: '04', desc: 'April'},
          {id: '05', desc: 'May'},
          {id: '06', desc: 'June'},
          {id: '07', desc: 'July'},
          {id: '08', desc: 'August'},
          {id: '09', desc: 'September'},
          {id: '10', desc: 'October'},
          {id: '11', desc: 'November'},
          {id: '12', desc: 'December'},
      ],
      years: [],
      years2: [],
      yearsAll: [],
      gender: [
        {id: 'Male', desc: 'Male'},
        {id: 'Female', desc: 'Female'},
      ],
      forbidden_countries: ["CU","IR","IQ","LY","KP","SO","SD","SY","YE"],
    }),
    created() {
        const currentYear = (new Date()).getFullYear();
        const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1}, (_, i) => start + (i * step));
        this.years = range(currentYear, currentYear - 100, -1); 
        this.years2 = range(currentYear, currentYear + 100, 1); 
        let years_all = range(currentYear - 100, currentYear + 100, 1);
        this.yearsAll = years_all.sort(function(a, b) {
        return b - a;
    });
    },
    methods: {
        setLanguage(lang){
          const languageNames = new Intl.DisplayNames(['en'], {
            type: 'language'
          });
          return languageNames.of(lang)
        },
        getCountry(code) {
          let res = 'None'
          if(code){
          this.$store.state.countries.forEach(item => {
            if(item.code.toLowerCase() == code.toLowerCase() || item.code_3.toLowerCase() == code.toLowerCase()){
              res = item.country
            }
          });
          }
          return res;
        },
        edit(prop, val){
          this.edit_val = val;
          this.edit_prop = prop
        },
        editDate(prop, val){
          this.edit_day = val[0];
          this.edit_month = val[1];
          this.edit_year = parseInt(val[2]);
          this.edit_prop_date = prop
        },
        updateData(){
          if(this.$refs.form.validate()){
          if(this.edit_prop_date){
              this.data[this.edit_prop_date[0]] = this.edit_day;
              this.data[this.edit_prop_date[1]] = this.edit_month;
              this.data[this.edit_prop_date[2]] = this.edit_year;
          }
          else if(this.edit_prop + '_text' in this.data){
            let country = this.getCountry(this.edit_val);
            if(country != 'None'){
              // console.log("updated the" + this.edit_prop + '_text')
              this.data[this.edit_prop] = this.edit_val
              this.data[this.edit_prop + '_text'] = country;
            }
          }
          else{
          this.data[this.edit_prop] = this.edit_val
          }
          let data = {
            token: this.data.token,
            data: JSON.stringify(this.data)
          };
          this.axiosCall("/applications/updateData", "POST", data).then((res) => {
            if (res.data.status == 200) {
              this.showToast("Application field [" + this.edit_prop + "] was successfully updated.", 'success')
              this.cancelEdit();
            }
            else{
              this.showToast(res.data.msg, 'error')
            }
          });
          }
        },
        cancelEdit(){
          this.edit_val = null;
          this.edit_prop = null;
          this.edit_day = null;
          this.edit_month = null;
          this.edit_year = null;
          this.edit_prop_date = null;
        }
    },
    mounted(){
      console.log(this.data)
    }
  }
</script>
<style scoped>
.view-main-title {
  color: #4d6585 !important;
  font-weight: 500;
  font-size: 16px;
}
.view-title {
  color: #4d6585 !important;
  font-weight: 500;
}
.chip-status-1{
  background-color: #ebeef1 !important;
  color: #616f7e !important;
}
.chip-status-2{
  background-color: #F0FFF8 !important;
  color: #18AB56 !important;
}
</style>