<template>
  <div class="pa-5">
    <v-card class="card-style rounded-1" >
    <v-row class="ma-2">
      <v-col cols="12" md="9" class="flex-items">
        <h2>Clients</h2>
      </v-col>
      <v-col cols="12" md="3">
      <v-text-field
          v-model="search"
          prepend-inner-icon="search"
          label="Search clients"
          single-line
          hide-details
          color="#38435a"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-card class="mb-1 dt-container" elevation="0" outlined>
    <v-data-table
      :loading="loading"
       :headers="headers"
        :items="data"
        item-key="id"
        :options.sync="options"
        hide-default-footer
    >
    <template v-slot:[`item.status`]="{ item }">
      <v-chip v-if="item.active == 1"
        class="chip-status-1 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap"
        small
      >
        <span>Active</span>
      </v-chip>
      <v-chip v-else
          class="chip-status-5 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap"
          small
        >
        <span>Inactive</span>
      </v-chip>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
          <span class="flex-items">
            <span class="mr-3">OFF</span>
          <v-switch
            v-model="item.switch_enabled"
            @change="setClient(item)"
            inset
          ></v-switch>
            <span>ON</span>
          </span>
    </template>
    </v-data-table>
    </v-card>
    <v-row class="ma-2" align="center" justify="center">
    <v-col cols="auto" class="flex-items">
        <span class="px-2">Row Per Page: </span>
        <span>
          <v-select
            dense
            outlined
            hide-details
            :value="options.itemsPerPage"
            style="max-width: 90px"
            class="rounded-lg"
            @change="options.itemsPerPage = parseInt($event, 10)"
            :items="perPageChoices"
          >
          </v-select>
        </span>
    </v-col>
    <v-col cols="auto" class="mx-auto text-truncate" no-gutters>
    Showing {{ data.length > 0 ? ((paginationData.currentPage - 1) * options.itemsPerPage) + 1 : 0 }} to {{ data.length > 0 ? ((paginationData.currentPage - 1) * options.itemsPerPage) + data.length : 0 }} of {{ paginationData.count }} entries
    </v-col>
    <v-col cols="auto">
        <v-pagination
            v-model="options.page"
            class="rounded-lg"
            :total-visible="7"
            color="#38435a"
            :length="paginationData.lastPage">
        </v-pagination>
    </v-col>
      </v-row>
    </v-card>
    <v-dialog
      v-model="dialogConfirmation"
      width="520"
      persistent
    >
      <v-card>
        <v-card-title class="text-h6">
          <strong>CONFIRMATION</strong>
        </v-card-title>

        <v-card-text>
            <div class="subtitle-1">
              Are you sure you want to {{edit_item.switch_enabled ? 'enable' : 'disable'}} <strong>{{edit_item.name}}</strong>?
            </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            outlined
            class="mr-2"
            depressed
            @click="cancelSwitch()"
          >
            Cancel
          </v-btn>
          <v-btn
            color="#166ecf"
            class="white--text"
            depressed
            @click="confirmSetClient()"
            v-if="edit_item.switch_enabled"
            :loading="loadingConfirm"
          >
            Yes, Enable
          </v-btn>
          <v-btn
            color="#d13c56"
            class="white--text"
            depressed
            @click="confirmSetClient()"
            v-if="!edit_item.switch_enabled"
            :loading="loadingConfirm"
          >
            Yes, Disable
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</div>
</template>
<script>
  export default {
    props: {
      tab: null,
    },
    components: {
    },
    data: () => ({
      search: '',
      headers: [
          { text: 'REF#', value: 'token' },
          { text: 'COMPANY', value: 'name'},
          { text: 'ALIAS', value: 'alias'},
          { text: 'STATUS', value: 'status'},
          { text: 'ACTIONS', value: 'actions', width: 170 },
        ],
      data: [],
      perPageChoices: [
          {text:'5' , value: 5},
          {text:'10' , value: 10},
          {text:'20' , value: 20},
          {text:'50' , value: 50},
          {text:'100' , value: 100},
          {text:'250' , value: 250},
          {text:'500' , value: 500},
      ],
      options: {},
      paginationData: {},
      loading: false,
      formdata: [],
      loadingConfirm: false,
      edit_item: {},
      dialogConfirmation: false,
    }),
    watch: {
      options: {
        handler () {
         this.initialize();
        },
        deep: true,
      },
      search: {
        handler () {
          setTimeout(() => {
            this.options.page = 1;
            this.initialize();
          }, 600);
        },
      },
    },
    mounted() {
    },
    methods: {
      initialize (){
        this.loading = true;
        let page = this.options.page;
        let itemPerPage = this.options.itemsPerPage;
        let sortBy = this.options.sortBy.length > 0 ? this.options.sortBy[0] : '';
        let order = this.options.sortBy.length > 0 ? this.options.sortDesc[0] ? 'desc' : 'asc' : '';
        let search = this.search;
        this.axiosCall('/clients/getClients?page=' + page + '&itemPerPage=' + itemPerPage + '&sortBy=' + sortBy + '&order=' + order + '&searchKey=' + search, 'GET').then((res) => {
              this.loading = false
              res.data.data.forEach(item => {
                item.switch_enabled = item.active
              });
              this.data = res.data.data
              // console.log(this.data)
              this.paginationData = res.data.pagination;
          })
      },
      cancelSwitch(){
        this.edit_item.switch_enabled = !this.edit_item.switch_enabled;
        this.dialogConfirmation = false;
      },
      setClient(item){
        this.edit_item = item;
        this.dialogConfirmation = true;
       },
       confirmSetClient(){
        this.loadingConfirm = true;
        let data = {
          token: this.edit_item.token,
          active: this.edit_item.switch_enabled ? 1 : 0,
        };
        this.axiosCall("/clients/updateStatus", "POST", data).then((res) => {
          if (res.data.status == 200) {
            this.edit_item.active = this.edit_item.switch_enabled ? 1 : 0;
            this.edit_item = {};
            this.showToast(res.data.msg, 'success')
          }
          else{
            this.edit_item.switch_enabled = !this.edit_item.switch_enabled
            this.showToast(res.data.msg, 'error')
          }
          this.loadingConfirm = false;
          this.dialogConfirmation = false;
        });
       },
    }
  }
</script>
<style scoped>
.chip-status-1{
  background-color: #d7f7c2 !important;
  color: #2f8547 !important;
}
.chip-status-2{
  background-color: #cff5f6 !important;
  color: #1066c5 !important;
}
.chip-status-3{
  background-color: #ebeef1 !important;
  color: #616f7e !important;
}
.chip-status-4{
  background-color: #fcedb9 !important;
  color: #b02c00 !important;
}
.chip-status-5{
  background-color: #ffe7f2 !important;
  color: #c4093c !important;
}
.chip-status-6{
  background-color: #F0FFF8 !important;
  color: #18AB56 !important;
}
</style>