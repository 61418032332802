<template>
  <div>
    <v-card class="card-style rounded-1" >
    <v-row class="ma-2">
      <v-col cols="12" md="9" class="flex-items">
        <h2>My Pending Verification Applications</h2>
      </v-col>
      <v-col cols="12" md="3">
      <v-text-field
          v-model="search"
          prepend-inner-icon="search"
          label="Search applications"
          single-line
          hide-details
          color="#38435a"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-card class="mb-1 dt-container" elevation="0" outlined>
    <v-data-table
      :loading="loading"
       :headers="headers"
        :items="data"
        item-key="id"
        :options.sync="options"
        hide-default-footer
    >
    <template v-slot:[`item.applicant`]="{ item }">
      <span v-if="item.data">{{(urlDecode(item.data.first_given_names || '') || '').toUpperCase()}} {{(urlDecode(item.data.family_names || '') || '').toUpperCase()}}</span>
    </template>
    <template v-slot:[`item.status`]="{ item }">
      <div v-if="$store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'">
      <v-chip
        class="chip-status-1 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap"
        v-if="item.submitted == 1"
        small
      >
       <span>Completed</span>
      </v-chip>
      <span v-else>
        <span v-if="item.assignedTo == null || item.assignedTo == ''">
          <v-chip
            class="chip-status-3 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap"
            small
          >
          <span>Available</span>
          </v-chip>
        </span>
        <span v-else>

            <v-chip
              class="chip-status-4 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap"
              small
            >
            <span>Processing</span>
            </v-chip>
      </span>
      </span>
      </div>
    </template>
    <template v-slot:[`item.submitted_at`]="{ item }">
        {{formatDate(item.submitted_at_timestamp)}}
    </template>
    <template v-slot:[`item.time`]="{ item }">
        {{formatDate(item.submitted_at_timestamp)}}
    </template>
    <template v-slot:[`item.commission_status`]="{ item }">
      <span v-if="item.cardStatus == 'CLOSED'">
        <v-chip
            v-if="item.cardStatus == 'CLOSED'"
            class="chip-status-1 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap"
            small
            >
            <span>Payable</span>
        </v-chip>
        <v-chip
            v-else
            class="chip-status-4 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap"
            small
            >
            <span>Validating Commission...</span>
        </v-chip>
      </span>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
            <v-btn
            outlined
            color="#166ecf"
            :to="'/' + $store.state.user.role + '/search/view/'+ item.token + '/'"
            v-if="item.cardStatus != 'CLOSED'"
            >
            <v-icon class="mr-1">mdi-eye</v-icon>
            View
            </v-btn>
    </template>
    </v-data-table>
    </v-card>
    <v-row class="ma-2" align="center" justify="center">
    <v-col cols="auto" class="flex-items">
        <span class="px-2">Row Per Page: </span>
        <span>
          <v-select
            dense
            outlined
            hide-details
            :value="options.itemsPerPage"
            style="max-width: 90px"
            class="rounded-lg"
            @change="options.itemsPerPage = parseInt($event, 10)"
            :items="perPageChoices"
          >
          </v-select>
        </span>
    </v-col>
    <v-col cols="auto" class="mx-auto text-truncate" no-gutters>
    Showing {{ data.length > 0 ? ((paginationData.currentPage - 1) * options.itemsPerPage) + 1 : 0 }} to {{ data.length > 0 ? ((paginationData.currentPage - 1) * options.itemsPerPage) + data.length : 0 }} of {{ paginationData.count }} entries
    </v-col>
    <v-col cols="auto">
        <v-pagination
            v-model="options.page"
            class="rounded-lg"
            :total-visible="7"
            color="#38435a"
            :length="paginationData.lastPage">
        </v-pagination>
    </v-col>
      </v-row>
    </v-card>
</div>
</template>
<script>
  export default {
    components: {
    },
    data: () => ({
      search: '',
      headers: [
          { text: 'REF#', value: 'token' },
          { text: 'STATUS', value: 'status', width: 250},
          { text: 'SUBMITTED AT', value: 'submitted_at'},
          { text: 'TIME', value: 'time'},
          { text: 'CUSTOMER', value: 'applicant'},
          { text: 'COMMISION RATE', value: 'commission_rate'},
          { text: 'COMMISION STATUS', value: 'commission_status', width: 200 },
          { text: 'ACTIONS', value: 'actions' },
        ],
      data: [],
      perPageChoices: [
          {text:'5' , value: 5},
          {text:'10' , value: 10},
          {text:'20' , value: 20},
          {text:'50' , value: 50},
          {text:'100' , value: 100},
          {text:'250' , value: 250},
          {text:'500' , value: 500},
      ],
      options: {},
      paginationData: {},
      loading: false,
      loadingRequest: false,
    available: 0,
    }),
    watch: {
      options: {
        handler () {
         this.initialize();
        },
        deep: true,
      },
      tab: {
        handler () {
        this.options.page = 1;
        this.initialize();
        },
        deep: true,
      },
      search: {
        handler () {
          setTimeout(() => {
            this.options.page = 1;
            this.initialize();
          }, 600);
        },
      },
    },
    mounted() {
    },
    beforeDestroy() {
    },
    methods: {
      initialize (){
        this.loading = true;
        let page = this.options.page;
        let itemPerPage = this.options.itemsPerPage;
        let sortBy = this.options.sortBy.length > 0 ? this.options.sortBy[0] : '';
        let order = this.options.sortBy.length > 0 ? this.options.sortDesc[0] ? 'desc' : 'asc' : '';
        let search = this.search;
        this.axiosCall('/applications/getMyPendingApplications?page=' + page + '&itemPerPage=' + itemPerPage + '&sortBy=' + sortBy + '&order=' + order + '&searchKey=' + search, 'GET').then((res) => {
              this.loading = false
              res.data.data.forEach(item => {
                item.data = JSON.parse(this.sanitizeJsonString(item.data))
              });
              this.data = res.data.data
              // console.log(this.data)
              this.paginationData = res.data.pagination;
          })
      },
    }
  }
</script>
<style scoped>
.chip-status-1{
  background-color: #d7f7c2 !important;
  color: #2f8547 !important;
}
.chip-status-2{
  background-color: #cff5f6 !important;
  color: #1066c5 !important;
}
.chip-status-3{
  background-color: #ebeef1 !important;
  color: #616f7e !important;
}
.chip-status-4{
  background-color: #fcedb9 !important;
  color: #b02c00 !important;
}
.chip-status-5{
  background-color: #ffe7f2 !important;
  color: #c4093c !important;
}
.chip-status-6{
  background-color: #F0FFF8 !important;
  color: #18AB56 !important;
}
</style>