<template>
    <div class="py-8">
      <v-form @submit.prevent ref="form">
        <v-row class="flex-items">
          <v-col><v-divider color="#909fa9"></v-divider></v-col>
          <v-col cols="auto" class="mx-auto">
            <span class="text-subtitle1 view-main-title">Applicant Information</span>
          </v-col>
          <v-col><v-divider color="#909fa9"></v-divider></v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Browser country (if available):</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <span class="flex-items clipboard-style" v-clipboard="(getCountry(data.completing_country) || '').toUpperCase()"><img v-if="data.completing_country" class="rounded-circle mr-2" width="18" :src="require('@/assets/img/flags/1x1/' + (data.completing_country.toLowerCase()).substring(0, 2) + '.svg')" /><strong>{{(getCountry(data.completing_country) || '').toUpperCase()}}</strong></span>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">What is your nationality?</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <span class="flex-items clipboard-style" v-clipboard="(getCountry(data.nationality) || '').toUpperCase()"><img v-if="data.nationality" class="rounded-circle mr-2" width="18" :src="require('@/assets/img/flags/1x1/' + (data.nationality.toLowerCase()).substring(0, 2) + '.svg')" /><strong>{{(getCountry(data.nationality) || '').toUpperCase()}}</strong></span>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">What is your passport number?</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="edit_prop != 'passport_number'"><strong class="clipboard-style" v-clipboard="(sanitizeString(data.passport_number) || '').toUpperCase()">{{(sanitizeString(data.passport_number) || '').toUpperCase()}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor'" small @click="edit('passport_number', data.passport_number)">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-text-field
                    v-model="edit_val"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">When does your passport expire?</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="!edit_prop_date || edit_prop_date.join(',') != 'passport_expiration_day,passport_expiration_month,passport_expiration_year'"><strong class="clipboard-style" v-clipboard="data.passport_expiration_day + ' / ' + (monthNumToName(data.passport_expiration_month) || '').toUpperCase() + ' / ' + data.passport_expiration_year">{{data.passport_expiration_day + ' / ' + (monthNumToName(data.passport_expiration_month) || '').toUpperCase() + ' / ' + data.passport_expiration_year}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor'" small @click="editDate(['passport_expiration_day','passport_expiration_month','passport_expiration_year'], [data.passport_expiration_day, data.passport_expiration_month, data.passport_expiration_year])">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-autocomplete
                    v-model="edit_day"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    placeholder="Day"
                    :items="days"
                    hide-details="auto"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" class="pb-1">
                  <v-autocomplete
                    v-model="edit_month"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    placeholder="Month"
                    item-text="desc"
                    item-value="id"
                    :items="months"
                    hide-details="auto"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" class="pb-1">
                  <v-autocomplete
                    v-model="edit_year"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    placeholder="Year"
                    :items="years2"
                    hide-details="auto"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <v-col cols="12" v-if="!docupass">
            <v-row>
              <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Upload a picture of your passport
                <br />
                <v-chip
                  class="chip-status-2 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap"
                  small
                  v-if="data.photo_details_optimized"
                >
                <span>Compressed</span>
                </v-chip>
                <v-chip
                  class="chip-status-1 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap"
                  small
                  v-else
                >
                <span>Non-Compressed</span>
                </v-chip>
                </v-col> <v-col cols="12" sm="6" class="py-1">
                <div v-if="data.photo_details_optimized">
                <div><img class="image-fluid rounded-lg" height="300" :src="data.photo_details_optimized"/></div>
                <v-btn color="#f5ca99" class="px-15 py-4" depressed @click="downloadPhoto(data.photo_details_optimized, data.first_given_names, data.family_names, 'PHOTO')"><v-icon>mdi-download</v-icon> Download Photo</v-btn>
                </div>
                <div v-else>
                <div><img class="image-fluid rounded-lg" height="300" :src="'https://esta-apply.s3.amazonaws.com/' + data.photo_details"/></div>
                <v-btn color="#f5ca99" class="px-15 py-4" depressed @click="downloadPhoto('https://esta-apply.s3.amazonaws.com/' + data.photo_details, data.first_given_names, data.family_names, 'PHOTO')"><v-icon>mdi-download</v-icon> Download Photo</v-btn>
                </div>
              </v-col>
            </v-row>
          </v-col>
          
            <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Applicant Photo</v-col> <v-col cols="12" sm="6" class="py-1">
            <!-- <div v-if="data.photo_uploaded_bool"> -->
            <div><img class="image-fluid rounded-lg" height="300" :src="data.photo_details_link"/></div>
            <v-btn color="#f5ca99" class="px-15 py-4" depressed @click="downloadPhoto(data.photo_details_link, data.first_given_names, data.family_names, 'PHOTO')"><v-icon>mdi-download</v-icon> Download Photo</v-btn>
            <!-- </div> -->
          </v-col>
          <v-col cols="12"><v-divider color="#909fa9"></v-divider></v-col>
          <v-col cols="12">
            <v-row v-if="data.has_full_name_bool">
              <v-col cols="12" sm="6" class="text-sm-end view-title py-1">What is your full name in English as written in your passport?</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
                <div v-if="edit_prop != 'full_name'"><strong class="clipboard-style" v-clipboard="(urlDecode(data.full_name) || '').toUpperCase()">{{(urlDecode(data.full_name) || '').toUpperCase()}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="edit('full_name', data.full_name)">Edit</v-btn></div>
                <v-row v-else no-gutters>
                    <v-col cols="12" class="pb-1">
                      <v-text-field
                        v-model="edit_val"
                        color="#677788"
                        :rules="[formRules.required]"
                        height="45"
                        width="100%"
                        outlined
                        hide-details="auto"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                    <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
                  </v-row>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Surname</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
                <div v-if="edit_prop != 'family_names'"><strong class="clipboard-style" v-clipboard="(urlDecode(data.family_names) || '').toUpperCase()">{{(urlDecode(data.family_names) || '').toUpperCase()}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="edit('family_names', data.family_names)">Edit</v-btn></div>
                <v-row v-else no-gutters>
                    <v-col cols="12" class="pb-1">
                      <v-text-field
                        v-model="edit_val"
                        color="#677788"
                        :rules="[formRules.required]"
                        height="45"
                        width="100%"
                        outlined
                        hide-details="auto"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                    <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
                  </v-row>
              </v-col>
              <v-col cols="12" sm="6" class="text-sm-end view-title py-1">First (Given) Name</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
                <div v-if="edit_prop != 'first_given_names'"><strong class="clipboard-style" v-clipboard="(urlDecode(data.first_given_names) || '').toUpperCase()">{{(urlDecode(data.first_given_names) || '').toUpperCase()}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="edit('first_given_names', data.first_given_names)">Edit</v-btn></div>
                <v-row v-else no-gutters>
                    <v-col cols="12" class="pb-1">
                      <v-text-field
                        v-model="edit_val"
                        color="#677788"
                        :rules="[formRules.required]"
                        height="45"
                        width="100%"
                        outlined
                        hide-details="auto"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                    <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
                  </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">System Email</v-col> <v-col cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="systemEmail">
            <strong>{{systemEmail}}</strong>
          </v-col>
          <!-- <v-col cols="12" sm="6" class="text-sm-end view-title py-1">What is your gender?</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="edit_prop != 'gender'"><strong class="clipboard-style" v-clipboard="data.gender ? gender.find(item => item.id == data.gender).desc : ''">{{data.gender ? gender.find(item => item.id == data.gender).desc : ''}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="edit('gender', data.gender)">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-autocomplete
                    v-model="edit_val"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    item-text="desc"
                    item-value="id"
                    :items="sex"
                    hide-details="auto"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col> -->
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">What is your date of birth? (Day/Month/Year)</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="!edit_prop_date || edit_prop_date.join(',') != 'dob_day,dob_month,dob_year'"><strong class="clipboard-style" v-clipboard="data.dob_day + ' / ' + (monthNumToName(data.dob_month) || '').toUpperCase() + ' / ' + data.dob_year">{{data.dob_day + ' / ' + (monthNumToName(data.dob_month) || '').toUpperCase() + ' / ' + data.dob_year}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="editDate(['dob_day','dob_month','dob_year'], [data.dob_day, data.dob_month, data.dob_year])">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-autocomplete
                    v-model="edit_day"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    placeholder="Day"
                    :items="days"
                    hide-details="auto"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" class="pb-1">
                  <v-autocomplete
                    v-model="edit_month"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    placeholder="Month"
                    item-text="desc"
                    item-value="id"
                    :items="months"
                    hide-details="auto"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" class="pb-1">
                  <v-autocomplete
                    v-model="edit_year"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    placeholder="Year"
                    :items="years"
                    hide-details="auto"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">What is your home address?</v-col> <v-col cols="12" sm="6" class="py-1">
            <div><strong style="color:#677788;">Line 1:&nbsp;</strong><strong v-if="data.contact_details_adress_line_1" class="clipboard-style" v-clipboard="(urlDecode(data.contact_details_adress_line_1) || '').toUpperCase()">{{(urlDecode(data.contact_details_adress_line_1) || '').toUpperCase()}}</strong></div>
            <div><strong style="color:#677788;">Line 2:&nbsp;</strong><strong v-if="data.contact_details_adress_line_2" class="clipboard-style" v-clipboard="(urlDecode(data.contact_details_adress_line_2) || '').toUpperCase()">{{(urlDecode(data.contact_details_adress_line_2) || '').toUpperCase()}}</strong></div>
            <div><strong style="color:#677788;">Line 3 (optional):&nbsp;</strong><strong v-if="data.contact_details_adress_line_3" class="clipboard-style" v-clipboard="(urlDecode(data.contact_details_adress_line_3) || '').toUpperCase()">{{(urlDecode(data.contact_details_adress_line_3) || '').toUpperCase()}}</strong></div>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Town or city</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="edit_prop != 'contact_details_adress_line_4'"><strong class="clipboard-style" v-clipboard="(sanitizeString(urlDecode(data.contact_details_adress_line_4)) || '').toUpperCase()">{{(urlDecode(data.contact_details_adress_line_4) || '').toUpperCase()}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="edit('contact_details_adress_line_4', data.contact_details_adress_line_4)">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-text-field
                    v-model="edit_val"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Country</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="edit_prop != 'contact_details_country'"><strong class="clipboard-style" v-clipboard="(sanitizeString(urlDecode(data.contact_details_country)) || '').toUpperCase()">{{(urlDecode(data.contact_details_country) || '').toUpperCase()}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="edit('contact_details_country', data.contact_details_country)">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-text-field
                    v-model="edit_val"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Postal / Zipcode</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="edit_prop != 'contact_details_postcode'"><strong class="clipboard-style" v-clipboard="(urlDecode(data.contact_details_postcode) || '').toUpperCase()">{{(urlDecode(data.contact_details_postcode) || '').toUpperCase()}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="edit('contact_details_postcode', data.contact_details_postcode)">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-text-field
                    v-model="edit_val"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">What is your main home phone number?</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="edit_prop != 'contact_details_phone_number'"><strong class="clipboard-style" v-clipboard="data.contact_details_phone_number">{{data.contact_details_phone_number}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="edit('contact_details_phone_number', data.contact_details_phone_number)">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-text-field
                    v-model="edit_val"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">What is your mobile phone number?</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="edit_prop != 'contact_details_mobile_phone_number'"><strong class="clipboard-style" v-clipboard="data.contact_details_mobile_phone_number">{{data.contact_details_mobile_phone_number}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="edit('contact_details_mobile_phone_number', data.contact_details_mobile_phone_number)">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-text-field
                    v-model="edit_val"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12"><v-divider color="#909fa9"></v-divider></v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Are you employed?</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="edit_prop != 'is_employed_bool'"><strong class="clipboard-style" v-clipboard="data.is_employed_bool ? 'YES' : 'NO'">{{data.is_employed_bool ? 'YES' : 'NO'}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor'" small @click="edit('is_employed_bool', data.is_employed_bool)">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-radio-group
                    v-model="edit_val"
                    :rules="[formRules.required3]"
                    row
                    class="mt-0"
                    >
                    <v-radio
                        label="No"
                        :value="false"
                        color="#00a34e"
                    ></v-radio>
                    <v-radio
                        label="Yes"
                        :value="true"
                        color="#00a34e"
                    ></v-radio>
                </v-radio-group>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" v-if="data.is_employed_bool">
              <v-row>
                  <v-col cols="12" sm="6" class="text-sm-end view-title py-1">What is the name of the company or organisation you work for?</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
                      <strong class="clipboard-style" v-clipboard="(data.is_employed_company_name || '').toUpperCase()">{{(data.is_employed_company_name || '').toUpperCase()}}</strong>
                  </v-col>
                  <v-col cols="12" sm="6" class="text-sm-end view-title py-1">What is your job title?</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
                      <strong class="clipboard-style" v-clipboard="(data.is_employed_job_title || '').toUpperCase()">{{(data.is_employed_job_title || '').toUpperCase()}}</strong>
                  </v-col>
              </v-row>
          </v-col>
          <v-col cols="12"><v-divider color="#909fa9"></v-divider></v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Do you currently have any other nationalities?</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="edit_prop != 'other_nationalities_bool'"><strong class="clipboard-style" v-clipboard="data.other_nationalities_bool ? 'YES' : 'NO'">{{data.other_nationalities_bool ? 'YES' : 'NO'}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor'" small @click="edit('other_nationalities_bool', data.other_nationalities_bool)">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-radio-group
                    v-model="edit_val"
                    :rules="[formRules.required3]"
                    row
                    class="mt-0"
                    >
                    <v-radio
                        label="No"
                        :value="false"
                        color="#00a34e"
                    ></v-radio>
                    <v-radio
                        label="Yes"
                        :value="true"
                        color="#00a34e"
                    ></v-radio>
                </v-radio-group>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" v-if="data.other_nationalities_bool">
              <v-row v-for="(item, index) in data.other_nationalities" :key="index">
                  <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Nationality #{{index + 1}}</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
                      <strong class="clipboard-style" v-clipboard="item.name">{{item.name}}</strong>
                  </v-col>
              </v-row>
          </v-col>
          <v-col cols="12"><v-divider color="#909fa9"></v-divider></v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Have you ever convicted to a crime in the last 12 months?</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="edit_prop != 'convicted_to_crime_12_months_bool'"><strong class="clipboard-style" v-clipboard="data.convicted_to_crime_12_months_bool ? 'YES' : 'NO'">{{data.convicted_to_crime_12_months_bool ? 'YES' : 'NO'}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor'" small @click="edit('convicted_to_crime_12_months_bool', data.convicted_to_crime_12_months_bool)">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-radio-group
                    v-model="edit_val"
                    :rules="[formRules.required3]"
                    row
                    class="mt-0"
                    >
                    <v-radio
                        label="No"
                        :value="false"
                        color="#00a34e"
                    ></v-radio>
                    <v-radio
                        label="Yes"
                        :value="true"
                        color="#00a34e"
                    ></v-radio>
                </v-radio-group>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" v-if="data.convicted_to_crime_12_months_bool">
              <v-row>
                  <v-col cols="12" sm="6" class="text-sm-end view-title py-1">What crime were you convicted of?</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
                      <strong class="clipboard-style" v-clipboard="(data.convicted_to_crime_12_months_details || '').toUpperCase()">{{(data.convicted_to_crime_12_months_details || '').toUpperCase()}}</strong>
                  </v-col>
                  <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Which country were you convicted in?</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
                      <strong class="clipboard-style" v-clipboard="(data.convicted_to_crime_12_months_country || '').toUpperCase()">{{(data.convicted_to_crime_12_months_country || '').toUpperCase()}}</strong>
                  </v-col>
                  <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Were you sentenced to more than 12 months in prison?</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
                      <strong class="clipboard-style" v-clipboard="data.convicted_to_crime_12_months_more_bool ? 'YES' : 'NO'">{{data.convicted_to_crime_12_months_more_bool ? 'YES' : 'NO'}}</strong>
                  </v-col>
              </v-row>
          </v-col>
          <v-col cols="12"><v-divider color="#909fa9"></v-divider></v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Have you ever had a prison sentence of more than 12 months?</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="edit_prop != 'prisonment_12_months_bool'"><strong class="clipboard-style" v-clipboard="data.prisonment_12_months_bool ? 'YES' : 'NO'">{{data.prisonment_12_months_bool ? 'YES' : 'NO'}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor'" small @click="edit('prisonment_12_months_bool', data.prisonment_12_months_bool)">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-radio-group
                    v-model="edit_val"
                    :rules="[formRules.required3]"
                    row
                    class="mt-0"
                    >
                    <v-radio
                        label="No"
                        :value="false"
                        color="#00a34e"
                    ></v-radio>
                    <v-radio
                        label="Yes"
                        :value="true"
                        color="#00a34e"
                    ></v-radio>
                </v-radio-group>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" v-if="data.prisonment_12_months_bool">
              <v-row>
                  <v-col cols="12" sm="6" class="text-sm-end view-title py-1">What crime were you convicted of?</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
                      <strong class="clipboard-style" v-clipboard="(data.prisonment_12_months_details || '').toUpperCase()">{{(data.prisonment_12_months_details || '').toUpperCase()}}</strong>
                  </v-col>
                  <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Convicted Date</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
                      <strong class="clipboard-style" v-clipboard="(formatDate(data.prisonment_12_months_date) || '').toUpperCase()">{{(formatDate(data.prisonment_12_months_date) || '').toUpperCase()}}</strong>
                  </v-col>
                  <v-col cols="12" sm="6" class="text-sm-end view-title py-1">hich country were you convicted in?</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
                      <strong class="clipboard-style" v-clipboard="(data.convicted_to_crime_12_months_country || '').toUpperCase()">{{(data.convicted_to_crime_12_months_country || '').toUpperCase()}}</strong>
                  </v-col>
              </v-row>
          </v-col>
          <v-col cols="12"><v-divider color="#909fa9"></v-divider></v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Have you ever been involved in; or suspected of, any of the following?</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="edit_prop != 'involved_or_suspected'"><strong class="clipboard-style" v-clipboard="data.involved_or_suspected ? 'YES' : 'NO'">{{data.involved_or_suspected ? 'YES' : 'NO'}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor'" small @click="edit('involved_or_suspected', data.involved_or_suspected)">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-radio-group
                    v-model="edit_val"
                    :rules="[formRules.required3]"
                    row
                    class="mt-0"
                    >
                    <v-radio
                        label="No"
                        :value="false"
                        color="#00a34e"
                    ></v-radio>
                    <v-radio
                        label="Yes"
                        :value="true"
                        color="#00a34e"
                    ></v-radio>
                </v-radio-group>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" v-if="data.involved_or_suspected">
              <v-row>
                  <v-col cols="12" sm="6" class="text-sm-end view-title py-1">War crimes</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
                      <strong class="clipboard-style" v-clipboard="data.involved_or_suspected_war_crimes_bool ? 'YES' : 'NO'">{{data.involved_or_suspected_war_crimes_bool ? 'YES' : 'NO'}}</strong>
                  </v-col>
                  <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Terrorism</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
                      <strong class="clipboard-style" v-clipboard="data.involved_or_suspected_terrorism_bool ? 'YES' : 'NO'">{{data.involved_or_suspected_terrorism_bool ? 'YES' : 'NO'}}</strong>
                  </v-col>
                  <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Extremism</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
                      <strong class="clipboard-style" v-clipboard="data.involved_or_suspected_extermist_bool ? 'YES' : 'NO'">{{data.involved_or_suspected_extermist_bool ? 'YES' : 'NO'}}</strong>
                  </v-col>
              </v-row>
          </v-col>
        </v-row>
      </v-form>
    </div>
</template>

<script>
  export default {
    props: {
        data: {},
        docupass: {},
        systemEmail: null
    },
    components: {
    },
    data: () => ({
      edit_val: null,
      edit_prop: null,
      edit_day: null,
      edit_month: null,
      edit_year: null,
      edit_prop_date: null,
      days: ['01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31'],
      months: [
          {id: '01', desc: 'January'},
          {id: '02', desc: 'February'},
          {id: '03', desc: 'March'},
          {id: '04', desc: 'April'},
          {id: '05', desc: 'May'},
          {id: '06', desc: 'June'},
          {id: '07', desc: 'July'},
          {id: '08', desc: 'August'},
          {id: '09', desc: 'September'},
          {id: '10', desc: 'October'},
          {id: '11', desc: 'November'},
          {id: '12', desc: 'December'},
      ],
      years: [],
      years2: [],
      yearsAll: [],
      gender: [
        {id: 'M', desc: 'Male'},
        {id: 'F', desc: 'Female'},
      ],
      forbidden_countries: ["CU","IR","IQ","LY","KP","SO","SD","SY","YE"],
    }),
    created() {
        const currentYear = (new Date()).getFullYear();
        const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1}, (_, i) => start + (i * step));
        this.years = range(currentYear, currentYear - 100, -1); 
        this.years2 = range(currentYear, currentYear + 100, 1); 
        let years_all = range(currentYear - 100, currentYear + 100, 1);
        this.yearsAll = years_all.sort(function(a, b) {
        return b - a;
    });
    },
    methods: {
        setLanguage(lang){
          const languageNames = new Intl.DisplayNames(['en'], {
            type: 'language'
          });
          return languageNames.of(lang)
        },
        getCountry(code) {
          let res = 'None'
          if(code){
          this.$store.state.countries.forEach(item => {
            if(item.code.toLowerCase() == code.toLowerCase() || item.code_3.toLowerCase() == code.toLowerCase()){
              res = item.country
            }
          });
          }
          return res;
        },
        edit(prop, val){
          this.edit_val = val;
          this.edit_prop = prop
        },
        editDate(prop, val){
          this.edit_day = val[0];
          this.edit_month = val[1];
          this.edit_year = parseInt(val[2]);
          this.edit_prop_date = prop
        },
        updateData(){
          if(this.$refs.form.validate()){
          if(this.edit_prop_date){
              this.data[this.edit_prop_date[0]] = this.edit_day;
              this.data[this.edit_prop_date[1]] = this.edit_month;
              this.data[this.edit_prop_date[2]] = this.edit_year;
          }
          else if(this.edit_prop + '_text' in this.data){
            let country = this.getCountry(this.edit_val);
            if(country != 'None'){
              // console.log("updated the" + this.edit_prop + '_text')
              this.data[this.edit_prop] = this.edit_val
              this.data[this.edit_prop + '_text'] = country;
            }
          }
          else{
          this.data[this.edit_prop] = this.edit_val
            if(this.edit_prop == 'full_name'){
              this.data['first_given_names'] = this.edit_val;
            }
          }
          let data = {
            token: this.data.token,
            data: JSON.stringify(this.data)
          };
          this.axiosCall("/applications/updateData", "POST", data).then((res) => {
            if (res.data.status == 200) {
              this.showToast("Application field [" + this.edit_prop + "] was successfully updated.", 'success')
              this.cancelEdit();
            }
            else{
              this.showToast(res.data.msg, 'error')
            }
          });
          }
        },
        cancelEdit(){
          this.edit_val = null;
          this.edit_prop = null;
          this.edit_day = null;
          this.edit_month = null;
          this.edit_year = null;
          this.edit_prop_date = null;
        }
    },
    mounted(){
      // console.log(this.docupass)
    }
  }
</script>
<style scoped>
.view-main-title {
  color: #4d6585 !important;
  font-weight: 500;
  font-size: 16px;
}
.view-title {
  color: #4d6585 !important;
  font-weight: 500;
}
</style>