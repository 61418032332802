<template>
    <div class="py-8">
      <v-form @submit.prevent ref="form">
        <v-row class="flex-items">
          <v-col><v-divider color="#909fa9"></v-divider></v-col>
          <v-col cols="auto" class="mx-auto">
            <span class="text-subtitle1 view-main-title">Applicant Information</span>
          </v-col>
          <v-col><v-divider color="#909fa9"></v-divider></v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Family Name</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="edit_prop != 'family_names'">
              <strong class="clipboard-style" v-clipboard="(data.family_names || '').toUpperCase()">{{(data.family_names || '').toUpperCase()}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="edit('family_names', data.family_names)">Edit</v-btn>
              <div v-if="passportDataMatch == 0 && passportDataComparison.family_names == false && passportValidationData">
                <v-chip outlined color="#ff9514" class="d-flex align-center rounded">
                <v-btn x-small outlined depressed color="#1d7327" class="mr-4" @click="recognize('family_names', passportValidationData.data.lastName, 'lastName')">
                  <v-icon small>mdi-check</v-icon>
                </v-btn>
                
                <b>{{passportValidationData.data.lastName}}</b>
                
                <v-btn x-small outlined depressed color="#c4093c" class="ml-4" @click="unrecognize('family_names', data.family_names)">
                  <v-icon small class="pa-0">mdi-close</v-icon>
                </v-btn>
              </v-chip>
              </div>
            </div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-text-field
                    v-model="edit_val"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">First (Given) Name</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="edit_prop != 'first_given_names'">
              <strong class="clipboard-style" v-clipboard="(data.first_given_names || '').toUpperCase()">{{(data.first_given_names || '').toUpperCase()}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="edit('first_given_names', data.first_given_names)">Edit</v-btn>
              <div v-if="passportDataMatch == 0 && passportDataComparison.first_given_names == false && passportValidationData">
                <v-chip outlined color="#ff9514" class="d-flex align-center rounded">
                <v-btn x-small outlined depressed color="#1d7327" class="mr-4" @click="recognize('first_given_names', passportValidationData.data.firstName, 'firstName')">
                  <v-icon small>mdi-check</v-icon>
                </v-btn>
                
                <b>{{passportValidationData.data.firstName}}</b>
                
                <v-btn x-small outlined depressed color="#c4093c" class="ml-4" @click="unrecognize('first_given_names', data.first_given_names)">
                  <v-icon small class="pa-0">mdi-close</v-icon>
                </v-btn>
              </v-chip>
              </div>
            </div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-text-field
                    v-model="edit_val"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Email Address</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="edit_prop != 'email'"><strong class="clipboard-style" v-clipboard="(data.email || '').toUpperCase()">{{(data.email || '').toUpperCase()}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="edit('email', data.email)">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-text-field
                    v-model="edit_val"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Passport Number</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="edit_prop != 'passport_number'">
              <strong class="clipboard-style" v-clipboard="(data.passport_number || '').toUpperCase()">{{(data.passport_number || '').toUpperCase()}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="edit('passport_number', data.passport_number)">Edit</v-btn>
              <div v-if="passportDataMatch == 0 && passportDataComparison.passport_number == false && passportValidationData">
                <v-chip outlined color="#ff9514" class="d-flex align-center rounded">
                <v-btn x-small outlined depressed color="#1d7327" class="mr-4" @click="recognize('passport_number', passportValidationData.data.documentNumber, 'documentNumber')">
                  <v-icon small>mdi-check</v-icon>
                </v-btn>
                
                <b>{{passportValidationData.data.documentNumber}}</b>
                
                <v-btn x-small outlined depressed color="#c4093c" class="ml-4" @click="unrecognize('passport_number', data.passport_number)">
                  <v-icon small class="pa-0">mdi-close</v-icon>
                </v-btn>
              </v-chip>
            </div>
            </div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-text-field
                    v-model="edit_val"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Passport Issuing Country</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="edit_prop != 'issuing_country'">
              <span class="flex-items"><span class="flex-items clipboard-style" v-clipboard="(getCountry(data.issuing_country) || '').toUpperCase()"><img v-if="data.issuing_country" class="rounded-circle mr-2" width="18" :src="require('@/assets/img/flags/1x1/' + (data.issuing_country.toLowerCase()).substring(0, 2) + '.svg')" /><strong>{{(getCountry(data.issuing_country) || '').toUpperCase()}}</strong></span> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="edit('issuing_country', data.issuing_country)">Edit</v-btn></span>
              <div v-if="passportDataMatch == 0 && passportDataComparison.issuing_country == false && passportValidationData">
                  <v-chip outlined color="#ff9514" class="d-flex align-center rounded">
                  <v-btn x-small outlined depressed color="#1d7327" class="mr-4" @click="recognize('issuing_country', passportValidationData.data.issuerOrg_iso2, 'issuerOrg_iso2')">
                    <v-icon small>mdi-check</v-icon>
                  </v-btn>
                  
                  <b>{{(getCountry(passportValidationData.data.issuerOrg_iso2) || '').toUpperCase()}}</b>
                  
                  <v-btn x-small outlined depressed color="#c4093c" class="ml-4" @click="unrecognize('issuing_country', data.issuing_country)">
                    <v-icon small class="pa-0">mdi-close</v-icon>
                  </v-btn>
                </v-chip>
              </div>
            </div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-autocomplete
                    v-model="edit_val"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    item-text="country"
                    item-value="code"
                    :items="$store.state.countries"
                    hide-details="auto"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Passport Issuance Date (Day/Month/Year)</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="!edit_prop_date || edit_prop_date.join(',') != 'passport_issuance_day,passport_issuance_month,passport_issuance_year'">
              <strong class="clipboard-style" v-clipboard="data.passport_issuance_day + ' / ' + (monthNumToName(data.passport_issuance_month) || '').toUpperCase() + ' / ' + data.passport_issuance_year">{{data.passport_issuance_day + ' / ' + (monthNumToName(data.passport_issuance_month) || '').toUpperCase() + ' / ' + data.passport_issuance_year}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="editDate(['passport_issuance_day','passport_issuance_month','passport_issuance_year'], [data.passport_issuance_day, data.passport_issuance_month, data.passport_issuance_year])">Edit</v-btn>
              <div v-if="passportDataMatch == 0 && passportDataComparison.passport_issuance_day == false && passportDataComparison.passport_issuance_month == false && passportDataComparison.passport_issuance_year == false && passportValidationData">
                  <v-chip outlined color="#ff9514" class="d-flex align-center rounded">
                  <v-btn x-small outlined depressed color="#1d7327" class="mr-4" @click="recognizeDate(['passport_issuance_day','passport_issuance_month','passport_issuance_year'], [passportValidationData.data.issued_day, passportValidationData.data.issued_month, passportValidationData.data.issued_year])">
                    <v-icon small>mdi-check</v-icon>
                  </v-btn>
                  
                  <b>{{passportValidationData.data.issued_day + ' / ' + (monthNumToName(passportValidationData.data.issued_month) || '').toUpperCase() + ' / ' + passportValidationData.data.issued_year}}</b>
                  
                  <v-btn x-small outlined depressed color="#c4093c" class="ml-4" @click="unrecognizeDate(['passport_issuance_day','passport_issuance_month','passport_issuance_year'], [data.passport_issuance_day, data.passport_issuance_month, data.passport_issuance_year])">
                    <v-icon small class="pa-0">mdi-close</v-icon>
                  </v-btn>
                </v-chip>
              </div>
            </div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-autocomplete
                    v-model="edit_day"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    placeholder="Day"
                    :items="days"
                    hide-details="auto"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" class="pb-1">
                  <v-autocomplete
                    v-model="edit_month"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    placeholder="Month"
                    item-text="desc"
                    item-value="id"
                    :items="months"
                    hide-details="auto"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" class="pb-1">
                  <v-autocomplete
                    v-model="edit_year"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    placeholder="Year"
                    :items="years"
                    hide-details="auto"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Passport Expiration Date (Day/Month/Year)</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="!edit_prop_date || edit_prop_date.join(',') != 'passport_expiration_day,passport_expiration_month,passport_expiration_year'">
              <strong class="clipboard-style" v-clipboard="data.passport_expiration_day + ' / ' + (monthNumToName(data.passport_expiration_month) || '').toUpperCase() + ' / ' + data.passport_expiration_year">{{data.passport_expiration_day + ' / ' + (monthNumToName(data.passport_expiration_month) || '').toUpperCase() + ' / ' + data.passport_expiration_year}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="editDate(['passport_expiration_day','passport_expiration_month','passport_expiration_year'], [data.passport_expiration_day, data.passport_expiration_month, data.passport_expiration_year])">Edit</v-btn>
              <div v-if="passportDataMatch == 0 && passportDataComparison.passport_expiration_day == false && passportDataComparison.passport_expiration_month == false && passportDataComparison.passport_expiration_year == false && passportValidationData">
                  <v-chip outlined color="#ff9514" class="d-flex align-center rounded">
                  <v-btn x-small outlined depressed color="#1d7327" class="mr-4" @click="recognizeDate(['passport_expiration_day','passport_expiration_month','passport_expiration_year'], [passportValidationData.data.expiry_day, passportValidationData.data.expiry_month, passportValidationData.data.expiry_year], ['passport_expiration_day','passport_expiration_month','passport_expiration_year'])">
                    <v-icon small>mdi-check</v-icon>
                  </v-btn>
                  
                  <b>{{passportValidationData.data.expiry_day + ' / ' + (monthNumToName(passportValidationData.data.expiry_month) || '').toUpperCase() + ' / ' + passportValidationData.data.expiry_year}}</b>
                  
                  <v-btn x-small outlined depressed color="#c4093c" class="ml-4" @click="unrecognizeDate(['passport_expiration_day','passport_expiration_month','passport_expiration_year'], [data.passport_expiration_day, data.passport_expiration_month, data.passport_expiration_year])">
                    <v-icon small class="pa-0">mdi-close</v-icon>
                  </v-btn>
                </v-chip>
              </div>
            </div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-autocomplete
                    v-model="edit_day"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    placeholder="Day"
                    :items="days"
                    hide-details="auto"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" class="pb-1">
                  <v-autocomplete
                    v-model="edit_month"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    placeholder="Month"
                    item-text="desc"
                    item-value="id"
                    :items="months"
                    hide-details="auto"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" class="pb-1">
                  <v-autocomplete
                    v-model="edit_year"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    placeholder="Year"
                    :items="years2"
                    hide-details="auto"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Country of Citizenship</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="edit_prop != 'country_of_citizenship'">
              <span class="flex-items"><span class="flex-items clipboard-style" v-clipboard="(getCountry(data.country_of_citizenship) || '').toUpperCase()"><img v-if="data.country_of_citizenship" class="rounded-circle mr-2" width="18" :src="require('@/assets/img/flags/1x1/' + (data.country_of_citizenship.toLowerCase()).substring(0, 2) + '.svg')" /><strong>{{(getCountry(data.country_of_citizenship) || '').toUpperCase()}}</strong></span> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="edit('country_of_citizenship', data.country_of_citizenship)">Edit</v-btn></span>
              <div v-if="passportDataMatch == 0 && passportDataComparison.country_of_citizenship == false && passportValidationData">
                <v-chip outlined color="#ff9514" class="d-flex align-center rounded">
                <v-btn x-small outlined depressed color="#1d7327" class="mr-4" @click="recognize('country_of_citizenship', passportValidationData.data.nationality_iso2, 'nationality_iso2')">
                  <v-icon small>mdi-check</v-icon>
                </v-btn>
                
                <b>{{(getCountry(passportValidationData.data.nationality_iso2) || '').toUpperCase()}}</b>
                
                <v-btn x-small outlined depressed color="#c4093c" class="ml-4" @click="unrecognize('country_of_citizenship', data.country_of_citizenship)">
                  <v-icon small class="pa-0">mdi-close</v-icon>
                </v-btn>
              </v-chip>
            </div>
            </div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-autocomplete
                    v-model="edit_val"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    item-text="country"
                    item-value="code"
                    :items="$store.state.countries"
                    hide-details="auto"
                  ></v-autocomplete> 
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">National Identification Number</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="edit_prop != 'national_identification_number'"><strong class="clipboard-style" v-clipboard="(data.national_identification_number || '').toUpperCase()">{{(data.national_identification_number || '').toUpperCase()}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="edit('national_identification_number', data.national_identification_number)">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-text-field
                    v-model="edit_val"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Personal Identification Number</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="edit_prop != 'personal_identification_number'"><strong class="clipboard-style" v-clipboard="(data.personal_identification_number || '').toUpperCase()">{{(data.personal_identification_number || '').toUpperCase()}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="edit('personal_identification_number', data.personal_identification_number)">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-text-field
                    v-model="edit_val"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Gender</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="edit_prop != 'gender'">
              <strong class="clipboard-style" v-clipboard="data.gender ? (gender.find(item => item.id == data.gender).desc || '').toUpperCase() : ''">{{data.gender ? (gender.find(item => item.id == data.gender).desc || '').toUpperCase() : ''}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="edit('gender', data.gender)">Edit</v-btn>
              <div v-if="passportDataMatch == 0 && passportDataComparison.gender == false && passportValidationData">
                  <v-chip outlined color="#ff9514" class="d-flex align-center rounded">
                  <v-btn x-small outlined depressed color="#1d7327" class="mr-4" @click="recognize('gender', passportValidationData.data.sex, 'sex')">
                    <v-icon small>mdi-check</v-icon>
                  </v-btn>
                  
                  <b>{{passportValidationData.data.sex ? (gender.find(item => item.id == passportValidationData.data.sex).desc || '').toUpperCase() : ''}}</b>
                  
                  <v-btn x-small outlined depressed color="#c4093c" class="ml-4" @click="unrecognize('gender', data.gender)">
                    <v-icon small class="pa-0">mdi-close</v-icon>
                  </v-btn>
                </v-chip>
              </div>
            </div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-autocomplete
                    v-model="edit_val"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    item-text="desc"
                    item-value="id"
                    :items="gender"
                    hide-details="auto"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Date of Birth (Day/Month/Year)</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="!edit_prop_date || edit_prop_date.join(',') != 'dob_day,dob_month,dob_year'">
              <strong class="clipboard-style" v-clipboard="data.dob_day + ' / ' + (monthNumToName(data.dob_month) || '').toUpperCase() + ' / ' + data.dob_year">{{data.dob_day + ' / ' + (monthNumToName(data.dob_month) || '').toUpperCase() + ' / ' + data.dob_year}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="editDate(['dob_day','dob_month','dob_year'], [data.dob_day, data.dob_month, data.dob_year])">Edit</v-btn>
              <div v-if="passportDataMatch == 0 && passportDataComparison.dob_day == false && passportDataComparison.dob_month == false && passportDataComparison.dob_year == false && passportValidationData">
                  <v-chip outlined color="#ff9514" class="d-flex align-center rounded">
                  <v-btn x-small outlined depressed color="#1d7327" class="mr-4" @click="recognizeDate(['dob_day','dob_month','dob_year'], [passportValidationData.data.dob_day, passportValidationData.data.dob_month, passportValidationData.data.dob_year], ['dob_day','dob_month','dob_year'])">
                    <v-icon small>mdi-check</v-icon>
                  </v-btn>
                  
                  <b>{{passportValidationData.data.dob_day + ' / ' + (monthNumToName(passportValidationData.data.dob_month) || '').toUpperCase() + ' / ' + passportValidationData.data.dob_year}}</b>
                  
                  <v-btn x-small outlined depressed color="#c4093c" class="ml-4" @click="unrecognizeDate(['dob_day','dob_month','dob_year'], [data.dob_day, data.dob_month, data.dob_year])">
                    <v-icon small class="pa-0">mdi-close</v-icon>
                  </v-btn>
                </v-chip>
              </div>
            </div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-autocomplete
                    v-model="edit_day"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    placeholder="Day"
                    :items="days"
                    hide-details="auto"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" class="pb-1">
                  <v-autocomplete
                    v-model="edit_month"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    placeholder="Month"
                    item-text="desc"
                    item-value="id"
                    :items="months"
                    hide-details="auto"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" class="pb-1">
                  <v-autocomplete
                    v-model="edit_year"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    placeholder="Year"
                    :items="years"
                    hide-details="auto"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">City of Birth</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="edit_prop != 'birth_city'">
              <strong class="clipboard-style" v-clipboard="(data.birth_city || '').toUpperCase()">{{(data.birth_city || '').toUpperCase()}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="edit('birth_city', data.birth_city)">Edit</v-btn>
              <div v-if="passportDataMatch == 0 && passportDataComparison.birth_city == false && passportValidationData">
                <v-chip outlined color="#ff9514" class="d-flex align-center rounded">
                <v-btn x-small outlined depressed color="#1d7327" class="mr-4" @click="recognize('birth_city', passportValidationData.data.placeOfBirth, 'placeOfBirth')">
                  <v-icon small>mdi-check</v-icon>
                </v-btn>
                
                <b>{{passportValidationData.data.placeOfBirth || 'NO DATA'}}</b>
                
                <v-btn x-small outlined depressed color="#c4093c" class="ml-4" @click="unrecognize('birth_city', data.birth_city)">
                  <v-icon small class="pa-0">mdi-close</v-icon>
                </v-btn>
              </v-chip>
            </div>
            </div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-text-field
                    v-model="edit_val"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Country of Birth</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="edit_prop != 'birth_country'" class="flex-items"><span class="flex-items clipboard-style py-1" v-clipboard="(getCountry(data.birth_country) || '').toUpperCase()"><img v-if="data.birth_country" class="rounded-circle mr-2" width="18" :src="require('@/assets/img/flags/1x1/' + (data.birth_country.toLowerCase()).substring(0, 2) + '.svg')" /><strong>{{(getCountry(data.birth_country) || '').toUpperCase()}}</strong></span> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="edit('birth_country', data.birth_country)">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-autocomplete
                    v-model="edit_val"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    item-text="country"
                    item-value="code"
                    :items="$store.state.countries"
                    hide-details="auto"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Are you now, a citizen or national of any other country?</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="edit_prop != 'has_other_citizenship_country_bool'"><strong class="clipboard-style" v-clipboard="data.has_other_citizenship_country_bool ? 'YES' : 'NO'">{{data.has_other_citizenship_country_bool ? 'YES' : 'NO'}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="edit('has_other_citizenship_country_bool', data.has_other_citizenship_country_bool)">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-radio-group
                    v-model="edit_val"
                    :rules="[formRules.required3]"
                    row
                    class="mt-0"
                    >
                    <v-radio
                        label="No"
                        :value="false"
                        color="#00a34e"
                    ></v-radio>
                    <v-radio
                        label="Yes"
                        :value="true"
                        color="#00a34e"
                    ></v-radio>
                </v-radio-group>
                </v-col>
                <v-col cols="12" class="pb-1" v-if="edit_val">
                  <v-textarea
                    v-model="data.has_other_citizenship_country_note"
                    color="#677788"
                    :rules="[formRules.required]"
                    width="100%"
                    outlined
                    rows="3"
                    dense
                    placeholder="Add notes here..."
                    hide-details="auto"
                  ></v-textarea>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" v-if="data.has_other_citizenship_country_note && data.has_other_citizenship_country_bool == true">
            <v-row>
              <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Note</v-col> <v-col cols="12" sm="6" class="py-1"><strong class="note-text">{{data.has_other_citizenship_country_note}}</strong></v-col>
            </v-row>
          </v-col>
          <v-col cols="12" v-if="data.has_other_citizenship_country_bool == true">
            <v-row v-for="(item, index) in data.has_other_citizenship_country" :key="index">
            <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Country of Citizenship / Nationality &mdash;&mdash;{{index + 1}}</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
              <span class="flex-items clipboard-style" v-clipboard="(getCountry(item.has_other_citizenship_country_country) || '').toUpperCase()"><img v-if="item.has_other_citizenship_country_country" class="rounded-circle mr-2" width="18" :src="require('@/assets/img/flags/1x1/' + (item.has_other_citizenship_country_country.toLowerCase()).substring(0, 2) + '.svg')" /><strong>{{(getCountry(item.has_other_citizenship_country_country) || '').toUpperCase()}}</strong></span>
            </v-col>
            <v-col cols="12" sm="6" class="text-sm-end view-title py-1">How did you acquire citizenship / nationality from this country? &mdash;&mdash;{{index + 1}}</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
              <strong class="clipboard-style" v-clipboard="item.has_other_citizenship_country_acquired">{{item.has_other_citizenship_country_acquired}}</strong>
            </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Have you ever been a citizen or national of any other country?</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="edit_prop != 'has_other_citizenship_country_before_bool'"><strong class="clipboard-style" v-clipboard="data.has_other_citizenship_country_before_bool ? 'YES' : 'NO'">{{data.has_other_citizenship_country_before_bool ? 'YES' : 'NO'}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="edit('has_other_citizenship_country_before_bool', data.has_other_citizenship_country_before_bool)">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-radio-group
                    v-model="edit_val"
                    :rules="[formRules.required3]"
                    row
                    class="mt-0"
                    >
                    <v-radio
                        label="No"
                        :value="false"
                        color="#00a34e"
                    ></v-radio>
                    <v-radio
                        label="Yes"
                        :value="true"
                        color="#00a34e"
                    ></v-radio>
                </v-radio-group>
                </v-col>
                <v-col cols="12" class="pb-1" v-if="edit_val">
                  <v-textarea
                    v-model="data.has_other_citizenship_country_before_note"
                    color="#677788"
                    :rules="[formRules.required]"
                    width="100%"
                    outlined
                    rows="3"
                    dense
                    placeholder="Add notes here..."
                    hide-details="auto"
                  ></v-textarea>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" v-if="data.has_other_citizenship_country_before_note && data.has_other_citizenship_country_before_bool == true">
            <v-row>
              <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Note</v-col> <v-col cols="12" sm="6" class="py-1"><strong class="note-text">{{data.has_other_citizenship_country_before_note}}</strong></v-col>
            </v-row>
          </v-col>
          <v-col cols="12" v-if="data.has_other_citizenship_country_before_bool == true">
            <v-row v-for="(item, index) in data.has_other_citizenship_country_before" :key="index">
            <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Country of Citizenship / Nationality &mdash;&mdash;{{index + 1}}</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
              <span class="flex-items clipboard-style" v-clipboard="(getCountry(item.has_other_citizenship_country_before_country) || '').toUpperCase()"><img v-if="item.has_other_citizenship_country_before_country" class="rounded-circle mr-2" width="18" :src="require('@/assets/img/flags/1x1/' + (item.has_other_citizenship_country_before_country.toLowerCase()).substring(0, 2) + '.svg')" /><strong>{{(getCountry(item.has_other_citizenship_country_before_country) || '').toUpperCase()}}</strong></span>
            </v-col>
            <v-col cols="12" sm="6" class="text-sm-end view-title py-1">From (Day/Month/Year) &mdash;&mdash;{{index + 1}}</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
              <strong class="clipboard-style" v-clipboard="item.has_other_citizenship_country_before_from_day + ' / ' + (monthNumToName(item.has_other_citizenship_country_before_from_month) || '').toUpperCase() + ' / ' + item.has_other_citizenship_country_before_from_year">{{item.has_other_citizenship_country_before_from_day + ' / ' + (monthNumToName(item.has_other_citizenship_country_before_from_month) || '').toUpperCase() + ' / ' + item.has_other_citizenship_country_before_from_year}}</strong>
            </v-col>
            <v-col cols="12" sm="6" class="text-sm-end view-title py-1">To (Day/Month/Year) &mdash;&mdash;{{index + 1}}</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
              <strong class="clipboard-style" v-clipboard="item.has_other_citizenship_country_before_to_day + ' / ' + (monthNumToName(item.has_other_citizenship_country_before_to_month) || '').toUpperCase() + ' / ' + item.has_other_citizenship_country_before_to_year">{{item.has_other_citizenship_country_before_to_day + ' / ' + (monthNumToName(item.has_other_citizenship_country_before_to_month) || '').toUpperCase() + ' / ' + item.has_other_citizenship_country_before_to_year}}</strong>
            </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" v-if="data.has_other_citizenship_country_before_bool == true && data.has_other_citizenship_country_bool == true && forbidden_countries.includes((data.birth_country || '').toUpperCase())">
            <v-row>
            <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Did not acquire citizenship at birth or have never held citizenship in birth country</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
              <strong>{{data.did_not_acquire_citizenship_bool ? 'YES' : 'NO' }}</strong>
            </v-col>
            <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Renounced citizenship of birth country</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
              <strong>{{data.renounced_citizenship_bool ? 'YES' : 'NO' }}</strong>
            </v-col>
            <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Have not lived or resided in birth country within the past five years</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
              <strong>{{data.have_not_lived_birth_country_in_5_years_bool ? 'YES' : 'NO' }}</strong>
            </v-col>
            <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Have not held a passport or national identity card from birth country within the past five years</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
              <strong>{{data.have_not_held_passport_in_past_5_years_bool ? 'YES' : 'NO' }}</strong>
            </v-col>
            <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Other </v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
              <strong>{{data.other_text ? 'YES' : 'NO' }}</strong>
            </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="flex-items">
          <v-col><v-divider color="#909fa9"></v-divider></v-col>
          <v-col cols="auto" class="mx-auto">
            <span class="text-subtitle1 view-main-title">Personal Information</span>
          </v-col>
          <v-col><v-divider color="#909fa9"></v-divider></v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Are you known by any other names or aliases?</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="edit_prop != 'previous_names_bool'"><strong class="clipboard-style" v-clipboard="data.previous_names_bool ? 'YES' : 'NO'">{{data.previous_names_bool ? 'YES' : 'NO'}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="edit('previous_names_bool', data.previous_names_bool)">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-radio-group
                    v-model="edit_val"
                    :rules="[formRules.required3]"
                    row
                    class="mt-0"
                    >
                    <v-radio
                        label="No"
                        :value="false"
                        color="#00a34e"
                    ></v-radio>
                    <v-radio
                        label="Yes"
                        :value="true"
                        color="#00a34e"
                    ></v-radio>
                </v-radio-group>
                </v-col>
                <v-col cols="12" class="pb-1" v-if="edit_val">
                  <v-textarea
                    v-model="data.previous_names_note"
                    color="#677788"
                    :rules="[formRules.required]"
                    width="100%"
                    outlined
                    rows="3"
                    dense
                    placeholder="Add notes here..."
                    hide-details="auto"
                  ></v-textarea>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" v-if="data.previous_names_note && data.previous_names_bool == true">
            <v-row>
              <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Note</v-col> <v-col cols="12" sm="6" class="py-1"><strong class="note-text">{{data.previous_names_note}}</strong></v-col>
            </v-row>
          </v-col>
          <v-col cols="12" v-if="data.previous_names_bool == true">
            <v-row v-for="(item, index) in data.previous_names" :key="index">
            <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Family Name &mdash;&mdash; {{index + 1}}</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
              <strong class="clipboard-style" v-clipboard="(sanitizeString(item.family_names)).toUpperCase()">{{(sanitizeString(item.family_names)).toUpperCase()}}</strong>
            </v-col>
            <v-col cols="12" sm="6" class="text-sm-end view-title py-1">First (Given) Name &mdash;&mdash;{{index + 1}}</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
              <strong class="clipboard-style" v-clipboard="(sanitizeString(item.first_given_names)).toUpperCase()">{{(sanitizeString(item.first_given_names)).toUpperCase()}}</strong>
            </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Have you ever been issued a passport or national identity card for travel by any other country?</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="edit_prop != 'has_other_issuing_country_bool'"><strong class="clipboard-style" v-clipboard="data.has_other_issuing_country_bool ? 'YES' : 'NO'">{{data.has_other_issuing_country_bool ? 'YES' : 'NO'}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="edit('has_other_issuing_country_bool', data.has_other_issuing_country_bool)">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-radio-group
                    v-model="edit_val"
                    :rules="[formRules.required3]"
                    row
                    class="mt-0"
                    >
                    <v-radio
                        label="No"
                        :value="false"
                        color="#00a34e"
                    ></v-radio>
                    <v-radio
                        label="Yes"
                        :value="true"
                        color="#00a34e"
                    ></v-radio>
                </v-radio-group>
                </v-col>
                <v-col cols="12" class="pb-1" v-if="edit_val">
                  <v-textarea
                    v-model="data.has_other_issuing_country_note"
                    color="#677788"
                    :rules="[formRules.required]"
                    width="100%"
                    outlined
                    rows="3"
                    dense
                    placeholder="Add notes here..."
                    hide-details="auto"
                  ></v-textarea>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" v-if="data.has_other_issuing_country_note && data.has_other_issuing_country_bool == true">
            <v-row>
              <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Note</v-col> <v-col cols="12" sm="6" class="py-1"><strong class="note-text">{{data.has_other_issuing_country_note}}</strong></v-col>
            </v-row>
          </v-col>
          <v-col cols="12" v-if="data.has_other_issuing_country_bool == true">
            <v-row v-for="(item, index) in data.has_other_issuing_country" :key="index">
            <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Issuing Country &mdash;&mdash;{{index + 1}}</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
              <span class="flex-items clipboard-style" v-clipboard="(getCountry(item.has_other_issuing_country_country) || '').toUpperCase()"><img v-if="item.has_other_issuing_country_country" class="rounded-circle mr-2" width="18" :src="require('@/assets/img/flags/1x1/' + (item.has_other_issuing_country_country.toLowerCase()).substring(0, 2) + '.svg')" /><strong>{{(getCountry(item.has_other_issuing_country_country) || '').toUpperCase()}}</strong></span>
            </v-col>
            <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Document Type &mdash;&mdash;{{index + 1}}</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
              <strong class="clipboard-style" v-clipboard="item.has_other_issuing_country_document_type == 'P' ? 'P (PASSPORT)' : 'NID (NATIONAL IDENTIFICATION CARD)'">{{item.has_other_issuing_country_document_type == 'P' ? 'P (PASSPORT)' : 'NID (NATIONAL IDENTIFICATION CARD)'}}</strong>
            </v-col>
            <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Document Number &mdash;&mdash;{{index + 1}}</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
              <strong class="clipboard-style" v-clipboard="(item.has_other_issuing_country_document_number || '').toUpperCase()">{{(item.has_other_issuing_country_document_number || '').toUpperCase()}}</strong>
            </v-col>
            <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Expiration Year &mdash;&mdash;{{index + 1}}</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
              <strong class="clipboard-style" v-clipboard="item.has_other_issuing_country_expiration_year">{{item.has_other_issuing_country_expiration_year}}</strong>
            </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Address Line 1</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="edit_prop != 'address_line_1'"><strong class="clipboard-style" v-clipboard="(data.address_line_1 || '').toUpperCase()">{{(data.address_line_1 || '').toUpperCase()}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor'" small @click="edit('address_line_1', data.address_line_1)">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-text-field
                    v-model="edit_val"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Address Line 2</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="edit_prop != 'address_line_2'"><strong class="clipboard-style" v-clipboard="(data.address_line_2 || '').toUpperCase()">{{(data.address_line_2 || '').toUpperCase()}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor'" small @click="edit('address_line_2', data.address_line_2)">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-text-field
                    v-model="edit_val"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Apartment Number</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="edit_prop != 'apartment_number'"><strong class="clipboard-style" v-clipboard="(sanitizeString(data.apartment_number) || '').toUpperCase()">{{(sanitizeString(data.apartment_number) || '').toUpperCase()}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor'" small @click="edit('apartment_number', data.apartment_number)">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-text-field
                    v-model="edit_val"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">City</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="edit_prop != 'city'"><strong class="clipboard-style" v-clipboard="(data.city || '').toUpperCase()">{{(data.city || '').toUpperCase()}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor'" small @click="edit('city', data.city)">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-text-field
                    v-model="edit_val"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">State</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="edit_prop != 'state'"><strong class="clipboard-style" v-clipboard="(data.state || '').toUpperCase()">{{(data.state || '').toUpperCase()}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor'" small @click="edit('state', data.state)">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-text-field
                    v-model="edit_val"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Country</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="edit_prop != 'country'" class="flex-items"><span class="flex-items clipboard-style" v-clipboard="(getCountry(data.country) || '').toUpperCase()"><img class="rounded-circle mr-2" v-if="data.country" width="18" :src="require('@/assets/img/flags/1x1/' + (data.country.toLowerCase()).substring(0, 2) + '.svg')" /><strong>{{(getCountry(data.country) || '').toUpperCase()}}</strong></span> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor'" small @click="edit('country', data.country)">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-autocomplete
                    v-model="edit_val"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    item-text="country"
                    item-value="code"
                    :items="$store.state.countries"
                    hide-details="auto"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Telephone Type</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="edit_prop != 'telephone_type'"><strong class="clipboard-style" v-clipboard="(data.telephone_type || '').toUpperCase()">{{(data.telephone_type || '').toUpperCase()}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor'" small @click="edit('telephone_type', data.telephone_type)">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-text-field
                    v-model="edit_val"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Country Code</v-col> <v-col cols="12" sm="6" class="py-1 flex-items clipboard-style" v-clipboard="(getCountry(getPhoneNumberUtil(data.phone_number, 'region')) || '').toUpperCase()"><img v-if="data.phone_number" class="rounded-circle mr-2" width="18" :src="require('@/assets/img/flags/1x1/' + (getPhoneNumberUtil(data.phone_number, 'region').toLowerCase()).substring(0, 2) + '.svg')" /><strong>{{((getCountry(getPhoneNumberUtil(data.phone_number, 'region')) || '').toUpperCase() + ' (+' + getPhoneNumberUtil(data.phone_number, 'code') + ')' || '').toUpperCase()}}</strong></v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Phone Number</v-col> <v-col cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="getPhoneNumberUtil(data.phone_number, 'number')"><strong>{{getPhoneNumberUtil(data.phone_number, 'number')}}</strong></v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Do you have social media presence?</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="edit_prop != 'social_media_presence_bool'"><strong class="clipboard-style" v-clipboard="data.social_media_presence_bool ? 'YES' : 'NO'">{{data.social_media_presence_bool ? 'YES' : 'NO'}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="edit('social_media_presence_bool', data.social_media_presence_bool)">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-radio-group
                    v-model="edit_val"
                    :rules="[formRules.required3]"
                    row
                    class="mt-0"
                    >
                    <v-radio
                        label="No"
                        :value="false"
                        color="#00a34e"
                    ></v-radio>
                    <v-radio
                        label="Yes"
                        :value="true"
                        color="#00a34e"
                    ></v-radio>
                </v-radio-group>
                </v-col>
                <v-col cols="12" class="pb-1" v-if="edit_val">
                  <v-textarea
                    v-model="data.social_media_presence_note"
                    color="#677788"
                    :rules="[formRules.required]"
                    width="100%"
                    outlined
                    rows="3"
                    dense
                    placeholder="Add notes here..."
                    hide-details="auto"
                  ></v-textarea>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" v-if="data.social_media_presence_note && data.social_media_presence_bool == true">
            <v-row>
              <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Note</v-col> <v-col cols="12" sm="6" class="py-1"><strong class="note-text">{{data.social_media_presence_note}}</strong></v-col>
            </v-row>
          </v-col>
          <v-col cols="12" v-if="data.social_media_presence_bool == true">
            <v-row>
                <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Facebook Page ID</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
                  <div v-if="edit_prop != 'facebook_page_id'"><strong class="clipboard-style" v-clipboard="data.facebook_page_id">{{data.facebook_page_id}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor'" small @click="edit('facebook_page_id', data.facebook_page_id)">Edit</v-btn></div>
                  <v-row v-else no-gutters>
                      <v-col cols="12" class="pb-1">
                        <v-text-field
                          v-model="edit_val"
                          color="#677788"
                          :rules="[formRules.required]"
                          height="45"
                          width="100%"
                          outlined
                          hide-details="auto"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                      <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" sm="6" class="text-sm-end view-title py-1">LinkedIn Profile Link</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
                <div v-if="edit_prop != 'linkedin_profile_link'"><strong class="clipboard-style" v-clipboard="data.linkedin_profile_link">{{data.linkedin_profile_link}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor'" small @click="edit('linkedin_profile_link', data.linkedin_profile_link)">Edit</v-btn></div>
                <v-row v-else no-gutters>
                    <v-col cols="12" class="pb-1">
                      <v-text-field
                          v-model="edit_val"
                          color="#677788"
                          :rules="[formRules.required]"
                          height="45"
                          width="100%"
                          outlined
                          hide-details="auto"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                    <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
                  </v-row>
              </v-col>
              <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Twitter User ID</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
                <div v-if="edit_prop != 'twitter_user_id'"><strong class="clipboard-style" v-clipboard="data.twitter_user_id">{{data.twitter_user_id}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor'" small @click="edit('twitter_user_id', data.twitter_user_id)">Edit</v-btn></div>
                <v-row v-else no-gutters>
                    <v-col cols="12" class="pb-1">
                      <v-text-field
                          v-model="edit_val"
                          color="#677788"
                          :rules="[formRules.required]"
                          height="45"
                          width="100%"
                          outlined
                          hide-details="auto"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                    <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
                  </v-row>
              </v-col>
              <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Instagram User ID</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
                <div v-if="edit_prop != 'instagram_username'"><strong class="clipboard-style" v-clipboard="data.instagram_username">{{data.instagram_username}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor'" small @click="edit('instagram_username', data.instagram_username)">Edit</v-btn></div>
                <v-row v-else no-gutters>
                    <v-col cols="12" class="pb-1">
                      <v-text-field
                          v-model="edit_val"
                          color="#677788"
                          :rules="[formRules.required]"
                          height="45"
                          width="100%"
                          outlined
                          hide-details="auto"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                    <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
                  </v-row>
              </v-col>
              <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Provider / Platform</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
                <div v-if="edit_prop != 'platform'"><strong class="clipboard-style" v-clipboard="data.platform">{{data.platform}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor'" small @click="edit('platform', data.platform)">Edit</v-btn></div>
                <v-row v-else no-gutters>
                    <v-col cols="12" class="pb-1">
                      <v-text-field
                          v-model="edit_val"
                          color="#677788"
                          :rules="[formRules.required]"
                          height="45"
                          width="100%"
                          outlined
                          hide-details="auto"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                    <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
                  </v-row>
              </v-col>
              <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Social Media Identifier</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
                <div v-if="edit_prop != 'social_media_identifier'"><strong class="clipboard-style" v-clipboard="data.social_media_identifier">{{data.social_media_identifier}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor'" small @click="edit('social_media_identifier', data.social_media_identifier)">Edit</v-btn></div>
                <v-row v-else no-gutters>
                    <v-col cols="12" class="pb-1">
                      <v-text-field
                          v-model="edit_val"
                          color="#677788"
                          :rules="[formRules.required]"
                          height="45"
                          width="100%"
                          outlined
                          hide-details="auto"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                    <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
                  </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Are you a member of the CBP Global Entry Program?</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="edit_prop != 'has_ge_passid_bool'"><strong class="clipboard-style" v-clipboard="data.has_ge_passid_bool ? 'YES' : 'NO'">{{data.has_ge_passid_bool ? 'YES' : 'NO'}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="edit('has_ge_passid_bool', data.has_ge_passid_bool)">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-radio-group
                    v-model="edit_val"
                    :rules="[formRules.required3]"
                    row
                    class="mt-0"
                    >
                    <v-radio
                        label="No"
                        :value="false"
                        color="#00a34e"
                    ></v-radio>
                    <v-radio
                        label="Yes"
                        :value="true"
                        color="#00a34e"
                    ></v-radio>
                </v-radio-group>
                </v-col>
                <v-col cols="12" class="pb-1" v-if="edit_val">
                  <v-textarea
                    v-model="data.has_ge_passid_note"
                    color="#677788"
                    :rules="[formRules.required]"
                    width="100%"
                    outlined
                    rows="3"
                    dense
                    placeholder="Add notes here..."
                    hide-details="auto"
                  ></v-textarea>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" v-if="data.has_ge_passid_note && data.has_ge_passid_bool == true">
            <v-row>
              <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Note</v-col> <v-col cols="12" sm="6" class="py-1"><strong class="note-text">{{data.has_ge_passid_note}}</strong></v-col>
            </v-row>
          </v-col>
          <v-col cols="12" v-if="data.has_ge_passid_bool == true">
            <v-row>
                <v-col cols="12" sm="6" class="text-sm-end view-title py-1">PASSID / Membership Number</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
                <div v-if="edit_prop != 'ge_passid'"><strong class="clipboard-style" v-clipboard="data.ge_passid">{{data.ge_passid}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor'" small @click="edit('ge_passid', data.ge_passid)">Edit</v-btn></div>
                <v-row v-else no-gutters>
                    <v-col cols="12" class="pb-1">
                      <v-text-field
                          v-model="edit_val"
                          color="#677788"
                          :rules="[formRules.required]"
                          height="45"
                          width="100%"
                          outlined
                          hide-details="auto"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                    <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
                  </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Mother — Family Name(s)</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
                  <div v-if="edit_prop != 'mother_family_names'"><strong class="clipboard-style" v-clipboard="(sanitizeString(data.mother_family_names) || '').toUpperCase()">{{(sanitizeString(data.mother_family_names) || '').toUpperCase()}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor'" small @click="edit('mother_family_names', data.mother_family_names)">Edit</v-btn></div>
                  <v-row v-else no-gutters>
                      <v-col cols="12" class="pb-1">
                        <v-text-field
                          v-model="edit_val"
                          color="#677788"
                          :rules="[formRules.required]"
                          height="45"
                          width="100%"
                          outlined
                          hide-details="auto"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                      <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Mother — First (Given) Name(s)</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
                <div v-if="edit_prop != 'mother_first_given_names'"><strong class="clipboard-style" v-clipboard="(sanitizeString(data.mother_first_given_names) || '').toUpperCase()">{{(sanitizeString(data.mother_first_given_names) || '').toUpperCase()}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor'" small @click="edit('mother_first_given_names', data.mother_first_given_names)">Edit</v-btn></div>
                <v-row v-else no-gutters>
                    <v-col cols="12" class="pb-1">
                      <v-text-field
                          v-model="edit_val"
                          color="#677788"
                          :rules="[formRules.required]"
                          height="45"
                          width="100%"
                          outlined
                          hide-details="auto"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                    <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
                  </v-row>
              </v-col>
              <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Father — Family Name(s)</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
                <div v-if="edit_prop != 'father_family_names'"><strong class="clipboard-style" v-clipboard="(sanitizeString(data.father_family_names) || '').toUpperCase()">{{(sanitizeString(data.father_family_names) || '').toUpperCase()}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor'" small @click="edit('father_family_names', data.father_family_names)">Edit</v-btn></div>
                <v-row v-else no-gutters>
                    <v-col cols="12" class="pb-1">
                      <v-text-field
                          v-model="edit_val"
                          color="#677788"
                          :rules="[formRules.required]"
                          height="45"
                          width="100%"
                          outlined
                          hide-details="auto"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                    <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
                  </v-row>
              </v-col>
              <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Father — First (Given) Name(s)</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
                <div v-if="edit_prop != 'father_first_given_names'"><strong class="clipboard-style" v-clipboard="(sanitizeString(data.father_first_given_names) || '').toUpperCase()">{{(sanitizeString(data.father_first_given_names) || '').toUpperCase()}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor'" small @click="edit('father_first_given_names', data.father_first_given_names)">Edit</v-btn></div>
                <v-row v-else no-gutters>
                    <v-col cols="12" class="pb-1">
                      <v-text-field
                          v-model="edit_val"
                          color="#677788"
                          :rules="[formRules.required]"
                          height="45"
                          width="100%"
                          outlined
                          hide-details="auto"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                    <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
                  </v-row>
              </v-col>
              <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Do you have a current or previous employer?</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
                <div v-if="edit_prop != 'current_previous_employer_bool'"><strong class="clipboard-style" v-clipboard="data.current_previous_employer_bool ? 'YES' : 'NO'">{{data.current_previous_employer_bool ? 'YES' : 'NO'}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="edit('current_previous_employer_bool', data.current_previous_employer_bool)">Edit</v-btn></div>
                <v-row v-else no-gutters>
                    <v-col cols="12" class="pb-1">
                      <v-radio-group
                        v-model="edit_val"
                        :rules="[formRules.required3]"
                        row
                        class="mt-0"
                        >
                        <v-radio
                            label="No"
                            :value="false"
                            color="#00a34e"
                        ></v-radio>
                        <v-radio
                            label="Yes"
                            :value="true"
                            color="#00a34e"
                        ></v-radio>
                    </v-radio-group>
                    </v-col>
                    <v-col cols="12" class="pb-1" v-if="edit_val">
                      <v-textarea
                        v-model="data.current_previous_employer_note"
                        color="#677788"
                        :rules="[formRules.required]"
                        width="100%"
                        outlined
                        rows="3"
                        dense
                        placeholder="Add notes here..."
                        hide-details="auto"
                      ></v-textarea>
                    </v-col>
                    <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                    <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
                  </v-row>
              </v-col>
              <v-col cols="12" v-if="data.current_previous_employer_note && data.current_previous_employer_bool == true">
                <v-row>
                  <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Note</v-col> <v-col cols="12" sm="6" class="py-1"><strong class="note-text">{{data.current_previous_employer_note}}</strong></v-col>
                </v-row>
              </v-col>
              <v-col cols="12" v-if="data.current_previous_employer_bool == true">
                <v-row>
                    <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Job Title</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
                      <div v-if="edit_prop != 'job_title'"><strong v-if="data.job_title" class="clipboard-style" v-clipboard="(sanitizeString(data.job_title) || '').toUpperCase()">{{(sanitizeString(data.job_title) || '').toUpperCase()}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor'" small @click="edit('job_title', data.job_title)">Edit</v-btn></div>
                      <v-row v-else no-gutters>
                          <v-col cols="12" class="pb-1">
                            <v-text-field
                              v-model="edit_val"
                              color="#677788"
                              :rules="[formRules.required]"
                              height="45"
                              width="100%"
                              outlined
                              hide-details="auto"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                          <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Employer Name</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
                    <div v-if="edit_prop != 'employer_name'"><strong class="clipboard-style" v-clipboard="(sanitizeString(data.employer_name) || '').toUpperCase()">{{(sanitizeString(data.employer_name) || '').toUpperCase()}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor'" small @click="edit('employer_name', data.employer_name)">Edit</v-btn></div>
                    <v-row v-else no-gutters>
                        <v-col cols="12" class="pb-1">
                          <v-text-field
                              v-model="edit_val"
                              color="#677788"
                              :rules="[formRules.required]"
                              height="45"
                              width="100%"
                              outlined
                              hide-details="auto"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                        <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
                      </v-row>
                  </v-col>
                  <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Address Line 1</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
                    <div v-if="edit_prop != 'employer_address_line_1'"><strong class="clipboard-style" v-clipboard="(sanitizeString(data.employer_address_line_1) || '').toUpperCase()">{{(sanitizeString(data.employer_address_line_1) || '').toUpperCase()}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor'" small @click="edit('employer_address_line_1', data.employer_address_line_1)">Edit</v-btn></div>
                    <v-row v-else no-gutters>
                        <v-col cols="12" class="pb-1">
                          <v-text-field
                              v-model="edit_val"
                              color="#677788"
                              :rules="[formRules.required]"
                              height="45"
                              width="100%"
                              outlined
                              hide-details="auto"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                        <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
                      </v-row>
                  </v-col>
                  <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Address Line 2</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
                    <div v-if="edit_prop != 'employer_address_line_2'"><strong class="clipboard-style" v-clipboard="(sanitizeString(data.employer_address_line_2) || '').toUpperCase()">{{(sanitizeString(data.employer_address_line_2) || '').toUpperCase()}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor'" small @click="edit('employer_address_line_2', data.employer_address_line_2)">Edit</v-btn></div>
                    <v-row v-else no-gutters>
                        <v-col cols="12" class="pb-1">
                          <v-text-field
                              v-model="edit_val"
                              color="#677788"
                              :rules="[formRules.required]"
                              height="45"
                              width="100%"
                              outlined
                              hide-details="auto"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                        <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
                      </v-row>
                  </v-col>
                  <v-col cols="12" sm="6" class="text-sm-end view-title py-1">City</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
                    <div v-if="edit_prop != 'employer_city'"><strong class="clipboard-style" v-clipboard="(sanitizeString(data.employer_city) || '').toUpperCase()">{{(sanitizeString(data.employer_city) || '').toUpperCase()}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor'" small @click="edit('employer_city', data.employer_city)">Edit</v-btn></div>
                    <v-row v-else no-gutters>
                        <v-col cols="12" class="pb-1">
                          <v-text-field
                              v-model="edit_val"
                              color="#677788"
                              :rules="[formRules.required]"
                              height="45"
                              width="100%"
                              outlined
                              hide-details="auto"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                        <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
                      </v-row>
                  </v-col>
                  <v-col cols="12" sm="6" class="text-sm-end view-title py-1">State</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
                    <div v-if="edit_prop != 'employer_state'"><strong class="clipboard-style" v-clipboard="(sanitizeString(data.employer_state) || '').toUpperCase()">{{(sanitizeString(data.employer_state) || '').toUpperCase()}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor'" small @click="edit('employer_state', data.employer_state)">Edit</v-btn></div>
                    <v-row v-else no-gutters>
                        <v-col cols="12" class="pb-1">
                          <v-text-field
                              v-model="edit_val"
                              color="#677788"
                              :rules="[formRules.required]"
                              height="45"
                              width="100%"
                              outlined
                              hide-details="auto"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                        <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
                      </v-row>
                  </v-col>
                  <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Country</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
                    <div v-if="edit_prop != 'employer_country'" class="flex-items"><span class="flex-items clipboard-style" v-if="data.employer_country" v-clipboard="(getCountry(data.employer_country) || '').toUpperCase()"><img v-if="data.employer_country" class="rounded-circle mr-2" width="18" :src="require('@/assets/img/flags/1x1/' + (data.employer_country.toLowerCase()).substring(0, 2) + '.svg')" /><strong>{{(getCountry(data.employer_country) || '').toUpperCase()}}</strong></span> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor'" small @click="edit('employer_country', data.employer_country)">Edit</v-btn></div>
                    <v-row v-else no-gutters>
                        <v-col cols="12" class="pb-1">
                          <v-autocomplete
                            v-model="edit_val"
                            color="#677788"
                            :rules="[formRules.required]"
                            height="45"
                            width="100%"
                            outlined
                            item-text="country"
                            item-value="code"
                            :items="$store.state.countries"
                            hide-details="auto"
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                        <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
                      </v-row>
                  </v-col>
                  <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Country Code</v-col> <v-col cols="12" sm="6" class="py-1 flex-items clipboard-style" v-clipboard="data.employer_phone_number ? (getCountry(getPhoneNumberUtil(data.employer_phone_number, 'region')) || '').toUpperCase() : ''"><img class="rounded-circle mr-2" width="18" v-if="data.employer_phone_number" :src="require('@/assets/img/flags/1x1/' + (getPhoneNumberUtil(data.employer_phone_number, 'region').toLowerCase()).substring(0, 2) + '.svg')" /><strong>{{data.employer_phone_number ? (getCountry(getPhoneNumberUtil(data.employer_phone_number, 'region')) || '').toUpperCase() + ' (+' + getPhoneNumberUtil(data.employer_phone_number, 'code') + ')' : ''}}</strong></v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Phone Number</v-col> <v-col cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="getPhoneNumberUtil(data.employer_phone_number, 'number')"><strong>{{getPhoneNumberUtil(data.employer_phone_number, 'number')}}</strong></v-col>
                </v-row>
              </v-col>
        </v-row>
        <v-row class="flex-items">
          <v-col><v-divider color="#909fa9"></v-divider></v-col>
          <v-col cols="auto" class="mx-auto">
            <span class="text-subtitle1 view-main-title">Travel Information</span>
          </v-col>
          <v-col><v-divider color="#909fa9"></v-divider></v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Is your travel to the US occurring to transit to another country?</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="edit_prop != 'transit_bool'"><strong class="clipboard-style" v-clipboard="data.transit_bool ? 'YES' : 'NO'">{{data.transit_bool ? 'YES' : 'NO'}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="edit('transit_bool', data.transit_bool)">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-radio-group
                    v-model="edit_val"
                    :rules="[formRules.required3]"
                    row
                    class="mt-0"
                    >
                    <v-radio
                        label="No"
                        :value="false"
                        color="#00a34e"
                    ></v-radio>
                    <v-radio
                        label="Yes"
                        :value="true"
                        color="#00a34e"
                    ></v-radio>
                </v-radio-group>
                </v-col>
                <v-col cols="12" class="pb-1" v-if="!edit_val">
                  <v-textarea
                    v-model="data.transit_note"
                    color="#677788"
                    :rules="[formRules.required]"
                    width="100%"
                    outlined
                    rows="3"
                    dense
                    placeholder="Add notes here..."
                    hide-details="auto"
                  ></v-textarea>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" v-if="data.transit_note && data.transit_bool == false">
            <v-row>
              <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Note</v-col> <v-col cols="12" sm="6" class="py-1"><strong class="note-text">{{data.transit_note}}</strong></v-col>
            </v-row>
          </v-col>
          <v-col cols="12" v-if="data.transit_bool == false">
            <v-row>
                <v-col cols="12" sm="6" class="text-sm-end view-title py-1">U.S. Point of Contact &mdash; Name</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
                  <div v-if="edit_prop != 'us_contact_name'"><strong class="clipboard-style" v-clipboard="data.us_contact_unknown_bool == true ? 'UNKNOWN' : (data.us_contact_name || '').toUpperCase()">{{data.us_contact_unknown_bool == true ? 'UNKNOWN' : (data.us_contact_name || '').toUpperCase()}}</strong> <v-btn text color="btnText" v-if="($store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor') && data.us_contact_unknown_bool == false" small @click="edit('us_contact_name', data.us_contact_name)">Edit</v-btn></div>
                  <v-row v-else no-gutters>
                      <v-col cols="12" class="pb-1">
                        <v-text-field
                          v-model="edit_val"
                          color="#677788"
                          :rules="[formRules.required]"
                          height="45"
                          width="100%"
                          outlined
                          hide-details="auto"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                      <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" sm="6" class="text-sm-end view-title py-1">U.S. Point of Contact &mdash; Address Line 1</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
                  <div v-if="edit_prop != 'us_contact_address_line_1'"><strong class="clipboard-style" v-clipboard="data.us_contact_unknown_bool == true ? 'UNKNOWN' : (data.us_contact_address_line_1 || '').toUpperCase()">{{data.us_contact_unknown_bool == true ? 'UNKNOWN' : (data.us_contact_address_line_1 || '').toUpperCase()}}</strong> <v-btn text color="btnText" v-if="($store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor') && data.us_contact_unknown_bool == false" small @click="edit('us_contact_address_line_1', data.us_contact_address_line_1)">Edit</v-btn></div>
                  <v-row v-else no-gutters>
                      <v-col cols="12" class="pb-1">
                        <v-text-field
                          v-model="edit_val"
                          color="#677788"
                          :rules="[formRules.required]"
                          height="45"
                          width="100%"
                          outlined
                          hide-details="auto"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                      <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" sm="6" class="text-sm-end view-title py-1">U.S. Point of Contact &mdash; Address Line 2</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
                  <div v-if="edit_prop != 'us_contact_address_line_2'"><strong class="clipboard-style" v-clipboard="data.us_contact_unknown_bool == true ? 'UNKNOWN' : (data.us_contact_address_line_2 || '').toUpperCase()">{{data.us_contact_unknown_bool == true ? 'UNKNOWN' : (data.us_contact_address_line_2 || '').toUpperCase()}}</strong> <v-btn text color="btnText" v-if="($store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor') && data.us_contact_unknown_bool == false" small @click="edit('us_contact_address_line_2', data.us_contact_address_line_2)">Edit</v-btn></div>
                  <v-row v-else no-gutters>
                      <v-col cols="12" class="pb-1">
                        <v-text-field
                          v-model="edit_val"
                          color="#677788"
                          :rules="[formRules.required]"
                          height="45"
                          width="100%"
                          outlined
                          hide-details="auto"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                      <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" sm="6" class="text-sm-end view-title py-1">U.S. Point of Contact &mdash; Apartment Number</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
                  <div v-if="edit_prop != 'us_contact_apartment_number'"><strong class="clipboard-style" v-clipboard="data.us_contact_unknown_bool == true ? 'UNKNOWN' : (data.us_contact_apartment_number || '').toUpperCase()">{{data.us_contact_unknown_bool == true ? 'UNKNOWN' : (data.us_contact_apartment_number || '').toUpperCase()}}</strong> <v-btn text color="btnText" v-if="($store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor') && data.us_contact_unknown_bool == false" small @click="edit('us_contact_apartment_number', data.us_contact_apartment_number)">Edit</v-btn></div>
                  <v-row v-else no-gutters>
                      <v-col cols="12" class="pb-1">
                        <v-text-field
                          v-model="edit_val"
                          color="#677788"
                          :rules="[formRules.required]"
                          height="45"
                          width="100%"
                          outlined
                          hide-details="auto"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                      <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" sm="6" class="text-sm-end view-title py-1">U.S. Point of Contact &mdash; City</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
                  <div v-if="edit_prop != 'us_contact_city'"><strong class="clipboard-style" v-clipboard="data.us_contact_unknown_bool == true ? 'UNKNOWN' : (data.us_contact_city || '').toUpperCase()">{{data.us_contact_unknown_bool == true ? 'UNKNOWN' : (data.us_contact_city || '').toUpperCase()}}</strong> <v-btn text color="btnText" v-if="($store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor') && data.us_contact_unknown_bool == false" small @click="edit('us_contact_city', data.us_contact_city)">Edit</v-btn></div>
                  <v-row v-else no-gutters>
                      <v-col cols="12" class="pb-1">
                        <v-text-field
                          v-model="edit_val"
                          color="#677788"
                          :rules="[formRules.required]"
                          height="45"
                          width="100%"
                          outlined
                          hide-details="auto"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                      <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" sm="6" class="text-sm-end view-title py-1">U.S. Point of Contact &mdash; State</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
                  <div v-if="edit_prop != 'us_contact_state'"><strong class="clipboard-style" v-clipboard="data.us_contact_unknown_bool == true ? 'UNKNOWN' : (data.us_contact_state || '').toUpperCase()">{{data.us_contact_unknown_bool == true ? 'UNKNOWN' : (data.us_contact_state || '').toUpperCase()}}</strong> <v-btn text color="btnText" v-if="($store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor') && data.us_contact_unknown_bool == false" small @click="edit('us_contact_state', data.us_contact_state)">Edit</v-btn></div>
                  <v-row v-else no-gutters>
                      <v-col cols="12" class="pb-1">
                        <v-text-field
                          v-model="edit_val"
                          color="#677788"
                          :rules="[formRules.required]"
                          height="45"
                          width="100%"
                          outlined
                          hide-details="auto"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                      <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
                    </v-row>
                </v-col>
              <v-col cols="12" sm="6" class="text-sm-end view-title py-1">U.S. Point of Contact &mdash; Country Code</v-col> <v-col cols="12" sm="6" class="py-1 flex-items clipboard-style" v-clipboard="data.us_contact_phone_number ? (getCountry(getPhoneNumberUtil(data.us_contact_phone_number, 'region')) || '').toUpperCase() : ''"><img class="rounded-circle mr-2" width="18" v-if="data.us_contact_phone_number" :src="require('@/assets/img/flags/1x1/' + (getPhoneNumberUtil(data.us_contact_phone_number, 'region').toLowerCase()).substring(0, 2) + '.svg')" /><strong>{{data.us_contact_phone_number ? (getCountry(getPhoneNumberUtil(data.us_contact_phone_number, 'region')) || '').toUpperCase() + ' (+' + getPhoneNumberUtil(data.us_contact_phone_number, 'code') + ')' : ''}}</strong></v-col>
              <v-col cols="12" sm="6" class="text-sm-end view-title py-1">U.S. Point of Contact &mdash; Phone Number</v-col> <v-col cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="getPhoneNumberUtil(data.us_contact_phone_number, 'number')"><strong>{{getPhoneNumberUtil(data.us_contact_phone_number, 'number')}}</strong></v-col>
              <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Is your Address While in the U.S. same as the U.S. Point of Contact Address listed above?</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
                <div v-if="edit_prop != 'us_address_same_as_us_contact_bool'"><strong class="clipboard-style" v-clipboard="data.us_address_same_as_us_contact_bool ? 'YES' : 'NO'">{{data.us_address_same_as_us_contact_bool ? 'YES' : 'NO'}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="edit('us_address_same_as_us_contact_bool', data.us_address_same_as_us_contact_bool)">Edit</v-btn></div>
                <v-row v-else no-gutters>
                    <v-col cols="12" class="pb-1">
                      <v-radio-group
                        v-model="edit_val"
                        :rules="[formRules.required3]"
                        row
                        class="mt-0"
                        >
                        <v-radio
                            label="No"
                            :value="false"
                            color="#00a34e"
                        ></v-radio>
                        <v-radio
                            label="Yes"
                            :value="true"
                            color="#00a34e"
                        ></v-radio>
                    </v-radio-group>
                    </v-col>
                    <v-col cols="12" class="pb-1" v-if="!edit_val">
                      <v-textarea
                        v-model="data.us_address_same_as_us_contact_note"
                        color="#677788"
                        :rules="[formRules.required]"
                        width="100%"
                        outlined
                        rows="3"
                        dense
                        placeholder="Add notes here..."
                        hide-details="auto"
                      ></v-textarea>
                    </v-col>
                    <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                    <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
                  </v-row>
              </v-col>
              <v-col cols="12" v-if="data.us_address_same_as_us_contact_note && data.us_address_same_as_us_contact_bool == false">
                <v-row>
                  <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Note</v-col> <v-col cols="12" sm="6" class="py-1"><strong class="note-text">{{data.us_address_same_as_us_contact_note}}</strong></v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row v-if="data.us_address_same_as_us_contact_bool == false">
              <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Address While in the U.S. &mdash; Address Line 1</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
                  <div v-if="edit_prop != 'us_address_line_1'"><strong class="clipboard-style" v-clipboard="data.us_contact_unknown_bool == true ? 'UNKNOWN' : (data.us_address_line_1 || '').toUpperCase()">{{data.us_contact_unknown_bool == true ? 'UNKNOWN' : (data.us_address_line_1 || '').toUpperCase()}}</strong> <v-btn text color="btnText" v-if="($store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor') && data.us_contact_unknown_bool == false" small @click="edit('us_address_line_1', data.us_address_line_1)">Edit</v-btn></div>
                  <v-row v-else no-gutters>
                      <v-col cols="12" class="pb-1">
                        <v-text-field
                          v-model="edit_val"
                          color="#677788"
                          :rules="[formRules.required]"
                          height="45"
                          width="100%"
                          outlined
                          hide-details="auto"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                      <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Address While in the U.S. &mdash; Address Line 2</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
                  <div v-if="edit_prop != 'us_address_line_2'"><strong class="clipboard-style" v-clipboard="data.us_contact_unknown_bool == true ? 'UNKNOWN' : (data.us_address_line_2 || '').toUpperCase()">{{data.us_contact_unknown_bool == true ? 'UNKNOWN' : (data.us_address_line_2 || '').toUpperCase()}}</strong> <v-btn text color="btnText" v-if="($store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor') && data.us_contact_unknown_bool == false" small @click="edit('us_address_line_2', data.us_address_line_2)">Edit</v-btn></div>
                  <v-row v-else no-gutters>
                      <v-col cols="12" class="pb-1">
                        <v-text-field
                          v-model="edit_val"
                          color="#677788"
                          :rules="[formRules.required]"
                          height="45"
                          width="100%"
                          outlined
                          hide-details="auto"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                      <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Address While in the U.S. &mdash; Apartment Number</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
                  <div v-if="edit_prop != 'us_apartment_number'"><strong class="clipboard-style" v-clipboard="data.us_contact_unknown_bool == true ? 'UNKNOWN' : (data.us_apartment_number || '').toUpperCase()">{{data.us_contact_unknown_bool == true ? 'UNKNOWN' : (data.us_apartment_number || '').toUpperCase()}}</strong> <v-btn text color="btnText" v-if="($store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor') && data.us_contact_unknown_bool == false" small @click="edit('us_apartment_number', data.us_apartment_number)">Edit</v-btn></div>
                  <v-row v-else no-gutters>
                      <v-col cols="12" class="pb-1">
                        <v-text-field
                          v-model="edit_val"
                          color="#677788"
                          :rules="[formRules.required]"
                          height="45"
                          width="100%"
                          outlined
                          hide-details="auto"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                      <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Address While in the U.S. &mdash; City</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
                  <div v-if="edit_prop != 'us_city'"><strong class="clipboard-style" v-clipboard="data.us_contact_unknown_bool == true ? 'UNKNOWN' : (data.us_city || '').toUpperCase()">{{data.us_contact_unknown_bool == true ? 'UNKNOWN' : (data.us_city || '').toUpperCase()}}</strong> <v-btn text color="btnText" v-if="($store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor') && data.us_contact_unknown_bool == false" small @click="edit('us_city', data.us_city)">Edit</v-btn></div>
                  <v-row v-else no-gutters>
                      <v-col cols="12" class="pb-1">
                        <v-text-field
                          v-model="edit_val"
                          color="#677788"
                          :rules="[formRules.required]"
                          height="45"
                          width="100%"
                          outlined
                          hide-details="auto"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                      <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Address While in the U.S. &mdash; State</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
                  <div v-if="edit_prop != 'us_state'"><strong class="clipboard-style" v-clipboard="data.us_contact_unknown_bool == true ? 'UNKNOWN' : (data.us_state || '').toUpperCase()">{{data.us_contact_unknown_bool == true ? 'UNKNOWN' : (data.us_state || '').toUpperCase()}}</strong> <v-btn text color="btnText" v-if="($store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor') && data.us_contact_unknown_bool == false" small @click="edit('us_state', data.us_state)">Edit</v-btn></div>
                  <v-row v-else no-gutters>
                      <v-col cols="12" class="pb-1">
                        <v-text-field
                          v-model="edit_val"
                          color="#677788"
                          :rules="[formRules.required]"
                          height="45"
                          width="100%"
                          outlined
                          hide-details="auto"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                      <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
                    </v-row>
                </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Emergency Contact Information &mdash; Family Name(s)</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="edit_prop != 'emergency_contact_family_names'"><strong class="clipboard-style" v-clipboard="(sanitizeString(data.emergency_contact_family_names) || '').toUpperCase()">{{(sanitizeString(data.emergency_contact_family_names) || '').toUpperCase()}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor'" small @click="edit('emergency_contact_family_names', data.emergency_contact_family_names)">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-text-field
                      v-model="edit_val"
                      color="#677788"
                      :rules="[formRules.required]"
                      height="45"
                      width="100%"
                      outlined
                      hide-details="auto"
                    ></v-text-field>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Emergency Contact Information &mdash; First (Given) Name(s)</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="edit_prop != 'emergency_contact_first_given_names'"><strong class="clipboard-style" v-clipboard="(sanitizeString(data.emergency_contact_first_given_names) || '').toUpperCase()">{{(sanitizeString(data.emergency_contact_first_given_names) || '').toUpperCase()}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor'" small @click="edit('emergency_contact_first_given_names', data.emergency_contact_first_given_names)">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-text-field
                      v-model="edit_val"
                      color="#677788"
                      :rules="[formRules.required]"
                      height="45"
                      width="100%"
                      outlined
                      hide-details="auto"
                    ></v-text-field>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Emergency Contact Information &mdash; Email Address</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="edit_prop != 'emergency_contact_email_address'"><strong class="clipboard-style" v-clipboard="(data.emergency_contact_email_address || '').toUpperCase()">{{(data.emergency_contact_email_address || '').toUpperCase()}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor'" small @click="edit('emergency_contact_email_address', data.emergency_contact_email_address)">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-text-field
                      v-model="edit_val"
                      color="#677788"
                      :rules="[formRules.required]"
                      height="45"
                      width="100%"
                      outlined
                      hide-details="auto"
                    ></v-text-field>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Emergency Contact Information &mdash; Country Code</v-col> <v-col cols="12" sm="6" class="py-1 flex-items clipboard-style" v-clipboard="data.emergency_contact_phone_number ? (getCountry(getPhoneNumberUtil(data.emergency_contact_phone_number, 'region')) || '').toUpperCase() : ''"><img class="rounded-circle mr-2" width="18" v-if="data.emergency_contact_phone_number" :src="require('@/assets/img/flags/1x1/' + (getPhoneNumberUtil(data.emergency_contact_phone_number, 'region').toLowerCase()).substring(0, 2) + '.svg')" /><strong>{{data.emergency_contact_phone_number ? (getCountry(getPhoneNumberUtil(data.emergency_contact_phone_number, 'region')) || '').toUpperCase() + ' (+' + getPhoneNumberUtil(data.emergency_contact_phone_number, 'code') + ')' : ''}}</strong></v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Emergency Contact Information &mdash; Phone Number</v-col> <v-col cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="getPhoneNumberUtil(data.emergency_contact_phone_number, 'number')"><strong>{{getPhoneNumberUtil(data.emergency_contact_phone_number, 'number')}}</strong></v-col>
        </v-row>
        <v-row class="flex-items">
          <v-col><v-divider color="#909fa9"></v-divider></v-col>
          <v-col cols="auto" class="mx-auto">
            <span class="text-subtitle1 view-main-title">Eligibility Questions</span>
          </v-col>
          <v-col><v-divider color="#909fa9"></v-divider></v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">1) Do you have a physical or mental disorder; or are you a drug abuser or addict; or do you currently have any of the following diseases (communicable diseases are specified pursuant to section 361(b) of the Public Health Service Act): Cholera, Diphtheria, Tuberculosis, infectious, Plague, Smallpox, Yellow Fever, Viral Hemorrhagic Fevers, including Ebola, Lassa, Marburg, Crimean-Congo, Severe acute respiratory illnesses capable of transmission to other persons and likely to cause mortality.</v-col> <v-col cols="12" sm="6" class="py-1"><strong><span v-if="data.mental_disorder_bool == true" style='color:#f79911;'>1) YES</span> <span v-else>1) NO</span></strong></v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">2) Have you ever been arrested or convicted for a crime that resulted in serious damage to property, or serious harm to another person or government authority?</v-col> <v-col cols="12" sm="6" class="py-1"><strong><span v-if="data.been_arrested_bool == true" style='color:#f79911;'>2) YES</span> <span v-else>2) NO</span></strong></v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">3) Have you ever violated any law related to possessing, using, or distributing illegal drugs?</v-col> <v-col cols="12" sm="6" class="py-1"><strong><span v-if="data.drugs_bool == true" style='color:#f79911;'>3) YES</span> <span v-else>3) NO</span></strong></v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">4) Do you seek to engage in or have you ever engaged in terrorist activities, espionage, sabotage, or genocide?</v-col> <v-col cols="12" sm="6" class="py-1"><strong><span v-if="data.terrorist_bool == true" style='color:#f79911;'>4) YES</span> <span v-else>4) NO</span></strong></v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">5) Have you ever committed fraud or misrepresented yourself or others to obtain, or assist others to obtain, a visa or entry into the United States?</v-col> <v-col cols="12" sm="6" class="py-1"><strong><span v-if="data.obtain_visa_bool == true" style='color:#f79911;'>5) YES</span> <span v-else>5) NO</span></strong></v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">6) Are you currently seeking employment in the United States or were you previously employed in the United States without prior permission from the U.S. government?</v-col> <v-col cols="12" sm="6" class="py-1"><strong><span v-if="data.usa_employment_bool == true" style='color:#f79911;'>6) YES</span> <span v-else>6) NO</span></strong></v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">7) Have you ever been denied a U.S. visa you applied for with your current or previous passport, or have you ever been refused admission to the United States or withdrawn your application for admission at a U.S. port of entry?</v-col> <v-col cols="12" sm="6" class="py-1"><strong><span v-if="data.rejected_visa_bool == true" style='color:#f79911;'>7) YES</span> <span v-else>7) NO</span></strong></v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">8) Have you ever stayed in the United States longer than the admission period granted to you by the U.S. government?</v-col> <v-col cols="12" sm="6" class="py-1"><strong><span v-if="data.stay_longer_bool == true" style='color:#f79911;'>8) YES</span> <span v-else>8) NO</span></strong></v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">9) Have you traveled to, or been present in Iran, Iraq, Libya, North Korea, Somalia, Sudan, Syria or Yemen on or after March 1, 2011?</v-col> <v-col cols="12" sm="6" class="py-1"><strong><span v-if="data.been_in_forbidden_countries_bool == true" style='color:#f79911;'>9) YES</span> <span v-else>9) NO</span></strong></v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">10) Have you ever traveled to, or been present in Cuba on or after January 12, 2021?</v-col> <v-col cols="12" sm="6" class="py-1"><strong><span v-if="data.been_in_cuba_bool == true" style='color:#f79911;'>10) YES</span> <span v-else>10) NO</span></strong></v-col>
        </v-row>
      </v-form>
      <v-dialog
      v-model="dialogConfirmation"
      width="520"
    >
      <v-card>
        <v-card-title class="text-subtitle-1">
          <strong v-if="action == 'accept'">Accept data for Application #{{data.token}}</strong>
          <strong v-else-if="action == 'ignore'">Ignore data for Application #{{data.token}}</strong>
        </v-card-title>

        <v-card-text>
          <v-form ref="dialogForm">
            <div v-if="action == 'accept'">
              Are you sure you want to <strong>ACCEPT</strong> the data of [{{this.recog_prop || this.recog_prop_date.join(",")}}] from the validated passport?<br />
            </div>
            <div v-else-if="action == 'ignore'">
              Are you sure you want to <strong>IGNORE</strong> the data of [{{this.recog_prop || this.recog_prop_date.join(",")}}] from the validated passport?<br />
            </div>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            outlined
            class="mr-2"
            depressed
            @click="dialogConfirmation = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="#398442"
            class="white--text"
            depressed
            @click="updateRecognizedData()"
            v-if="action == 'accept'"
          >
            Yes, Accept
          </v-btn>
          <v-btn
            color="#b02c00"
            class="white--text"
            depressed
            @click="updateRecognizedData()"
            v-else-if="action == 'ignore'"
          >
            Yes, Ignore
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </div>
</template>

<script>
  export default {
    props: {
        data: {},
        passportDataMatch: null,
        passportDataComparison: {},
        passportValidationData: {},
    },
    components: {
    },
    data: () => ({
      edit_val: null,
      edit_prop: null,
      edit_day: null,
      edit_month: null,
      edit_year: null,
      edit_prop_date: null,
      days: ['01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31'],
      months: [
          {id: '01', desc: 'January'},
          {id: '02', desc: 'February'},
          {id: '03', desc: 'March'},
          {id: '04', desc: 'April'},
          {id: '05', desc: 'May'},
          {id: '06', desc: 'June'},
          {id: '07', desc: 'July'},
          {id: '08', desc: 'August'},
          {id: '09', desc: 'September'},
          {id: '10', desc: 'October'},
          {id: '11', desc: 'November'},
          {id: '12', desc: 'December'},
      ],
      years: [],
      years2: [],
      yearsAll: [],
      gender: [
        {id: 'M', desc: 'Male'},
        {id: 'F', desc: 'Female'},
      ],
      forbidden_countries: ["CU","IR","IQ","LY","KP","SO","SD","SY","YE"],
      dialogConfirmation: false,
      action: '',
      recog_prop: null,
      recog_val: null,
      recog_day: null,
      recog_month: null,
      recog_year: null,
      recog_prop_date: null,
      passport_prop: null,
      passport_prop_date: null,
    }),
    created() {
        const currentYear = (new Date()).getFullYear();
        const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1}, (_, i) => start + (i * step));
        this.years = range(currentYear, currentYear - 100, -1); 
        this.years2 = range(currentYear, currentYear + 100, 1); 
        let years_all = range(currentYear - 100, currentYear + 100, 1);
        this.yearsAll = years_all.sort(function(a, b) {
        return b - a;
    });
    },
    methods: {
        setLanguage(lang){
          const languageNames = new Intl.DisplayNames(['en'], {
            type: 'language'
          });
          return languageNames.of(lang)
        },
        getCountry(code) {
          let res = 'None'
          if(code){
          this.$store.state.countries.forEach(item => {
            if(item.code.toLowerCase() == code.toLowerCase() || item.code_3.toLowerCase() == code.toLowerCase()){
              res = item.country
            }
          });
          }
          return res;
        },
        edit(prop, val){
          this.edit_val = val;
          this.edit_prop = prop
        },
        editDate(prop, val){
          this.edit_day = val[0];
          this.edit_month = val[1];
          this.edit_year = val[2];
          this.edit_prop_date = prop
        },
        updateData(){
          if(this.$refs.form.validate()){
          if(this.edit_prop_date){
              this.data[this.edit_prop_date[0]] = this.edit_day;
              this.data[this.edit_prop_date[1]] = this.edit_month;
              this.data[this.edit_prop_date[2]] = this.edit_year;
          }
          else if(this.edit_prop + '_text' in this.data){
            let country = this.getCountry(this.edit_val);
            if(country != 'None'){
              // console.log("updated the" + this.edit_prop + '_text')
              this.data[this.edit_prop] = this.edit_val
              this.data[this.edit_prop + '_text'] = country;
            }
          }
          else{
          this.data[this.edit_prop] = this.edit_val
          }
          let data = {
            token: this.data.token,
            data: JSON.stringify(this.data)
          };
          this.axiosCall("/applications/updateData", "POST", data).then((res) => {
            if (res.data.status == 200) {
              this.showToast("Application field [" + (this.edit_prop || this.edit_prop_date) + "] was successfully updated.", 'success')
              this.cancelEdit();
            }
            else{
              this.showToast(res.data.msg, 'error')
            }
          });
          }
        },
        cancelEdit(){
          this.edit_val = null;
          this.edit_prop = null;
          this.edit_day = null;
          this.edit_month = null;
          this.edit_year = null;
          this.edit_prop_date = null;

          this.recog_val = null;
          this.recog_prop = null;
          this.recog_day = null;
          this.recog_month = null;
          this.recog_year = null;
          this.recog_prop_date = null;
          this.passport_prop = null;
        },
        recognize(prop, val, passport_prop){
          this.recog_val = val;
          this.recog_prop = prop
          this.passport_prop = passport_prop;
          this.dialogConfirmation = true
          this.action = 'accept'
        },
        unrecognize(prop, val){
          this.recog_val = val;
          this.recog_prop = prop
          this.dialogConfirmation = true
          this.action = 'ignore'
        },
        recognizeDate(prop, val, passport_prop_date){
          this.recog_day = val[0];
          this.recog_month = val[1];
          this.recog_year = val[2];
          this.recog_prop_date = prop
          this.passport_prop_date = passport_prop_date;
          this.dialogConfirmation = true
          this.action = 'accept'
        },
        unrecognizeDate(prop, val){
          this.recog_day = val[0];
          this.recog_month = val[1];
          this.recog_year = val[2];
          this.recog_prop_date = prop
          this.dialogConfirmation = true
          this.action = 'ignore'
        },
        updateRecognizedData(){
          if(this.recog_prop_date){
              this.data[this.recog_prop_date[0]] = this.recog_day;
              this.data[this.recog_prop_date[1]] = this.recog_month;
              this.data[this.recog_prop_date[2]] = this.recog_year;
          }
          else{
              this.data[this.recog_prop] = this.recog_val
          }
          let props = this.recog_prop || this.recog_prop_date.join(",")
          let data = {
            token: this.data.token,
            data: JSON.stringify(this.data),
            props: props,
            action: this.action
          };
          this.axiosCall("/applications/updateMismatchedData", "POST", data).then((res) => {
            if (res.data.status == 200) {
              this.showToast("Mismatched data [" + (this.recog_prop || this.recog_prop_date) + "] was successfully " + (this.action == 'accept' ? 'accepted' : 'ignored') + ".", 'success')
              this.dialogConfirmation = false
              this.cancelEdit();
              this.reinitialize();
            }
            else{
              this.showToast(res.data.msg, 'error')
            }
          });
        },
        reinitialize(){
          this.eventHub.$emit("reinitializeUS", false);
        }
    },
    mounted(){
      // console.log(this.passportDataMatch)
      // console.log(this.passportDataComparison)
      // console.log(this.passportValidationData)
      console.log(this.data)
    }
  }
</script>
<style scoped>
.view-main-title {
  color: #4d6585 !important;
  font-weight: 500;
  font-size: 16px;
}
.view-title {
  color: #4d6585 !important;
  font-weight: 500;
}
</style>