<template>
  <div class="pa-5 footer-bg" v-show="$route.path != '/' + $store.state.user.role + '/evidence/' + $route.params.ref">
    {{ current_year }} © VIP GLOBAL, LLC. All rights reserved.
  </div>
</template>
<script>
export default {
    data: () => ({
        current_year: null
    }),
    mounted(){
       this.current_year = new Date().getFullYear()
    }
  }
</script>