<template>
    <div class="py-8">
      <v-form @submit.prevent ref="form">
        <v-row class="flex-items">
          <v-col><v-divider color="#909fa9"></v-divider></v-col>
          <v-col cols="auto" class="mx-auto">
            <span class="text-subtitle1 view-main-title">Applicant Information</span>
          </v-col>
          <v-col><v-divider color="#909fa9"></v-divider></v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Select Your Nationality:</v-col> <v-col cols="12" sm="6" class="py-1 flex-items clipboard-style" v-clipboard="(getCountry(data.nationality) || '').toUpperCase()"><img v-if="data.nationality" class="rounded-circle mr-2" width="18" :src="require('../../assets/img/flags/1x1/' + (data.nationality.toLowerCase()).substring(0, 2) + '.svg')" /><strong>{{(getCountry(data.nationality) || '').toUpperCase()}}</strong></v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Passport Number</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="edit_prop != 'passport_number'"><strong class="clipboard-style" v-clipboard="(data.passport_number || '').toUpperCase()">{{(data.passport_number || '').toUpperCase()}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="edit('passport_number', data.passport_number)">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-text-field
                      v-model="edit_val"
                      color="#677788"
                      :rules="[formRules.required]"
                      height="45"
                      width="100%"
                      outlined
                      hide-details="auto"
                    ></v-text-field>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">System Email</v-col> <v-col cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="systemEmail"><strong>{{systemEmail}}</strong></v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Select the nationality as written on your passport bio page.</v-col> <v-col cols="12" sm="6" class="py-1 flex-items clipboard-style" v-clipboard="(getCountry(data.nationality) || '').toUpperCase()"><img v-if="data.nationality" class="rounded-circle mr-2" width="18" :src="require('../../assets/img/flags/1x1/' + (data.nationality.toLowerCase()).substring(0, 2) + '.svg')" /><strong>{{(getCountry(data.nationality) || '').toUpperCase()}}</strong></v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Select the sex as written on your passport bio page</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="edit_prop != 'sex'"><strong class="clipboard-style" v-clipboard="data.sex ? (gender.find(item => item.id == data.sex).desc || '').toUpperCase() : ''">{{data.sex ? (gender.find(item => item.id == data.sex).desc || '').toUpperCase() : ''}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="edit('sex', data.sex)">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-autocomplete
                    v-model="edit_val"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    item-text="desc"
                    item-value="id"
                    :items="gender"
                    hide-details="auto"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Surname</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="edit_prop != 'family_names'"><strong class="clipboard-style" v-clipboard="(data.family_names || '').toUpperCase()">{{(data.family_names || '').toUpperCase()}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="edit('family_names', data.family_names)">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-text-field
                      v-model="edit_val"
                      color="#677788"
                      :rules="[formRules.required]"
                      height="45"
                      width="100%"
                      outlined
                      hide-details="auto"
                    ></v-text-field>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">First (Given) Name</v-col> <v-col cols="12" sm="6" class="py-1">
            <div v-if="edit_prop != 'first_given_names'"><strong class="clipboard-style" v-clipboard="(data.first_given_names || '').toUpperCase()">{{(data.first_given_names || '').toUpperCase()}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="edit('first_given_names', data.first_given_names)">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-text-field
                      v-model="edit_val"
                      color="#677788"
                      :rules="[formRules.required]"
                      height="45"
                      width="100%"
                      outlined
                      hide-details="auto"
                    ></v-text-field>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Date of Birth (Year/Month/Day)</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
          <div v-if="!edit_prop_date || edit_prop_date.join(',') != 'dob_year,dob_month,dob_day'"><strong class="clipboard-style" v-clipboard="data.dob_year + ' / ' + (monthNumToName(data.dob_month) || '').toUpperCase() + ' / ' + data.dob_day">{{data.dob_year + ' / ' + (monthNumToName(data.dob_month) || '').toUpperCase() + ' / ' + data.dob_day}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="editDate(['dob_year','dob_month','dob_day'], [data.dob_year, data.dob_month, data.dob_day])">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-autocomplete
                    v-model="edit_year"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    placeholder="Year"
                    :items="years"
                    hide-details="auto"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" class="pb-1">
                  <v-autocomplete
                    v-model="edit_month"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    placeholder="Month"
                    item-text="desc"
                    item-value="id"
                    :items="months"
                    hide-details="auto"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" class="pb-1">
                  <v-autocomplete
                    v-model="edit_day"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    placeholder="Day"
                    :items="days"
                    hide-details="auto"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Passport Expiration Date (Year/Month/Day)</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
          <div v-if="!edit_prop_date || edit_prop_date.join(',') != 'passport_expiration_year,passport_expiration_month,passport_expiration_day'"><strong class="clipboard-style" v-clipboard="data.passport_expiration_year + ' / ' + (monthNumToName(data.passport_expiration_month) || '').toUpperCase() + ' / ' + data.passport_expiration_day">{{data.passport_expiration_year + ' / ' + (monthNumToName(data.passport_expiration_month) || '').toUpperCase() + ' / ' + data.passport_expiration_day}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="editDate(['passport_expiration_year','passport_expiration_month','passport_expiration_day'], [data.passport_expiration_year, data.passport_expiration_month, data.passport_expiration_day])">Edit</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-autocomplete
                    v-model="edit_year"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    placeholder="Year"
                    :items="years2"
                    hide-details="auto"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" class="pb-1">
                  <v-autocomplete
                    v-model="edit_month"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    placeholder="Month"
                    item-text="desc"
                    item-value="id"
                    :items="months"
                    hide-details="auto"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" class="pb-1">
                  <v-autocomplete
                    v-model="edit_day"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    placeholder="Day"
                    :items="days"
                    hide-details="auto"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" v-if="data.national_identification_number">
            <v-row>
              <v-col cols="12" sm="6" class="text-sm-end view-title py-1">National Identification Number</v-col> <v-col cols="12" sm="6" class="py-1">
                <div v-if="edit_prop != 'national_identification_number'"><strong class="clipboard-style" v-clipboard="(data.national_identification_number || '').toUpperCase()">{{(data.national_identification_number || '').toUpperCase()}}</strong> <v-btn text color="btnText" v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'agent'" small @click="edit('national_identification_number', data.national_identification_number)">Edit</v-btn></div>
                <v-row v-else no-gutters>
                  <v-col cols="12" class="pb-1">
                    <v-text-field
                        v-model="edit_val"
                        color="#677788"
                        :rules="[formRules.required]"
                        height="45"
                        width="100%"
                        outlined
                        hide-details="auto"
                      ></v-text-field>
                  </v-col>
                  <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                  <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1" >Please select if you are also a national or citizen of another country other than the nationality of your passport</v-col> <v-col cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="data.has_other_citizenship_country_bool ? 'YES' : 'NO'"><strong>{{data.has_other_citizenship_country_bool ? 'YES' : 'NO'}}</strong></v-col>
          <v-col cols="12" v-if="data.has_other_citizenship_country_bool">
            <v-row>
              <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Country of Citizenship / Nationality</v-col> <v-col cols="12" sm="6" class="py-1"><strong>{{getCountry(data.has_other_citizenship_country)}}</strong></v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Phone Country Code</v-col> <v-col cols="12" sm="6" class="py-1 flex-items clipboard-style" v-clipboard="(getCountry(getPhoneNumberUtil(data.phone_number.includes('+') ? data.phone_number : data.phone_number_code.dialCode + '' + data.phone_number, 'region')) || '').toUpperCase()"><img v-if="data.phone_number.includes('+')" class="rounded-circle mr-2" width="18" :src="require('../../assets/img/flags/1x1/' + (getCountry(getPhoneNumberUtil(data.phone_number.includes('+') ? data.phone_number : data.phone_number_code.dialCode + '' + data.phone_number, 'region') || '').toLowerCase()).substring(0, 2) + '.svg')" /><strong>{{(getCountry(getPhoneNumberUtil(data.phone_number.includes('+') ? data.phone_number : data.phone_number_code.dialCode + '' + data.phone_number, 'region')) || '').toUpperCase() + ' (+' + getPhoneNumberUtil(data.phone_number.includes('+') ? data.phone_number : data.phone_number_code.dialCode + '' + data.phone_number, 'code') + ')'}}</strong></v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Phone Number</v-col> <v-col cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="getPhoneNumberUtil(data.phone_number.includes('+') ? data.phone_number : data.phone_number_code.dialCode + '' + data.phone_number, 'number')"><strong>{{getPhoneNumberUtil(data.phone_number.includes('+') ? data.phone_number : data.phone_number_code.dialCode + '' + data.phone_number, 'number')}}</strong></v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Have you ever visited Korea before?</v-col> <v-col cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="data.visited_korea_before ? 'YES' : 'NO'"><strong>{{data.visited_korea_before ? "YES" : "NO"}}</strong></v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Select your purpose of entry.</v-col> <v-col cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="(data.purpose_of_entry || '').toUpperCase()" ><strong>{{(data.purpose_of_entry || '').toUpperCase()}}</strong></v-col>
          <v-col cols="12" v-if="data.purpose_of_entry == 'Other'">
            <v-row>
              <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Other purpose of entry.</v-col> <v-col cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="(urlDecode(data.purpose_of_entry_other) || '').toUpperCase()"><strong>{{(urlDecode(data.purpose_of_entry_other) || '').toUpperCase()}}</strong></v-col>
            </v-row>
          </v-col>
          <v-col cols="12" v-else-if="data.purpose_of_entry == 'Tour'">
            <v-row>
              <v-col cols="12" sm="6" class="text-sm-end view-title py-1" v-if="data.purpose_of_entry == 'Tour'">Tour Type</v-col> <v-col cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="(data.purpose_of_entry_tour_type || '').toUpperCase()"><strong>{{(data.purpose_of_entry_tour_type || '').toUpperCase()}}</strong></v-col>
            </v-row>
            <v-row v-if="data.purpose_of_entry_tour_type == 'Group'">  
              <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Travel Agency Phone Number</v-col> <v-col cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="(data.purpose_of_entry_travel_agency_phone_number || '').toUpperCase()"><strong>{{(data.purpose_of_entry_travel_agency_phone_number || '').toUpperCase()}}</strong></v-col>
              <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Travel Agency Name</v-col> <v-col cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="(urlDecode(data.purpose_of_entry_travel_agency_name) || '').toUpperCase"><strong v-if="data.purpose_of_entry_travel_agency_name">{{(urlDecode(data.purpose_of_entry_travel_agency_name) || '').toUpperCase()}}</strong></v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Address</v-col> <v-col cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="(urlDecode(data.address) || '').toUpperCase()"><strong>{{(urlDecode(data.address) || '').toUpperCase()}}</strong></v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Address Details</v-col> <v-col cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="(urlDecode(data.address_details) || '').toUpperCase()"><strong>{{(urlDecode(data.address_details) || '').toUpperCase()}}</strong></v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Please enter your contact number in Korea</v-col> <v-col cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="(sanitizeString(data.contact_phone_number) || '').toUpperCase()"><strong>{{(sanitizeString(data.contact_phone_number) || '').toUpperCase()}}</strong></v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Select the category that best represents your current job</v-col> <v-col cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="(job_categories[data.job_category] || '').toUpperCase()"><strong>{{(job_categories[data.job_category] || '').toUpperCase()}}</strong></v-col>
          <v-col cols="12" v-if="data.job_category == 'Etc'">
            <v-row>
              <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Fill your current job</v-col> <v-col cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="(urlDecode(data.job_category_other) || '').toUpperCase()"><strong>{{(urlDecode(data.job_category_other) || '').toUpperCase()}}</strong></v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Are you currently suffering from any of the following diseases?</v-col> <v-col cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="data.diseases_bool ? 'YES' : 'NO'"><strong>{{data.diseases_bool ? "YES" : "NO"}}</strong></v-col>
          <v-col cols="12" v-if="data.diseases_bool">
            <v-row>
              <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Please explain the illness you are suffering from in detail</v-col> <v-col cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="(urlDecode(data.diseases_explanation) || '').toUpperCase()"><strong>{{(urlDecode(data.diseases_explanation) || '').toUpperCase()}}</strong></v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Have you ever been arrested, charged, or convicted of a criminal offense in your country or abroad?</v-col> <v-col cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="data.arrested_bool ? 'YES' : 'NO'"><strong>{{data.arrested_bool ? "YES" : "NO"}}</strong></v-col>
          <v-col cols="12" v-if="data.arrested_bool">
            <v-row>
              <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Write the details including of the country (where), the time of conviction (when), sentence name (what), sentence term (period), fine (amount), and probation</v-col> <v-col cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="(urlDecode(data.arrested_explanation) || '').toUpperCase()"><strong>{{(urlDecode(data.arrested_explanation) || '').toUpperCase()}}</strong></v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Applicant Photo
            <br />
            <v-chip
              class="chip-status-2 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap"
              small
              v-if="data.photo_details_optimized"
            >
            <span>Compressed</span>
            </v-chip>
            <v-chip
              class="chip-status-1 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap"
              small
              v-else
            >
            <span>Non-Compressed</span>
            </v-chip>
            </v-col> <v-col cols="12" sm="6" class="py-1">
            <div v-if="data.photo_details_optimized">
            <div><img class="image-fluid rounded-lg" height="300" :src="data.photo_details_optimized"/></div>
            <v-btn color="#f5ca99" class="px-15 py-4" depressed @click="downloadPhoto(data.photo_details_optimized, data.first_given_names, data.family_names, 'PHOTO')"><v-icon>mdi-download</v-icon> Download Photo</v-btn>
            </div>
            <div v-else>
            <div><img class="image-fluid rounded-lg" height="300" :src="'https://esta-apply.s3.amazonaws.com/' + data.photo_details"/></div>
            <v-btn color="#f5ca99" class="px-15 py-4" depressed @click="downloadPhoto('https://esta-apply.s3.amazonaws.com/' + data.photo_details, data.first_given_names, data.family_names, 'PHOTO')"><v-icon>mdi-download</v-icon> Download Photo</v-btn>
            </div>
          </v-col>
        </v-row>
        <v-row v-if="data.high_risk_nationality">
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Select country of birth</v-col> <v-col cols="12" sm="6" class="py-1 flex-items clipboard-style" v-clipboard="(getCountry(data.hrn_country_of_birth) || '').toUpperCase()"><strong><img v-if="data.hrn_country_of_birth" class="rounded-circle mr-2" width="18" :src="require('../../assets/img/flags/1x1/' + (data.hrn_country_of_birth.toLowerCase()).substring(0, 2) + '.svg')" /><strong>{{(getCountry(data.hrn_country_of_birth) || '').toUpperCase()}}</strong></strong></v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Enter place of birth as written on your passport bio page</v-col> <v-col cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="(sanitizeString(urlDecode(data.hrn_place_of_birth)) || '').toUpperCase()"><strong>{{(sanitizeString(urlDecode(data.hrn_place_of_birth)) || '').toUpperCase()}}</strong></v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Planned duration from date</v-col> <v-col cols="12" sm="6" class="py-1"><strong><span v-if="data.hrn_planned_duration_from_date">{{formatDate3(data.hrn_planned_duration_from_date)}}</span><span v-else style="color:#677788">NOT PROVIDED (OPTIONAL)</span></strong></v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Planned duration to date</v-col> <v-col cols="12" sm="6" class="py-1"><strong><span v-if="data.hrn_planned_duration_to_date">{{formatDate3(data.hrn_planned_duration_to_date)}}</span><span v-else style="color:#677788">NOT PROVIDED (OPTIONAL)</span></strong></v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Do you have any acquaintances in Korea?</v-col> <v-col cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="data.hrn_has_acquaintances_bool ? 'YES' : 'NO'"><strong>{{data.hrn_has_acquaintances_bool ? "YES" : "NO"}}</strong></v-col>
          <v-col cols="12" v-if="data.hrn_has_acquaintances_bool">
            <v-row>
              <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Acquaintance\'s Name</v-col> <v-col cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="(urlDecode(data.hrn_acquaintance_name) || '').toUpperCase()"><strong>{{(urlDecode(data.hrn_acquaintance_name) || '').toUpperCase()}}</strong></v-col>
              <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Acquaintance\'s Phone Number</v-col> <v-col cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="(urlDecode(data.hrn_acquaintance_phone_number) || '').toUpperCase()"><strong>{{(urlDecode(data.hrn_acquaintance_phone_number) || '').toUpperCase()}}</strong></v-col>
              <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Relation</v-col> <v-col cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="(acq_relations[data.hrn_acquaintance_relation] || '').toUpperCase()"><strong>{{(acq_relations[data.hrn_acquaintance_relation] || '').toUpperCase()}}</strong></v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Do you have an accompanying person?</v-col> <v-col cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="data.hrn_has_accompanying_person_bool ? 'YES' : 'NO'"><strong>{{data.hrn_has_accompanying_person_bool ? "YES" : "NO"}}</strong></v-col>
          <v-col cols="12" v-if="data.hrn_has_accompanying_person_bool">
            <v-row v-for="(person, index) in data.hrn_accompanying_persons" :key="index">
              <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Accompanying Person #{{index + 1}} Details</v-col> <v-col cols="12" sm="6" class="py-1"><strong>
              <div class="py-1"><span class="view-title">Nationality: </span><span class="pa-1 clipboard-style" v-clipboard="(getCountry(person.nationality) || '').toUpperCase()">{{(getCountry(person.nationality) || '').toUpperCase()}}</span></div>
              <div class="py-1"><span class="view-title">Name: </span><span v-if="person.name" class="pa-1 clipboard-style" v-clipboard="(urlDecode(person.name) || '').toUpperCase()">{{(urlDecode(person.name) || '').toUpperCase()}}</span></div>
              <div class="py-1"><span class="view-title">Date of Birth: </span><span class="pa-1 clipboard-style" v-clipboard="person.dob_year + ' / ' + (monthNumToName(person.dob_month) || '').toUpperCase() + ' / ' + person.dob_day">{{person.dob_year + ' / ' + (monthNumToName(person.dob_month) || '').toUpperCase() + ' / ' + person.dob_day}}</span></div>
              <div class="py-1"><span class="view-title">Relation: </span><span class="pa-1 clipboard-style" v-clipboard="(acc_array[person.relation] || '').toUpperCase()">{{(acc_array[person.relation] || '').toUpperCase()}}</span></div>
              </strong></v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Have you ever visited another country other than the Republic of Korea?</v-col> <v-col cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="(visited_arr[data.hrn_has_visited_another_country] || '').toUpperCase()"><strong>{{(visited_arr[data.hrn_has_visited_another_country] || '').toUpperCase()}}</strong></v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Are you currently employed?</v-col> <v-col cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="data.hrn_is_currently_employed_bool ? 'YES' : 'NO'"><strong>{{data.hrn_is_currently_employed_bool ? "YES" : "NO"}}</strong></v-col>
          <v-col cols="12" v-if="data.hrn_is_currently_employed_bool">
            <v-row>
              <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Enter the name of your employer or company</v-col> <v-col cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="(urlDecode(data.hrn_employer_name) || '').toUpperCase()"><strong>{{(urlDecode(data.hrn_employer_name) || '').toUpperCase()}}</strong></v-col>
              <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Enter the phone number of your employer or company</v-col> <v-col cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="(urlDecode(data.hrn_employer_phone_number) || '').toUpperCase()"><strong>{{(urlDecode(data.hrn_employer_phone_number) || '').toUpperCase()}}</strong></v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Select the area that includes your monthly income (in USD)</v-col> <v-col cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="(income_arr[data.hrn_monthly_income] || '').toUpperCase()"><strong>{{(income_arr[data.hrn_monthly_income] || '').toUpperCase()}}</strong></v-col>
        </v-row>
      </v-form>
        </div>
</template>

<script>
  export default {
    props: {
        data: {},
        systemEmail: null
    },
    components: {
    },
    data: () => ({
      edit_val: null,
      edit_prop: null,
      edit_day: null,
      edit_month: null,
      edit_year: null,
      edit_prop_date: null,
      days: ['01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31'],
      months: [
          {id: '01', desc: 'January'},
          {id: '02', desc: 'February'},
          {id: '03', desc: 'March'},
          {id: '04', desc: 'April'},
          {id: '05', desc: 'May'},
          {id: '06', desc: 'June'},
          {id: '07', desc: 'July'},
          {id: '08', desc: 'August'},
          {id: '09', desc: 'September'},
          {id: '10', desc: 'October'},
          {id: '11', desc: 'November'},
          {id: '12', desc: 'December'},
      ],
      years: [],
      years2: [],
      yearsAll: [],
      gender: [
        {id: 'M', desc: 'Male'},
        {id: 'F', desc: 'Female'},
      ],
      purposes_of_entry: {
        'Tour': 'Tour', 
        'Visit': 'Visit',
        'Business': 'Business',
        'Medical': 'Medical treatment',
        'Meeting': 'Meeting',
        'Event': 'Event',
        'Games': 'Games/Match/Competition',
        'Other': 'Other'
      },
      job_categories: {
        'Unemployed': 'Unemployed',
        'Selfemployed': 'Self-employed',
        'Agricultural': 'Agricultural and livestock industries',
        'Office': 'Office worker',
        'Public': 'Public officer',
        'Student': 'Student',
        'Housewife': 'Housewife',
        'Etc': 'Etc'
      },
      acq_relations: {
        'family': 'Family',
        'relative': 'Relatives',
        'friends': 'Friends',
        'business': 'Business',
        'etc': 'Etc'
      },
      acc_array: {
        'family': 'Family',
        'relative': 'Relative',
        'friends': 'Friends',
        'acquaintance': 'Acquaintance',
        'business': 'Business',
        'group_tour': 'Group Tour',
        'etc': 'Etc'
      },
      visited_arr: {
        'none': 'None',
        '1_2_times': '1~2 times',
        '3_4_times': '3~4 times',
        '5_and_more_times': 'More than 5 times'
      },
      income_arr: {
        '200': '~ 200$',
        '200-700': '200$ ~ 700$',
        '700-1300': '700$ ~ 1,300$',
        '1300-2100': '1,300$ ~ 2,100$',
        '2100': '2,100$ ~'
      }
    }),
    watch: {
    },
    created() {
        const currentYear = (new Date()).getFullYear();
        const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1}, (_, i) => start + (i * step));
        this.years = range(currentYear, currentYear - 100, -1); 
        this.years2 = range(currentYear, currentYear + 100, 1); 
        let years_all = range(currentYear - 100, currentYear + 100, 1);
        this.yearsAll = years_all.sort(function(a, b) {
        return b - a;
    });
    },
    methods: {
        setLanguage(lang){
          const languageNames = new Intl.DisplayNames(['en'], {
            type: 'language'
          });
          return languageNames.of(lang)
        },
        getCountry(code) {
          let res = 'None'
          if(code){
          this.$store.state.countries.forEach(item => {
            if(item.code.toLowerCase() == code.toLowerCase() || item.code_3.toLowerCase() == code.toLowerCase()){
              res = item.country
            }
          });
          }
          return res;
        },
        edit(prop, val){
          this.edit_val = val;
          this.edit_prop = prop
        },
        editDate(prop, val){
          this.edit_year = parseFloat(val[0]);
          this.edit_month = val[1];
          this.edit_day = val[2];
          this.edit_prop_date = prop
        },
        updateData(){
          if(this.$refs.form.validate()){
          if(this.edit_prop_date){
              this.data[this.edit_prop_date[0]] = this.edit_year;
              this.data[this.edit_prop_date[1]] = this.edit_month;
              this.data[this.edit_prop_date[2]] = this.edit_day;
          }
          else if(this.edit_prop + '_text' in this.data){
            let country = this.getCountry(this.edit_val);
            if(country != 'None'){
              // console.log("updated the" + this.edit_prop + '_text')
              this.data[this.edit_prop] = this.edit_val
              this.data[this.edit_prop + '_text'] = country;
            }
          }
          else{
          this.data[this.edit_prop] = this.edit_val
          }
          let data = {
            token: this.data.token,
            data: JSON.stringify(this.data)
          };
          this.axiosCall("/applications/updateData", "POST", data).then((res) => {
            if (res.data.status == 200) {
              this.showToast("Application field [" + this.edit_prop + "] was successfully updated.", 'success')
              this.cancelEdit();
            }
            else{
              this.showToast(res.data.msg, 'error')
            }
          });
          }
        },
        cancelEdit(){
          this.edit_val = null;
          this.edit_prop = null;
          this.edit_day = null;
          this.edit_month = null;
          this.edit_year = null;
          this.edit_prop_date = null;
        }
    },
    mounted(){
    }
  }
</script>
<style scoped>
.view-main-title {
  color: #4d6585 !important;
  font-weight: 500;
  font-size: 16px;
}
.view-title {
  color: #4d6585 !important;
  font-weight: 500;
}
.chip-status-1{
  background-color: #ebeef1 !important;
  color: #616f7e !important;
}
.chip-status-2{
  background-color: #F0FFF8 !important;
  color: #18AB56 !important;
}
</style>