<template>
  <div>
    <v-container fluid class="content">
      <v-row align="center" justify="center">
      <v-col align="center" cols="12" md="6">
        <v-row>
          <v-col cols="12" class="mb-10">
            <v-img src="../../assets/img/vip-global-logo.svg" width="250"></v-img>
          </v-col>
        </v-row>
        <v-card
          max-width="500"
          class="rounded-card"
          align="start" 
        >
                  <v-form ref="Formref">
                    <v-row class="mx-5 pt-12">
                      <v-col
                      cols="12"
                      class="pa-0 px-4 mb-2"
                      >
                      <div class="text-h5" align="center"><strong>Forgot Password?</strong></div>
                      <div class="text-center mt-3 mb-5">
                          Enter the email address you used when you joined and we'll send you instructions to reset your password.
                      </div>
                      </v-col>
                        <v-col v-if="showEmail == true"
                            cols="12"
                            class="pa-0 px-4 mt-3"
                            >
                            <v-text-field
                              color="#377dff"
                                class="font-size-14"
                                v-model="email"
                                :rules="[formRules.required, formRules.email]"
                                label="Email address"
                                height="42"
                                outlined
                                dense
                            ></v-text-field>
                        </v-col>

                      <v-col cols="12" v-if="showOTP == true">
                        <div>
                          An OTP (One Time Passcode) has been sent to your email address. Check your email and encode your OTP on the input below.
                        </div>
                        <div class="otp-div mt-5">
                          <v-otp-input
                                v-model="otp"
                                ref="otpInput"
                                input-classes="otp-input"
                                separator=""
                                :num-inputs="6"
                                :should-auto-focus="true"
                                :is-input-num="true"
                                @on-complete="handleOnComplete"
                            />
                        </div>
                      </v-col>

                        <v-col v-if="showPass == true"
                            cols="12"
                            class="pa-0 px-4 mt-2"
                            >
                            <v-text-field
                                color="#239FAB"
                                class="font-size-14 rounded-lg"
                                v-model="password"
                                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                :rules="[formRules.required]"
                                :type="show1 ? 'text' : 'password'"
                                name="input-10-1"
                                label="New Password"
                                dense
                                outlined
                                @keyup.enter="dologin()"
                                @click:append="show1 = !show1"
                            ></v-text-field>
                        </v-col>
                        <v-col v-if="showPass == true"
                            cols="12"
                            class="pa-0 px-4 mt-2"
                            >
                            <v-text-field
                                color="#239FAB"
                                class="font-size-14 rounded-lg"
                                v-model="confirm_pass"
                                :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                                :rules="[formRules.required]"
                                :type="show2 ? 'text' : 'password'"
                                name="input-10-1"
                                label="Confirm Password"
                                dense
                                outlined
                                @keyup.enter="dologin()"
                                @click:append="show2 = !show2"
                            ></v-text-field>
                        </v-col>
                        <v-col
                            cols="12"
                            class="mb-5">

                            <div class="d-flex justify-center" v-if="showEmail == true">
                                <v-btn
                                  block
                                  large
                                  depressed
                                  color="#377dff"
                                  class="white--text py-2 font-size-15 rounded-md"
                                  :loading="isLoading"
                                  @click="checkEmail()">Send Reset Instructions</v-btn>
                            </div>

                            <div class="d-flex justify-center" v-if="showOTP == true">
                                <v-btn
                                  block
                                  depressed
                                  color="#123E4D"
                                  class="white--text py-2 font-size-15 rounded-lg"
                                  :loading="isLoading"
                                  @click="checkOTP()">Verify OTP</v-btn>
                            </div>

                            <div class="d-flex justify-center" v-if="showPass == true">
                                <v-btn
                                  block
                                  depressed
                                  color="#123E4D"
                                  class="white--text py-2 font-size-15 rounded-lg"
                                  :loading="isLoading"
                                  @click="changePass()">Reset Password</v-btn>
                            </div>
                        </v-col>
                        <v-col
                            cols="12"
                            class="pa-0 px-4 mb-5"
                            >
                            <div class="d-flex justify-center pb-3 font-size-14">
                                <router-link class="pl-2"  to ="/login">
                                  Back to Sign In</router-link>
                            </div>
                        </v-col>
                    </v-row>
            </v-form>
        </v-card>
      </v-col>
      </v-row>
  </v-container>
  <fade-away-message-component
      displayType="variation2"
      v-model="fadeAwayMessage.show"
      :message="fadeAwayMessage.message"
      :header="fadeAwayMessage.header"
      :top="fadeAwayMessage.top"
      :type="fadeAwayMessage.type"
    ></fade-away-message-component>
  </div>
</template>

<script>
  export default {
    name: 'login',
    mounted () {
      if(this.$store.state.user){
      if(!this.$store.state.user.user_type_id){
        //this.deleteToken();
        setTimeout(function(){  location.reload(); }, 0);
      }
      }
    },
    data: () => ({
      isLoading: false,
      user_id: '',
      email: '',
      password: '',
      confirm_pass: '',
      otp: '',
      showEmail: true,
      showOTP: false,
      showPass: false,
      show1: false,
      show2: false,
      fadeAwayMessage: {
      show: false,
      type: "success",
      header: "Successfully Added!",
      message: "",
      top: 10,
    },
    }),
    methods: {
        checkEmail(){
          if(this.$refs.Formref.validate()){
             this.isLoading = true
             this.axiosCall("/auth/sendOTP/" + this.email, "GET").then((res) => {
                 if(res.data.status == 200){
                     this.isLoading = false
                     this.showEmail = false,
                     this.showPass = false,
                     this.showOTP = true
                     this.email = res.data.user
                 }
                 else if(res.data.status == 404){ 
                  this.isLoading = false
                    this.fadeAwayMessage.show = true;
                    this.fadeAwayMessage.type = 'error';
                    this.fadeAwayMessage.message = 'Email Not Registered';
                    this.fadeAwayMessage.header = 'System Message';
                 }
             })
          }
        },
        checkOTP(){
          let formData = { email: this.email, otp: this.otp }
          this.axiosCall("/auth/compareOTP", "POST", formData).then((res) => {
              if(res.data.status == 200){
                this.isLoading = false
                     this.showEmail = false
                     this.showPass = true
                     this.showOTP = false
                     this.email = res.data.user
              }
              else{
                this.isLoading = false
                    this.fadeAwayMessage.show = true;
                    this.fadeAwayMessage.type = 'error';
                    this.fadeAwayMessage.message = 'OTP not match';
                    this.fadeAwayMessage.header = 'System Message';
              }
          })
        },
        changePass(){
           if(this.password == this.confirm_pass){
               let formData = { email: this.email, password: this.password }
               this.axiosCall("/auth/changePass", "POST", formData).then((res) => {
                    if(res.data.status == 200){
                      this.fadeAwayMessage.show = true;
                      this.fadeAwayMessage.type = 'success';
                      this.fadeAwayMessage.message = 'Password changed successfully';
                      this.fadeAwayMessage.header = 'System Message';
                      this.$router.push({path: '/login'})
                    }
                    else{
                      this.fadeAwayMessage.show = true;
                      this.fadeAwayMessage.type = 'error';
                      this.fadeAwayMessage.message = 'Error changing password';
                      this.fadeAwayMessage.header = 'System Message';
                    } 
               })
           }
           else{
              this.fadeAwayMessage.show = true;
              this.fadeAwayMessage.type = 'error';
              this.fadeAwayMessage.message = 'Password not match';
              this.fadeAwayMessage.header = 'System Message';
           }
        },
        handleOnComplete(value) {
            this.otp = value;
        },
        handleClearInput() {
            this.$refs.otpInput.clearInput();
        },
        // dologin () {
        //   if(this.$refs.Formref.validate()){
        //     this.isLoading = true;
        //      let data = new FormData();
        //         data.append('email', this.email);
        //         data.append('password', this.password);
        //           this.axiosCall(
        //                 '/auth/login',
        //                 'POST',
        //                 data
        //             )
        //             .then((res) => {
        //               this.isLoading = false;
        //                 if (res.data.status){
        //                 localStorage.setItem("token", res.data.data.data.user.token);
        //                 location.reload();
        //                 }
        //                 else{
        //                   if(res.data.message === 'otp'){
        //                     this.$router.replace({path: '/register/' + this.email});
        //                   }else{
        //                 this.fadeAwayMessage.show = true;
        //                 this.fadeAwayMessage.type = 'error';
        //                 this.fadeAwayMessage.message = res.data.message;
        //                 this.fadeAwayMessage.header = 'System Message';
        //                   }
        //                 }
        //             })
        //     }
        // }
    },
    
  }
</script>

<style scoped>
.otp-div {
  display: flex;
  justify-content: center;
}
.content {
    /* background: url("../../assets/img/dsh-bg.png") ; */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 50px;
}
.title {
  color: #fff;
  font-size: 35px !important;
  position: relative;
  font-weight: bold;
  line-height: 40px;
}
.title .underline {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}
.title .underline .u1 {
  background: #fff;
  width: 70%;
  height: 5px;
  margin: 0 5px;
}
.title .underline .u2 {
  background: #fff;
  width: 5%;
  height: 5px;
  margin: 0 5px;
}
.lp-img {
  -webkit-filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.09));
  filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.09));
  border-radius: 50%;
  background: #fff;
}
.rounded-card {
  border-radius: 10px;
}

</style>
