<template>
  <div class="pa-5">
    <v-card class="card-style rounded-1" >
    <v-row class="ma-2">
      <v-col cols="12" md="9" class="flex-items">
        <v-btn
          color="#38435a"
          fab
          small
          @click="add"
          class="elevation-0 mr-3"
        ><v-icon color="white">mdi-plus</v-icon></v-btn>
        <h2>Transaction Ledger</h2>
      </v-col>
      <v-col cols="12" md="3">
      <v-text-field
          v-model="search"
          prepend-inner-icon="search"
          label="Search Transactions"
          single-line
          hide-details
          color="#38435a"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-card class="mb-1 dt-container" elevation="0" outlined>
    <v-data-table
      :loading="loading"
       :headers="headers"
        :items="data"
        item-key="id"
        :options.sync="options"
        hide-default-footer
    >
    <template v-slot:[`item.cretedAtTimestamp`]="{ item }">
      <span class="flex-items">
        <span>{{formatTimestamp(item.cretedAtTimestamp)}}</span>
        
      </span>
    </template>
    <template v-slot:[`item.amount`]="{ item }">
      <span class="flex-items">
        <span style="color: #96030b;" class="text-no-wrap">{{formatNumber(item.amountSent)}} <small>{{item.currencySent}}</small></span>
        <v-icon>mdi-arrow-right</v-icon>
        <span style="color: #176c0d;" class="text-no-wrap">{{formatNumber(item.amountReceived)}} <small>{{item.currencyReceived}}</small></span>
      </span>
    </template>
    <template v-slot:[`item.sender`]="{ item }">
        <span>{{item.sender.name}}</span>
        <div style="color: #96030b;" class="text-no-wrap"><small>{{item.sender.currency}} | {{item.sender.bank}}</small></div>
    </template>
    <template v-slot:[`item.beneficiary`]="{ item }">
        <span>{{item.beneficiary.name}}</span>
        <div style="color: #176c0d;" class="text-no-wrap"><small>{{item.beneficiary.currency}} | {{item.beneficiary.bank}}</small></div>
    </template>
    <template v-slot:[`item.status`]="{ item }">
        <v-chip
        v-if="item.status == 'completed'"
         class="chip-status-1 rounded px-2 py-1 mr-2 my-1 font-weight-bolder text-wrap"
        small
        >
        <span>Completed</span>
        </v-chip>
        <v-chip
        v-if="item.status == 'in_progress'"
         class="chip-status-2 rounded px-2 py-1 mr-2 my-1 font-weight-bolder text-wrap"
        small
        >
        <span class="text-no-wrap">In Progress</span>
        </v-chip>
        <v-chip
        v-if="item.status == 'cancelled'"
         class="chip-status-3 rounded px-2 py-1 mr-2 my-1 font-weight-bolder text-wrap"
        small
        >
        <span>Cancelled</span>
        </v-chip>
    </template>
    <template v-slot:[`item.reference`]="{ item }">
      <span class="d-flex">
        <span class="text-caption font-weight-bold">{{item.reference}}</span>
        <v-tooltip bottom v-if="item.notes">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="#f6cb99"
              dark
              class="ml-1"
              small
              v-bind="attrs"
              v-on="on"
            >
              mdi-file-document-outline
            </v-icon>
          </template>
          <span>{{item.notes}}</span>
        </v-tooltip>
      </span>   
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <span class="align-center">
        <v-btn
          v-if="$store.state.user.role == 'admin'"
          outlined
          small
          class="mr-2"
          color="#166ecf"
          @click="edit(item)"
        >
        <v-icon class="mr-1">mdi-square-edit-outline</v-icon>
        Edit
        </v-btn>
      </span>   
    </template>
    </v-data-table>
    </v-card>
    <v-row class="ma-2" align="center" justify="center">
    <v-col cols="auto" class="flex-items">
        <span class="px-2">Row Per Page: </span>
        <span>
          <v-select
            dense
            outlined
            hide-details
            :value="options.itemsPerPage"
            style="max-width: 90px"
            class="rounded-lg"
            @change="options.itemsPerPage = parseInt($event, 10)"
            :items="perPageChoices"
          >
          </v-select>
        </span>
    </v-col>
    <v-col cols="auto" class="mx-auto text-truncate" no-gutters>
    Showing {{ data.length > 0 ? ((paginationData.currentPage - 1) * options.itemsPerPage) + 1 : 0 }} to {{ data.length > 0 ? ((paginationData.currentPage - 1) * options.itemsPerPage) + data.length : 0 }} of {{ paginationData.count }} entries
    </v-col>
    <v-col cols="auto">
        <v-pagination
            v-model="options.page"
            class="rounded-lg"
            :total-visible="7"
            color="#38435a"
            :length="paginationData.lastPage">
        </v-pagination>
    </v-col>
      </v-row>
    </v-card>
    <v-dialog
      v-model="dialogConfirmation"
      width="520"
      persistent
    >
      <v-card>
        <v-card-title class="text-subtitle-1">
          <strong>Confirmation</strong>
        </v-card-title>

        <v-card-text>
            <div class="subtitle-1">
              Are you sure you want to <strong>DELETE</strong> this transaction?
            </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            outlined
            class="mr-2"
            depressed
            @click="dialogConfirmation = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="red darken-3"
            class="white--text"
            depressed
            @click="confirmDelete()"
            :loading="loadingConfirm"
          >
            Yes, Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogForm"
      width="800"
      scrollable
      persistent
    >
      <v-card>
        <v-card-title>
          <strong v-if="action == 'add'">Add New Transaction</strong>
          <strong v-else-if="action == 'edit'">Update Transaction</strong>
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="closeFormDialog()"
          >
          <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pt-2" style="max-height: 900px;">
            <v-form ref="formRef">
              <v-row>
                <v-col cols="12" md="6">
                  <v-row no-gutters>
                    <v-col cols="12">
                      <div class="px-0 pb-1 text-h6">Payer</div>
                      <v-autocomplete
                        v-model="formdata.senderId"
                        color="grey"
                        :rules="[formRules.required]"
                        height="45"
                        :items="accounts_list"
                        placeholder="Select Payer"
                        item-text="description"
                        item-value="id"
                        outlined
                        @change="changeAccountId()"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12">
                      <span class="flex-items">
                      <v-text-field
                        v-model="formdata.amountSent"
                        color="grey"
                        class="ml-1 rounded-r-0"
                        :rules="[formRules.numberRequired]"
                        @input="changeAmountSent()"
                        placeholder="Sending Amount *"
                        height="45"
                        outlined
                        ></v-text-field>
                      <v-autocomplete
                        v-model="formdata.currencySent"
                        color="grey"
                        :rules="[formRules.required]"
                        height="45"
                        :items="currency_list"
                        item-text="description"
                        item-value="id"
                        style="max-width: 100px"
                        hide-details
                        class="rounded-l-0 currency-margin"
                        outlined
                      ></v-autocomplete>
                      </span>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                          v-model="formdata.reference"
                          color="grey"
                          class="ml-1"
                          :rules="[formRules.required]"
                          height="45"
                          outlined
                          ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-divider vertical class="my-3"></v-divider>
                <v-col cols="12" md="6">
                  <v-row no-gutters>
                    <v-col cols="12">
                    <div class="px-0 pb-1 text-h6">Beneficiary</div>
                    <v-autocomplete
                      v-model="formdata.beneficiaryId"
                      color="grey"
                      :rules="[formRules.required]"
                      height="45"
                      placeholder="Select Beneficiary"
                      :items="accounts_list"
                      item-text="description"
                      item-value="id"
                      outlined
                      @change="changeAccountId()"
                    ></v-autocomplete>
                    </v-col>
                    <v-col cols="12">
                      <span class="flex-items">
                        <v-text-field
                          v-model="formdata.amountReceived"
                          color="grey"
                          class="ml-1 rounded-r-0"
                          :rules="[formRules.numberRequired]"
                          placeholder="Receiving Amount *"
                          height="45"
                          outlined
                          ></v-text-field>
                        <v-autocomplete
                          v-model="formdata.currencyReceived"
                          color="grey"
                          :rules="[formRules.required]"
                          height="45"
                          :items="currency_list"
                          item-text="description"
                          item-value="id"
                          style="max-width: 100px"
                          hide-details
                          class="rounded-l-0 currency-margin"
                          outlined
                        ></v-autocomplete>
                      </span>
                    </v-col>
                    <v-col cols="12">
                      <v-textarea
                        v-model="formdata.notes"
                        color="grey"
                        class="ml-1"
                        placeholder="Notes"
                        rows="3"
                        outlined
                        ></v-textarea>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" class="pt-0 pb-5">
                  <v-btn-toggle
                    v-model="formdata.status"
                    class="d-flex justify-space-between w-100"
                    mandatory
                    dense
                  >
                    <v-btn value="planned" color="#09a5be" outlined>
                      <span>Planned</span>
                    </v-btn>

                    <v-btn value="in_progress" color="#f5ca99" outlined>
                      <span>In Progress</span>
                    </v-btn>

                    <v-btn value="completed"  color="#00c9a7" outlined>
                      <span>Completed</span>
                    </v-btn>

                    <v-btn value="cancelled" color="#71869d" outlined>
                      <span>Cancelled</span>
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
            </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="pa-3">
          <v-btn
            color="red darken-3"
            class="white--text"
            depressed
            outlined
            @click="deleteLedger()"
            v-if="formdata && formdata.status == 'cancelled'"
          >
          <v-icon class="mr-1">mdi-delete</v-icon>
            Delete
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            outlined
            class="mr-2"
            depressed
            @click="closeFormDialog()"
          >
            Cancel
          </v-btn>
          <v-btn
            color="#166ecf"
            class="white--text mr-3"
            depressed
            @click="confirmUpdate()"
            v-if="action == 'edit'"
            :loading="loadingForm"
          >
          <v-icon class="mr-1">mdi-square-edit-outline</v-icon>
            Update
          </v-btn>
          <v-btn
            color="#00a34e"
            class="white--text mr-3"
            depressed
            @click="confirmAdd()"
            v-else-if="action == 'add'"
            :loading="loadingForm"
          >
          <v-icon class="mr-1">mdi-check-circle</v-icon>
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</div>
</template>
<script>
  export default {
    components: {
    },
    data: () => ({
      search: '',
      headers: [
          { text: 'DATE/TIME [EST]', value: 'cretedAtTimestamp' },
          { text: 'STATUS', value: 'status'},
          { text: 'AMOUNT', value: 'amount'},
          { text: 'PAYER', value: 'sender'},
          { text: 'BENEFICIARY', value: 'beneficiary'},
          { text: 'REF', value: 'reference', width: 160},
          { text: 'ACTIONS', value: 'actions', align: 'center', sortable: false, },
        ],
      data: [],
      perPageChoices: [
          {text:'5' , value: 5},
          {text:'10' , value: 10},
          {text:'20' , value: 20},
          {text:'50' , value: 50},
          {text:'100' , value: 100},
          {text:'250' , value: 250},
          {text:'500' , value: 500},
      ],
      options: {},
      paginationData: {},
      loading: false,
      dialogConfirmation: false,
      loadingConfirm: false,
      edit_item: {},
      dialogForm: false,
      formdata: {},
      action: '',
      loadingForm: false,
      accounts_list: [],
      currency_list: [],
    }),
    watch: {
      options: {
        handler () {
         this.initialize();
        },
        deep: true,
      },
      search: {
        handler () {
          setTimeout(() => {
            this.options.page = 1;
            this.initialize();
          }, 600);
        }
      },
    },
    mounted() {
      this.getAccountsList();
      this.getCurrencyList();
    },
    methods: {
      initialize (){
        this.loading = true;
        let page = this.options.page;
        let itemPerPage = this.options.itemsPerPage;
        let sortBy = this.options.sortBy.length > 0 ? this.options.sortBy[0] : '';
        let order = this.options.sortBy.length > 0 ? this.options.sortDesc[0] ? 'desc' : 'asc' : '';
        let search = this.search;
        this.axiosCall('/banking-ledger/getLedgers?page=' + page + '&itemPerPage=' + itemPerPage + '&sortBy=' + sortBy + '&order=' + order + '&searchKey=' + search, 'GET').then((res) => {
              this.loading = false
              this.data = res.data.data
              this.paginationData = res.data.pagination;
          })
      },
      getAccountsList(){
        this.axiosCall('/banking-accounts/getAccountsList', 'GET').then((res) => {
            this.accounts_list = res.data;
          })
      },
      getCurrencyList(){
        this.axiosCall('/banking-accounts/getCurrencyList', 'GET').then((res) => {
            this.currency_list = res.data;
          })
      },
       closeFormDialog(){
        this.$refs.formRef.resetValidation();
        this.dialogForm = false
       },
       async edit(item){
        this.action = 'edit';
        this.formdata = JSON.parse(JSON.stringify(item));
        this.dialogForm = true
       },
       async add(){
        this.action = 'add';
        this.account_available = null;
        this.formdata = {
          currencySent: 'USD',
          currencyReceived: 'USD',
          reference: 'AS PER INVOICE'
        };
        this.dialogForm = true
       },
       async deleteLedger(){
        this.dialogConfirmation = true
       },
       confirmAdd(){
        if(this.$refs.formRef.validate()){
        this.loadingForm = true;
        let data = {
          senderId: this.formdata.senderId,
          beneficiaryId: this.formdata.beneficiaryId,
          amountSent: this.formdata.amountSent,
          currencySent: this.formdata.currencySent,
          amountReceived: this.formdata.amountReceived,
          currencyReceived: this.formdata.currencyReceived,
          reference: this.formdata.reference,
          status: this.formdata.status,
          notes: this.formdata.notes,
        };
        this.axiosCall("/banking-ledger/addLedger", "POST", data).then((res) => {
          if (res.data.status == 201) {
            this.$refs.formRef.reset();
            this.closeFormDialog();
            this.initialize()
            this.showToast(res.data.msg, 'success')
          }
          else{
            this.showToast(res.data.msg, 'error')
          }
          this.loadingForm = false;
        });
        }
       },
       confirmUpdate(){
        if(this.$refs.formRef.validate()){
        this.loadingForm = true;
        let data = {
          token: this.formdata.token,
          senderId: this.formdata.senderId,
          beneficiaryId: this.formdata.beneficiaryId,
          amountSent: this.formdata.amountSent,
          currencySent: this.formdata.currencySent,
          amountReceived: this.formdata.amountReceived,
          currencyReceived: this.formdata.currencyReceived,
          reference: this.formdata.reference,
          status: this.formdata.status,
          notes: this.formdata.notes,
        };
        this.axiosCall("/banking-ledger/updateLedger", "POST", data).then((res) => {
          if (res.data.status == 200) {
            this.$refs.formRef.reset();
            this.closeFormDialog();
            this.initialize()
            this.showToast(res.data.msg, 'success')
          }
          else{
            this.showToast(res.data.msg, 'error')
          }
          this.loadingForm = false;
        });
        }
       },
       confirmDelete(){
        if(this.formdata.token){
        this.loadingConfirm = true;
        let data = {
          token: this.formdata.token,
        };
        this.axiosCall("/banking-ledger/deleteLedger", "POST", data).then((res) => {
          if (res.data.status == 200) {
            this.$refs.formRef.reset();
            this.closeFormDialog();
            this.initialize()
            this.showToast(res.data.msg, 'success')
            this.dialogConfirmation = false;
          }
          else{
            this.showToast(res.data.msg, 'error')
          }
          this.loadingConfirm = false;
        });
        }
       },
       changeAmountSent(){
        this.formdata.amountReceived = this.formdata.amountSent;
       },
       changeAccountId(){
        let sender = this.accounts_list.filter(account => account.id == this.formdata.senderId);
          let beneficiary = this.accounts_list.filter(account => account.id == this.formdata.beneficiaryId);
          this.formdata.reference = this.formdata.senderId && this.formdata.beneficiaryId && sender && beneficiary && sender[0].name == beneficiary[0].name ? "OWN ACCOUNT TRANSFER" : "AS PER INVOICE";
          if(this.formdata.senderId){
          this.accounts_list.forEach(item => {
            if(item.id == this.formdata.senderId){
              this.formdata.currencySent = item.currency;
              return;
            }
          });
          }
          if(this.formdata.beneficiaryId){
          this.accounts_list.forEach(item => {
            if(item.id == this.formdata.beneficiaryId){
              this.formdata.currencyReceived = item.currency;
              return;
            }
          });
          }
       }
    }
  }
</script>
<style scoped>
.chip-status-1{
  background-color: #d7f7c2 !important;
  color: #2f8547 !important;
}
.chip-status-2{
  background-color: #cff5f6 !important;
  color: #1066c5 !important;
}
.chip-status-3{
  background-color: #ebeef1 !important;
  color: #616f7e !important;
}
.chip-status-4{
  background-color: #fcedb9 !important;
  color: #b02c00 !important;
}
.chip-status-5{
  background-color: #ffe7f2 !important;
  color: #c4093c !important;
}
.chip-status-6{
  background-color: #F0FFF8 !important;
  color: #18AB56 !important;
}
.chip-status-7{
  background-color: #fbe996 !important;
  color: #413a29 !important;
}
.currency-margin{
  margin-bottom: 30px !important;
  border-left: none !important;
}
</style>