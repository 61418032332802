import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
var ls = new SecureLS({ isCompression: false });

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null,
    countries: [
        {
          code: "AF",
          country: "Afghanistan",
          code_3: "AFG"
        },
        {
          code: "AX",
          country: "Aland Islands",
          code_3: "ALA"
        },
        {
          code: "AL",
          country: "Albania",
          code_3: "ALB"
        },
        {
          code: "DZ",
          country: "Algeria",
          code_3: "DZA"
        },
        {
          code: "AS",
          country: "American Samoa",
          code_3: "ASM"
        },
        {
          code: "AD",
          country: "Andorra",
          code_3: "AND"
        },
        {
          code: "AO",
          country: "Angola",
          code_3: "AGO"
        },
        {
          code: "AI",
          country: "Anguilla",
          code_3: "AIA"
        },
        {
          code: "AQ",
          country: "Antarctica",
          code_3: "ATA"
        },
        {
          code: "AG",
          country: "Antigua and Barbuda",
          code_3: "ATG"
        },
        {
          code: "AR",
          country: "Argentina",
          code_3: "ARG"
        },
        {
          code: "AM",
          country: "Armenia",
          code_3: "ARM"
        },
        {
          code: "AW",
          country: "Aruba",
          code_3: "ABW"
        },
        {
          code: "AU",
          country: "Australia",
          code_3: "AUS"
        },
        {
          code: "AT",
          country: "Austria",
          code_3: "AUT"
        },
        {
          code: "AZ",
          country: "Azerbaijan",
          code_3: "AZE"
        },
        {
          code: "BS",
          country: "Bahamas",
          code_3: "BHS"
        },
        {
          code: "BH",
          country: "Bahrain",
          code_3: "BHR"
        },
        {
          code: "BD",
          country: "Bangladesh",
          code_3: "BGD"
        },
        {
          code: "BB",
          country: "Barbados",
          code_3: "BRB"
        },
        {
          code: "BY",
          country: "Belarus",
          code_3: "BLR"
        },
        {
          code: "BE",
          country: "Belgium",
          code_3: "BEL"
        },
        {
          code: "BZ",
          country: "Belize",
          code_3: "BLZ"
        },
        {
          code: "BJ",
          country: "Benin",
          code_3: "BEN"
        },
        {
          code: "BM",
          country: "Bermuda",
          code_3: "BMU"
        },
        {
          code: "BT",
          country: "Bhutan",
          code_3: "BTN"
        },
        {
          code: "BO",
          country: "Bolivia",
          code_3: "BOL"
        },
        {
          code: "BQ",
          country: "Bonaire",
          code_3: "BES"
        },
        {
          code: "BA",
          country: "Bosnia and Herzegovina",
          code_3: "BIH"
        },
        {
          code: "BW",
          country: "Botswana",
          code_3: "BWA"
        },
        {
          code: "BV",
          country: "Bouvet Island",
          code_3: "BVT"
        },
        {
          code: "BR",
          country: "Brazil",
          code_3: "BRA"
        },
        {
          code: "IO",
          country: "British Indian Ocean Territory",
          code_3: "IOT"
        },
        {
          code: "BN",
          country: "Brunei",
          code_3: "BRN"
        },
        {
          code: "BG",
          country: "Bulgaria",
          code_3: "BGR"
        },
        {
          code: "BF",
          country: "Burkina Faso",
          code_3: "BFA"
        },
        {
          code: "BU",
          country: "Burma",
          code_3: "MMR"
        },
        {
          code: "BI",
          country: "Burundi",
          code_3: "BDI"
        },
        {
          code: "KH",
          country: "Cambodia",
          code_3: "KHM"
        },
        {
          code: "CM",
          country: "Cameroon",
          code_3: "CMR"
        },
        {
          code: "CA",
          country: "Canada",
          code_3: "CAN"
        },
        {
          code: "CT",
          country: "Canton and Enderbury Islands",
          code_3: "CYN"
        },
        {
          code: "CV",
          country: "Cape Verde",
          code_3: "CPV"
        },
        {
          code: "KY",
          country: "Cayman Islands",
          code_3: "CYM"
        },
        {
          code: "CF",
          country: "Central African Republic",
          code_3: "CAF"
        },
        {
          code: "TD",
          country: "Chad",
          code_3: "TCD"
        },
        {
          code: "CL",
          country: "Chile",
          code_3: "CHL"
        },
        {
          code: "CN",
          country: "China",
          code_3: "CHN"
        },
        {
          code: "CNHK",
          country: "China P. R.(Hong Kong)",
          code_3: "CNHK"
        },
        {
          code: "CX",
          country: "Christmas Island",
          code_3: "CXR"
        },
        {
          code: "CC",
          country: "Cocos — KEELING Islands",
          code_3: "CCK"
        },
        {
          code: "CO",
          country: "Colombia",
          code_3: "COL"
        },
        {
          code: "KM",
          country: "Comoros",
          code_3: "COM"
        },
        {
          code: "CD",
          country: "Congo",
          code_3: "COD"
        },
        {
          code: "CG",
          country: "Congo",
          code_3: "COG"
        },
        {
          code: "CK",
          country: "Cook Islands",
          code_3: "COK"
        },
        {
          code: "CR",
          country: "Costa Rica",
          code_3: "CRI"
        },
        {
          code: "CI",
          country: "Cote D'ivoire",
          code_3: "CIV"
        },
        {
          code: "HR",
          country: "Croatia",
          code_3: "HRV"
        },
        {
          code: "CU",
          country: "Cuba",
          code_3: "CUB"
        },
        {
          code: "CW",
          country: "Curacao",
          code_3: "CUW"
        },
        {
          code: "CY",
          country: "Cyprus",
          code_3: "CYP"
        },
        {
          code: "CZ",
          country: "Czech Republic",
          code_3: "CZE"
        },
        {
          code: "YD",
          country: "Democratic Yemen",
          code_3: "YEM"
        },
        {
          code: "DK",
          country: "Denmark",
          code_3: "DNK"
        },
        {
          code: "DJ",
          country: "Djibouti",
          code_3: "DJI"
        },
        {
          code: "DM",
          country: "Dominica",
          code_3: "DMA"
        },
        {
          code: "DO",
          country: "Dominican Republic",
          code_3: "DOM"
        },
        {
          code: "NQ",
          country: "Dronning Maud Land",
          code_3: "DML"
        },
        {
          code: "TP",
          country: "East Timor",
          code_3: "TLS"
        },
        {
          code: "EC",
          country: "Ecuador",
          code_3: "ECU"
        },
        {
          code: "EG",
          country: "Egypt",
          code_3: "EGY"
        },
        {
          code: "SV",
          country: "El Salvador",
          code_3: "SLV"
        },
        {
          code: "GQ",
          country: "Equatorial Guinea",
          code_3: "GNQ"
        },
        {
          code: "ER",
          country: "Eritrea",
          code_3: "ERI"
        },
        {
          code: "EE",
          country: "Estonia",
          code_3: "EST"
        },
        {
          code: "ET",
          country: "Ethiopia",
          code_3: "ETH"
        },
        {
          code: "FK",
          country: "Falkland Islands",
          code_3: "FLK"
        },
        {
          code: "FO",
          country: "Faroe Islands",
          code_3: "FRO"
        },
        {
          code: "FJ",
          country: "Fiji",
          code_3: "FJI"
        },
        {
          code: "FI",
          country: "Finland",
          code_3: "FIN"
        },
        {
          code: "FR",
          country: "France",
          code_3: "FRA"
        },
        {
          code: "FX",
          country: "France Metropolitan",
          code_3: "FXX"
        },
        {
          code: "GF",
          country: "French Guiana",
          code_3: "GUF"
        },
        {
          code: "PF",
          country: "French Polynesia",
          code_3: "PYF"
        },
        {
          code: "TF",
          country: "French Southern Territories",
          code_3: "ATF"
        },
        {
          code: "GA",
          country: "Gabon",
          code_3: "GAB"
        },
        {
          code: "GM",
          country: "Gambia",
          code_3: "GMB"
        },
        {
          code: "GE",
          country: "Georgia",
          code_3: "GEO"
        },
        {
          code: "DE",
          country: "Germany",
          code_3: "DEU"
        },
        {
          code: "GH",
          country: "Ghana",
          code_3: "GHA"
        },
        {
          code: "GI",
          country: "Gibraltar",
          code_3: "GIB"
        },
        {
          code: "GR",
          country: "Greece",
          code_3: "GRC"
        },
        {
          code: "GL",
          country: "Greenland",
          code_3: "GRL"
        },
        {
          code: "GD",
          country: "Grenada",
          code_3: "GRD"
        },
        {
          code: "GP",
          country: "Guadeloupe",
          code_3: "GLP"
        },
        {
          code: "GU",
          country: "Guam",
          code_3: "GUM"
        },
        {
          code: "GT",
          country: "Guatemala",
          code_3: "GTM"
        },
        {
          code: "GG",
          country: "Guernsey",
          code_3: "GGY"
        },
        {
          code: "GN",
          country: "Guinea",
          code_3: "GIN"
        },
        {
          code: "GW",
          country: "Guinea-bissau",
          code_3: "GNB"
        },
        {
          code: "GY",
          country: "Guyana",
          code_3: "GUY"
        },
        {
          code: "HT",
          country: "Haiti",
          code_3: "HTI"
        },
        {
          code: "HM",
          country: "Heard and Mc Donald Islands",
          code_3: "HMD"
        },
        {
          code: "HN",
          country: "Honduras",
          code_3: "HND"
        },
        {
          code: "HK",
          country: "Hong Kong",
          code_3: "HKG"
        },
        {
          code: "HU",
          country: "Hungary",
          code_3: "HUN"
        },
        {
          code: "IS",
          country: "Iceland",
          code_3: "ISL"
        },
        {
          code: "IN",
          country: "India",
          code_3: "IND"
        },
        {
          code: "ID",
          country: "Indonesia",
          code_3: "IDN"
        },
        {
          code: "IR",
          country: "Iran",
          code_3: "IRN"
        },
        {
          code: "IQ",
          country: "Iraq",
          code_3: "IRQ"
        },
        {
          code: "IE",
          country: "Ireland",
          code_3: "IRL"
        },
        {
          code: "IM",
          country: "Isle Of Man",
          code_3: "IMN"
        },
        {
          code: "IL",
          country: "Israel",
          code_3: "ISR"
        },
        {
          code: "IT",
          country: "Italy",
          code_3: "ITA"
        },
        {
          code: "JM",
          country: "Jamaica",
          code_3: "JAM"
        },
        {
          code: "JP",
          country: "Japan",
          code_3: "JPN"
        },
        {
          code: "JE",
          country: "Jersey",
          code_3: "JEY"
        },
        {
          code: "JT",
          country: "Johnston Island",
          code_3: "JTN"
        },
        {
          code: "JO",
          country: "Jordan",
          code_3: "JOR"
        },
        {
          code: "KZ",
          country: "Kazakhstan",
          code_3: "KAZ"
        },
        {
          code: "KE",
          country: "Kenya",
          code_3: "KEN"
        },
        {
          code: "KI",
          country: "Kiribati",
          code_3: "KIR"
        },
        {
          code: "KV",
          country: "Kosovo",
          code_3: "XKX"
        },
        {
          code: "KW",
          country: "Kuwait",
          code_3: "KWT"
        },
        {
          code: "KG",
          country: "Kyrgyzstan",
          code_3: "KGZ"
        },
        {
          code: "LA",
          country: "Laos",
          code_3: "LAO"
        },
        {
          code: "LV",
          country: "Latvia",
          code_3: "LVA"
        },
        {
          code: "LB",
          country: "Lebanon",
          code_3: "LBN"
        },
        {
          code: "LS",
          country: "Lesotho",
          code_3: "LSO"
        },
        {
          code: "LR",
          country: "Liberia",
          code_3: "LBR"
        },
        {
          code: "LY",
          country: "Libya",
          code_3: "LBY"
        },
        {
          code: "LI",
          country: "Liechtenstein",
          code_3: "LIE"
        },
        {
          code: "LT",
          country: "Lithuania",
          code_3: "LTU"
        },
        {
          code: "LU",
          country: "Luxembourg",
          code_3: "LUX"
        },
        {
          code: "MO",
          country: "Macau",
          code_3: "MAC"
        },
        {
          code: "MK",
          country: "Macedonia",
          code_3: "MKD"
        },
        {
          code: "MG",
          country: "Madagascar",
          code_3: "MDG"
        },
        {
          code: "MW",
          country: "Malawi",
          code_3: "MWI"
        },
        {
          code: "MY",
          country: "Malaysia",
          code_3: "MYS"
        },
        {
          code: "MV",
          country: "Maldives",
          code_3: "MDV"
        },
        {
          code: "ML",
          country: "Mali",
          code_3: "MLI"
        },
        {
          code: "MT",
          country: "Malta",
          code_3: "MLT"
        },
        {
          code: "MH",
          country: "Marshall Islands",
          code_3: "MHL"
        },
        {
          code: "MQ",
          country: "Martinique",
          code_3: "MTQ"
        },
        {
          code: "MR",
          country: "Mauritania",
          code_3: "MRT"
        },
        {
          code: "MU",
          country: "Mauritius",
          code_3: "MUS"
        },
        {
          code: "YT",
          country: "Mayotte",
          code_3: "MYT"
        },
        {
          code: "MX",
          country: "Mexico",
          code_3: "MEX"
        },
        {
          code: "FM",
          country: "Micronesia - Federated States Of",
          code_3: "FSM"
        },
        {
          code: "MI",
          country: "Midway Islands",
          code_3: "MID"
        },
        {
          code: "MD",
          country: "Moldova",
          code_3: "MDA"
        },
        {
          code: "MC",
          country: "Monaco",
          code_3: "MCO"
        },
        {
          code: "MN",
          country: "Mongolia",
          code_3: "MNG"
        },
        {
          code: "ME",
          country: "Montenegro",
          code_3: "MNE"
        },
        {
          code: "MS",
          country: "Montserrat",
          code_3: "MSR"
        },
        {
          code: "MA",
          country: "Morocco",
          code_3: "MAR"
        },
        {
          code: "MZ",
          country: "Mozambique",
          code_3: "MOZ"
        },
        {
          code: "MM",
          country: "Myanmar",
          code_3: "MMR"
        },
        {
          code: "NA",
          country: "Namibia",
          code_3: "NAM"
        },
        {
          code: "NR",
          country: "Nauru",
          code_3: "NRU"
        },
        {
          code: "NP",
          country: "Nepal",
          code_3: "NPL"
        },
        {
          code: "NL",
          country: "Netherlands",
          code_3: "NLD"
        },
        {
          code: "AN",
          country: "Netherlands Antilles",
          code_3: "ANT"
        },
        {
          code: "NT",
          country: "Neutral Zone",
          code_3: "NTZ"
        },
        {
          code: "NC",
          country: "New Caledonia",
          code_3: "NCL"
        },
        {
          code: "NZ",
          country: "New Zealand",
          code_3: "NZL"
        },
        {
          code: "NI",
          country: "Nicaragua",
          code_3: "NIC"
        },
        {
          code: "NE",
          country: "Niger",
          code_3: "NER"
        },
        {
          code: "NG",
          country: "Nigeria",
          code_3: "NGA"
        },
        {
          code: "NU",
          country: "Niue",
          code_3: "NIU"
        },
        {
          code: "NF",
          country: "Norfolk Island",
          code_3: "NFK"
        },
        {
          code: "KP",
          country: "North Korea",
          code_3: "PRK"
        },
        {
          code: "MP",
          country: "Northern Mariana Islands",
          code_3: "MNP"
        },
        {
          code: "NO",
          country: "Norway",
          code_3: "NOR"
        },
        {
          code: "OM",
          country: "Oman",
          code_3: "OMN"
        },
        {
          code: "PK",
          country: "Pakistan",
          code_3: "PAK"
        },
        {
          code: "PW",
          country: "Palau",
          code_3: "PLW"
        },
        {
          code: "PS",
          country: "Palestinian Territories",
          code_3: "PSE"
        },
        {
          code: "PA",
          country: "Panama",
          code_3: "PAN"
        },
        {
          code: "PG",
          country: "Papua New Guinea",
          code_3: "PNG"
        },
        {
          code: "PY",
          country: "Paraguay",
          code_3: "PRY"
        },
        {
          code: "PE",
          country: "Peru",
          code_3: "PER"
        },
        {
          code: "PH",
          country: "Philippines",
          code_3: "PHL"
        },
        {
          code: "PN",
          country: "Pitcairn Islands",
          code_3: "PCN"
        },
        {
          code: "PL",
          country: "Poland",
          code_3: "POL"
        },
        {
          code: "PT",
          country: "Portugal",
          code_3: "PRT"
        },
        {
          code: "PR",
          country: "Puerto Rico",
          code_3: "PRI"
        },
        {
          code: "QA",
          country: "Qatar",
          code_3: "QAT"
        },
        {
          code: "RE",
          country: "Reunion",
          code_3: "REU"
        },
        {
          code: "RO",
          country: "Romania",
          code_3: "ROU"
        },
        {
          code: "RU",
          country: "Russia",
          code_3: "RUS"
        },
        {
          code: "RW",
          country: "Rwanda",
          code_3: "RWA"
        },
        {
          code: "BL",
          country: "Saint Barthelemy",
          code_3: "BLM"
        },
        {
          code: "KN",
          country: "Saint Kitts and Nevis",
          code_3: "KNA"
        },
        {
          code: "LC",
          country: "Saint Lucia",
          code_3: "LCA"
        },
        {
          code: "MF",
          country: "Saint Martin — French Part",
          code_3: "MAF"
        },
        {
          code: "VC",
          country: "Saint Vincent and The Grenadines",
          code_3: "VCT"
        },
        {
          code: "WS",
          country: "Samoa",
          code_3: "WSM"
        },
        {
          code: "SM",
          country: "San Marino",
          code_3: "SMR"
        },
        {
          code: "ST",
          country: "Sao Tome and Principe",
          code_3: "STP"
        },
        {
          code: "SA",
          country: "Saudi Arabia",
          code_3: "SAU"
        },
        {
          code: "SN",
          country: "Senegal",
          code_3: "SEN"
        },
        {
          code: "RS",
          country: "Serbia",
          code_3: "SRB"
        },
        {
          code: "CS",
          country: "Serbia and Montenegro",
          code_3: "CSM"
        },
        {
          code: "SC",
          country: "Seychelles",
          code_3: "SYC"
        },
        {
          code: "SL",
          country: "Sierra Leone",
          code_3: "SLE"
        },
        {
          code: "SG",
          country: "Singapore",
          code_3: "SGP"
        },
        {
          code: "SX",
          country: "Saint Martin — Dutch Part",
          code_3: "SXM"
        },
        {
          code: "SK",
          country: "Slovakia",
          code_3: "SVK"
        },
        {
          code: "SI",
          country: "Slovenia",
          code_3: "SVN"
        },
        {
          code: "SB",
          country: "Solomon Islands",
          code_3: "SLB"
        },
        {
          code: "SO",
          country: "Somalia",
          code_3: "SOM"
        },
        {
          code: "ZA",
          country: "South Africa",
          code_3: "ZAF"
        },
        {
          code: "GS",
          country: "South Georgia and The South Sandwich Islands",
          code_3: "SGS"
        },
        {
          code: "KR",
          country: "South Korea",
          code_3: "KOR"
        },
        {
          code: "SS",
          country: "South Sudan",
          code_3: "SSD"
        },
        {
          code: "ES",
          country: "Spain",
          code_3: "ESP"
        },
        {
          code: "LK",
          country: "Sri Lanka",
          code_3: "LKA"
        },
        {
          code: "SH",
          country: "St. Helena",
          code_3: "SHN"
        },
        {
          code: "PM",
          country: "St. Pierre and Miquelon",
          code_3: "SPM"
        },
        {
          code: "SD",
          country: "Sudan",
          code_3: "SDN"
        },
        {
          code: "SR",
          country: "Suriname",
          code_3: "SUR"
        },
        {
          code: "SJ",
          country: "Svalbard and Jan Mayen Islands",
          code_3: "SJM"
        },
        {
          code: "SZ",
          country: "Swaziland",
          code_3: "SWZ"
        },
        {
          code: "SE",
          country: "Sweden",
          code_3: "SWE"
        },
        {
          code: "CH",
          country: "Switzerland",
          code_3: "CHE"
        },
        {
          code: "SY",
          country: "Syria",
          code_3: "SYR"
        },
        {
          code: "TW",
          country: "Taiwan",
          code_3: "TWN"
        },
        {
          code: "TJ",
          country: "Tajikistan",
          code_3: "TJK"
        },
        {
          code: "TZ",
          country: "Tanzania",
          code_3: "TZA"
        },
        {
          code: "TH",
          country: "Thailand",
          code_3: "THA"
        },
        {
          code: "TL",
          country: "Timor-leste",
          code_3: "TLS"
        },
        {
          code: "TG",
          country: "Togo",
          code_3: "TGO"
        },
        {
          code: "TK",
          country: "Tokelau",
          code_3: "TKL"
        },
        {
          code: "TO",
          country: "Tonga",
          code_3: "TON"
        },
        {
          code: "TT",
          country: "Trinidad and Tobago",
          code_3: "TTO"
        },
        {
          code: "TN",
          country: "Tunisia",
          code_3: "TUN"
        },
        {
          code: "TR",
          country: "Turkey",
          code_3: "TUR"
        },
        {
          code: "TM",
          country: "Turkmenistan",
          code_3: "TKM"
        },
        {
          code: "TC",
          country: "Turks and Caicos Islands",
          code_3: "TCA"
        },
        {
          code: "TV",
          country: "Tuvalu",
          code_3: "TUV"
        },
        {
          code: "UG",
          country: "Uganda",
          code_3: "UGA"
        },
        {
          code: "UA",
          country: "Ukraine",
          code_3: "UKR"
        },
        {
          code: "AE",
          country: "United Arab Emirates",
          code_3: "ARE"
        },
        {
          code: "GB",
          country: "United Kingdom",
          code_3: "GBR"
        },
        {
          code: "US",
          country: "United States",
          code_3: "USA"
        },
        {
          code: "UM",
          country: "United States Minor Outlying Islands",
          code_3: "UMI"
        },
        {
          code: "UY",
          country: "Uruguay",
          code_3: "URY"
        },
        {
          code: "UZ",
          country: "Uzbekistan",
          code_3: "UZB"
        },
        {
          code: "VU",
          country: "Vanuatu",
          code_3: "VUT"
        },
        {
          code: "VA",
          country: "Vatican City State — HOLY See",
          code_3: "VAT"
        },
        {
          code: "VE",
          country: "Venezuela",
          code_3: "VEN"
        },
        {
          code: "VN",
          country: "Vietnam",
          code_3: "VNM"
        },
        {
          code: "VG",
          country: "Virgin Islands — British",
          code_3: "VGB"
        },
        {
          code: "VI",
          country: "Virgin Islands — U.S.",
          code_3: "VIR"
        },
        {
          code: "WK",
          country: "Wake Island",
          code_3: "WAK"
        },
        {
          code: "WF",
          country: "Wallis and Futuna Islands",
          code_3: "WLF"
        },
        {
          code: "EH",
          country: "Western Sahara",
          code_3: "ESH"
        },
        {
          code: "YE",
          country: "Yemen",
          code_3: "YEM"
        },
        {
          code: "ZR",
          country: "Zaire",
          code_3: "ZAR"
        },
        {
          code: "ZM",
          country: "Zambia",
          code_3: "ZMB"
        },
        {
          code: "ZW",
          country: "Zimbabwe",
          code_3: "ZWE"
        }
    ],
    application_issues: [
      {id: 'OK_NO_ISSUES', desc: 'OK! No issues.'},
      {id: 'EXPECTING_PASSPORT_UPLOAD', desc: 'Expecting Passport Upload.'},
      {id: 'ANOTHER_APPLICATION_ALREADY_EXISTS_PENDING_APPROVAL', desc: 'Another application with this passport information already exists. Previous application is pending approval.'},
      {id: 'TRAVEL_DETAILS_PENDING', desc: 'Travel Details Pending.'},
      {id: 'PORTRAIT_PHOTO_AND_TRAVEL_DETAILS_PENDING', desc: 'Portrait Photo and Travel details Pending'},
      {id: 'ANOTHER_APPLICATION_ALREADY_EXISTS', desc: 'Another application with this passport information already exists.'},
      {id: 'ANOTHER_APPLICATION_ALREADY_EXISTS_CANNOT_RETRIEVE', desc: 'Another application with this passport information already exists. Cannot retrieve previous application.'},
      {id: 'APPLICATION_EXPIRED', desc: 'Application Expired Status upon decision checking.'},
      {id: 'PREVIOUS_APPLICATION_TRAVEL_NOT_AUTHORIZED', desc: 'PREVIOUSLY SUBMITTED APPLICATION. TRAVEL NOT AUTHORIZED'},
      {id: 'INVALID_PASSPORT_NUMBER', desc: 'Passport Number is invalid.'},
      {id: 'PASSPORT_INELIGIBLE_FOR_VISA_WAIVER_PROGRAM', desc: 'Passport ineligible for Visa Waiver Program travel.'},
      {id: 'DATE_OF_BIRTH_GREATER_THAN_CURRENT_DATE', desc: 'Date of Birth Greater than the Current Date.'},
      {id: 'UNABLE_TO_PROCESS_PASSPORT_IMAGE', desc: 'Passport Image Unacceptable.'},
      {id: 'PAYMENT_STATUS_COULD_NOT_BE_DETERMINED', desc: 'THE PAYMENT STATUS COULD NOT BE DETERMINED AT THIS TIME. PLEASE CHECK BACK LATER FOR THE PAYMENT STATUS.'},
      {id: 'FORBIDDEN_COUNTRY_OF_BIRTH_OTHER', desc: 'Country of Birth on Forbidden List (IRAN). Specify ALL Statements that are related to Country of Birth.'},
      {id: 'EVW_PASSPORT_REUPLOAD_REQUIRED', desc: '[UK-EVW] - New Passport Image Needed: The passport image you submitted is not readable.'},
      {id: 'OTHER', desc: 'Other issue.'}
    ],
    application_issues_instructions: {
        'OK_NO_ISSUES': 'OK! No issues.',
        'EXPECTING_PASSPORT_UPLOAD': 'Expecting Passport Upload.',
        'PREVIOUS_APPLICATION_TRAVEL_NOT_AUTHORIZED': 'PREVIOUSLY SUBMITTED APPLICATION. TRAVEL NOT AUTHORIZED',
        'APPLICATION_EXPIRED': 'ATTENTION: You need to request real passport copy from applicant in order to submit this application.',
        'ANOTHER_APPLICATION_ALREADY_EXISTS': 'Another application with this passport information already exists.',
        'ANOTHER_APPLICATION_ALREADY_EXISTS_PENDING_APPROVAL': 'Unable to Process Application: Another application with this passport information already exists.<br><strong>WHAT YOU NEED TO DO?</strong><br>Upon retrieving, the status is Authorization Pending? You need to retrieve it until status is "Approved" and then re-submit entire application by canceling the current (old) one.',
        'ANOTHER_APPLICATION_ALREADY_EXISTS_CANNOT_RETRIEVE': 'Another application with this passport information already exists. Cannot retrieve previous application.',
        'INVALID_PASSPORT_NUMBER': 'Passport Number is invalid.',
        'PASSPORT_INELIGIBLE_FOR_VISA_WAIVER_PROGRAM': 'Passport ineligible for Visa Waiver Program travel.',
        'DATE_OF_BIRTH_GREATER_THAN_CURRENT_DATE': 'Date of Birth Greater than the Current Date.',
        'UNABLE_TO_PROCESS_PASSPORT_IMAGE': 'Passport Image Unacceptable.',
        'FORBIDDEN_COUNTRY_OF_BIRTH_OTHER': 'Country of Birth on Forbidden List (IRAN). Specify ALL Statements that are related to Country of Birth.',
        'PAYMENT_STATUS_COULD_NOT_BE_DETERMINED': 'THE PAYMENT STATUS COULD NOT BE DETERMINED AT THIS TIME. PLEASE CHECK BACK LATER FOR THE PAYMENT STATUS.<br><strong>WHAT YOU NEED TO DO?</strong><br>Keep retrieving the application status every 30-45 minutes until system allows you to make payment again.',
        'EVW_PASSPORT_REUPLOAD_REQUIRED': '[UK-EVW] - The passport image you submitted is not readable. Please, request a new passport image from the applicant or follow-up on an already existing open ticket.',
        'OTHER': 'Other issue.'
    },
    response_types: [
      {id: 'ANOTHER_APPLICATION_ALREADY_EXISTS', desc: 'Another application already exists'},
      {id: 'REFUND', desc: 'Asking for Refund (Money back)'},
      {id: 'REFUND_NO_PASSPORT', desc: 'Asking for Refund (Money back) - No Passport Uploaded'},
      {id: 'VISA_WAIVER', desc: 'Passport ineligible for Visa Waiver Program travel'},
      {id: 'APPLICATION_STATUS', desc: 'What is my Application Status?'},
      {id: 'THANKS_FOR_PROVIDED_INFO', desc: 'Thanks for provided information'},
      {id: 'PASSPORT_ISSUE_OVER_11_YEARS', desc: 'Passport Issue Problem Over 11 Years'},
      {id: 'PASSPORT_COPY_REQUIRED', desc: 'Passport Copy Required (Application Expired Issue)'},
      {id: 'PRINT_ESTA', desc: 'Do I need to Print Application?'},
      {id: 'WHO_WE_ARE', desc: 'Who we are?'},
      {id: 'HOW_MUCH_WE_CHARGE', desc: 'How much we charge?'},
      {id: 'UPDATE_TRAVEL_INFO', desc: 'How to update Travel Information?'},
      {id: 'INVALID_PASSPORT_NUMBER', desc: 'Invalid Passport Number'},
      {id: 'PAYMENT_RECEIPT', desc: 'Payment Receipt?'},
      {id: 'COUNTRY_OF_BIRTH_OTHER', desc: 'Country of Birth on Forbidden List (IRAN). Specify ALL Statements that are related to Country of Birth.'},
      {id: 'CHECK_APPLICATION_STATUS', desc: 'How do I check/verify Application on the Official Website?'},
      {id: 'CANCEL_DELETE_DATA_APPLICATION', desc: 'Cancel/Delete my Application (Data)'},
      {id: 'CHARGEBACK_WARNING', desc: 'Chargeback Warning'},
      {id: 'CHARGEBACK_THREAT', desc: 'Chargeback Threat!'},
    ],
  },
  mutations: {
    setUserData(state, data){
      state.user = data
    }
  },
  actions: {
    setUser(context, data){
      // let _this = context.state.thisVue;
      context.commit('setUserData', data);
      
    }
  },
  plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
})