<template>
<div>
  <div v-if="isLoading">
    <v-overlay
      :value="isLoading"
      color="white"
    >
      <v-progress-circular
        color="#38435a"
        indeterminate
        size="74"
      ></v-progress-circular>
    </v-overlay>
  </div>
  <div v-else class="pa-5">
  <v-form @submit.prevent ref="form">
  <div :key="app_key">
    <v-row>
      <v-col cols="12">
    <v-card class="card-style rounded-1">
      <v-card-title>
        <v-btn icon @click="$router.back()"><v-icon>mdi-chevron-left</v-icon></v-btn><h5>Dispute <strong>#{{$route.params.ref}}</strong></h5>
      </v-card-title>
      <v-card-text>
        <v-divider></v-divider>
        <div class="ma-5">
        <v-row class="flex-items">
          <v-col><v-divider color="#909fa9"></v-divider></v-col>
          <v-col cols="auto" class="mx-auto">
            <span class="view-main-title">System Information</span>
          </v-col>
          <v-col><v-divider color="#909fa9"></v-divider></v-col>
        </v-row>
        <v-row v-if="data.additionalInstructions">
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Additional Instructions</v-col> <v-col v-if="data.additionalInstructions" cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="data.additionalInstructions" ><strong>{{data.additionalInstructions}}</strong></v-col><v-col v-else cols="12" sm="6" class="py-1"></v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1"></v-col> <v-col cols="12" sm="6" class="py-2" >
            <v-btn text color="btnText" small @click="show_ads_power = !show_ads_power"><strong>View AdsPower Login Details</strong></v-btn>
            <div v-show="show_ads_power" style="background: #fefaf5;">
              <div class="pa-2 pb-1"><strong><span style="color: #7291ab">User: </span><span v-if="data.clients && data.clients.systemLoginUser" class="clipboard-style ml-2" v-clipboard="data.clients.systemLoginUser">{{data.clients.systemLoginUser || 'None'}}</span></strong></div>
              <div class="pa-2 pt-1"><strong><span style="color: #7291ab">Pass: </span><span v-if="data.clients && data.clients.systemLoginPassword" class="clipboard-style ml-2" v-clipboard="data.clients.systemLoginPassword">{{data.clients.systemLoginPassword || 'None'}}</span></strong></div>
            </div>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Dispute Reference Number</v-col> <v-col v-if="data.disputeToken" cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="data.disputeToken" ><strong>{{data.disputeToken}}</strong></v-col><v-col v-else cols="12" sm="6" class="py-1"></v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Charge ID</v-col> <v-col v-if="data.data && data.data.data && data.data.data.object && data.data.data.object.charge" cols="12" sm="6" class="py-0" ><strong class="py-1 clipboard-style" v-clipboard="data.data.data.object.charge">{{data.data.data.object.charge}}</strong>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon color="grey" v-clipboard="'https://dashboard.stripe.com/payments/' + data.data.data.object.charge" v-bind="attrs" v-on="on">
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>
            </template>
            <div class="custom-tooltip">
              {{"https://dashboard.stripe.com/payments/" + data.data.data.object.charge}}
              <span class="tooltip-caret"></span>
            </div>
          </v-tooltip>
          </v-col><v-col v-else cols="12" sm="6" class="py-1"></v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Dispute Timestamp</v-col> <v-col v-if="data.disputeTimestamp" cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="formatTimestamp(data.disputeTimestamp)" ><strong>{{formatTimestamp(data.disputeTimestamp)}}</strong></v-col><v-col v-else cols="12" sm="6" class="py-1"></v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Charge Timestamp</v-col> <v-col cols="12" sm="6" class="py-1">
          <div v-if="edit_prop != 'chargeTimestamp'">
            <strong class="clipboard-style" v-clipboard="formatTimestamp(data.chargeTimestamp)">{{formatTimestamp(data.chargeTimestamp)}}</strong> <v-btn text color="btnText" small @click="edit('chargeTimestamp', formatTimestamp(data.chargeTimestamp))">Edit</v-btn>
          </div>
          <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-text-field
                    v-model="edit_val"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Client</v-col> <v-col v-if="data.client" cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="data.client" ><strong>{{data.client}}</strong></v-col><v-col v-else cols="12" sm="6" class="py-1"></v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Zendesk Ticket</v-col> <v-col v-if="data.zendesk_ticket" cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="data.zendesk_ticket" ><strong>{{data.zendesk_ticket}}</strong></v-col><v-col v-else cols="12" sm="6" class="py-1"><strong>N/A</strong></v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Status</v-col> <v-col cols="12" sm="6" class="py-0">
            <v-chip
              class="chip-status-1 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap"
              v-if="data.submitted == 1"
              small
            >
            <span>Submitted</span>
            </v-chip>
            <v-chip v-else-if="data.status == 'pending'"
              class="chip-status-1 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap"
              small
            >
              <span>Pending</span>
            </v-chip>
            <v-chip v-else-if="data.status == 'awaiting_submission'"
              class="chip-status-2 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap"
              small
            >
            <span>Awaiting Submission</span>
          </v-chip>
          <span v-if="$store.state.user.role == 'admin'">
            <v-chip
              class="chip-status-1 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap"
              v-if="data.paid == 1"
              small
            >
            <span>Paid</span>
            </v-chip>
            <v-chip
              class="chip-status-4 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap"
              v-else-if="data.own == 0"
              small
            >
            <span>Unpaid</span>
            </v-chip>
          </span>
          </v-col>
        </v-row>

        <v-row class="flex-items">
          <v-col><v-divider color="#909fa9"></v-divider></v-col>
          <v-col cols="auto" class="mx-auto">
            <span class="view-main-title">Dispute Details</span>
          </v-col>
          <v-col><v-divider color="#909fa9"></v-divider></v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Dispute Email</v-col> <v-col v-if="data.disputeEmail" cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="data.disputeEmail" ><strong>{{data.disputeEmail}}</strong></v-col><v-col v-else cols="12" sm="6" class="py-1"></v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1"></v-col> <v-col cols="12" sm="6" class="py-1" ><v-btn text color="btnText" dark small @click="randomizeTimestamp(data.disputeToken)" :loading="loadingRandomTimestamp">Randomize Timestamp</v-btn></v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Signature Of Applicant</v-col> <v-col cols="12" sm="6" class="py-1" >
            <div v-if="edit_prop != 'disputeSignature'">
            <strong class="clipboard-style" v-clipboard="data.disputeSignature">{{data.disputeSignature}}</strong> <v-btn text color="btnText" small @click="edit('disputeSignature', data.disputeSignature)">Edit</v-btn>
          </div>
          <v-row v-else no-gutters>
              <v-col cols="12" class="pb-1">
                <v-text-field
                  v-model="edit_val"
                  color="#677788"
                  :rules="[formRules.required]"
                  height="45"
                  width="100%"
                  outlined
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
              <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Applicant Declaration Agreed Timestamp</v-col> <v-col cols="12" sm="6" class="py-1" >
            <div v-if="edit_prop != 'declarationAgreedTimestamp'">
            <strong class="clipboard-style" v-clipboard="formatTimestamp(data.declarationAgreedTimestamp)">{{formatTimestamp(data.declarationAgreedTimestamp)}}</strong> <v-btn text color="btnText" small @click="edit('declarationAgreedTimestamp', formatTimestamp(data.declarationAgreedTimestamp))">Edit</v-btn>
          </div>
          <v-row v-else no-gutters>
              <v-col cols="12" class="pb-1">
                <v-text-field
                  v-model="edit_val"
                  color="#677788"
                  :rules="[formRules.required]"
                  height="45"
                  width="100%"
                  outlined
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
              <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Third Party Authorization Agreed Timestamp</v-col> <v-col cols="12" sm="6" class="py-1">
            <div v-if="edit_prop != 'authorizationAgreedTimestamp'">
            <strong class="clipboard-style" v-clipboard="formatTimestamp(data.declarationAgreedTimestamp)">{{formatTimestamp(data.authorizationAgreedTimestamp)}}</strong> <v-btn text color="btnText" small @click="edit('authorizationAgreedTimestamp', formatTimestamp(data.authorizationAgreedTimestamp))">Edit</v-btn>
          </div>
          <v-row v-else no-gutters>
              <v-col cols="12" class="pb-1">
                <v-text-field
                  v-model="edit_val"
                  color="#677788"
                  :rules="[formRules.required]"
                  height="45"
                  width="100%"
                  outlined
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
              <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Electronically Signed Timestamp</v-col> <v-col cols="12" sm="6" class="py-1" >
            <div v-if="edit_prop != 'electronicallySignedTimestamp'">
            <strong class="clipboard-style" v-clipboard="formatTimestamp(data.electronicallySignedTimestamp)">{{formatTimestamp(data.electronicallySignedTimestamp)}}</strong> <v-btn text color="btnText" small @click="edit('electronicallySignedTimestamp', formatTimestamp(data.electronicallySignedTimestamp))">Edit</v-btn>
          </div>
          <v-row v-else no-gutters>
              <v-col cols="12" class="pb-1">
                <v-text-field
                  v-model="edit_val"
                  color="#677788"
                  :rules="[formRules.required]"
                  height="45"
                  width="100%"
                  outlined
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
              <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Digital Fingerprint</v-col> <v-col cols="12" sm="6" class="py-1" >
            <div v-if="edit_prop != 'userAgent'">
              <strong v-if="data.userAgent" class="clipboard-style" v-clipboard="data.userAgent" >{{data.userAgent}}</strong>
              <span v-else>Please Enter</span>
              <v-btn text color="btnText" small @click="edit('userAgent', data.userAgent)">Edit</v-btn>
              <v-btn text color="btnText" small @click="getRandomUserAgent(data.disputeToken)" dark :loading="loadingRandomAgent">Get Random</v-btn>
            </div>
          <v-row v-else no-gutters>
              <v-col cols="12" class="pb-1">
                <v-text-field
                  v-model="edit_val"
                  color="#677788"
                  :rules="[formRules.required]"
                  height="45"
                  width="100%"
                  outlined
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
              <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">IP Address</v-col> <v-col cols="12" sm="6" class="py-1" >
            <div v-if="edit_prop != 'ipAddress'">
              <strong v-if="data.ipAddress" class="clipboard-style" v-clipboard="data.ipAddress" >{{data.ipAddress}}</strong>
              <span v-else>Please Enter</span>
              <v-btn text color="btnText" small @click="edit('ipAddress', data.ipAddress)">Edit</v-btn>
            </div>
          <v-row v-else no-gutters>
              <v-col cols="12" class="pb-1">
                <v-text-field
                  v-model="edit_val"
                  color="#677788"
                  :rules="[formRules.required]"
                  height="45"
                  width="100%"
                  outlined
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="cancelEdit()"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
              <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="updateData()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row class="flex-items">
          <v-col><v-divider color="#909fa9"></v-divider></v-col>
          <v-col cols="auto" class="mx-auto">
            <span class="view-main-title">Dispute Information</span>
          </v-col>
          <v-col><v-divider color="#909fa9"></v-divider></v-col>
        </v-row>
        <v-row justify="center">
        <v-btn color="#71869d" class="white--text pa-5 mx-1 text-capitalized" depressed :title="'Our company, ' + (data.client || '').toUpperCase() + ', is providing a phone number verification tool on your websites and apps you can detect the validity of the provided phone numbers before they are accepted. You can improve your service by cutting down the invalid or inactive numbers before they enter your database.'" v-clipboard="'Our company, ' + (data.client || '').toUpperCase() + ', is providing a phone number verification tool on your websites and apps you can detect the validity of the provided phone numbers before they are accepted. You can improve your service by cutting down the invalid or inactive numbers before they enter your database.'">Copy Description</v-btn>
        <v-btn color="#71869d" class="white--text pa-5 mx-1 text-capitalized" depressed :title="`Purchase is made by the rightful cardholder.

The cardholder is trying to defraud us off funds in relation to a successfully executed digitally signed agreement.`" v-clipboard="`Purchase is made by the rightful cardholder.

The cardholder is trying to defraud us off funds in relation to a successfully executed digitally signed agreement.`">Copy Fraudulent / General Reason</v-btn>
        <v-btn color="#71869d" class="white--text pa-5 mx-1 text-capitalized" depressed :title="`The cardholder has already received the advertised service.

The cardholder is trying to defraud us off funds in relation to a successfully executed digitally signed agreement after completion of service.`" v-clipboard="`The cardholder has already received the advertised service.

The cardholder is trying to defraud us off funds in relation to a successfully executed digitally signed agreement after completion of service.`">Copy Other Reason</v-btn>
          
        </v-row>

        <v-row class="flex-items">
          <v-col><v-divider color="#909fa9"></v-divider></v-col>
          <v-col cols="auto" class="mx-auto">
            <span class="view-main-title">Consent Form</span>
          </v-col>
          <v-col><v-divider color="#909fa9"></v-divider></v-col>
        </v-row>
        <div id="consentForm" class="consent-form-style">
          <v-row no-gutters>
            <v-col cols="12">
              <v-checkbox :input-value="true" color="darkgrey" hide-details>
              <template v-slot:label>
                  Agree with Applicant\'s Declaration.
              </template>
            </v-checkbox>
            </v-col>
            <v-col cols="12">
              <v-checkbox :input-value="true" true-value color="darkgrey" hide-details>
              <template v-slot:label>
                  Agree with Third Party Authorization.
              </template>
            </v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <div class="px-0 pb-1 text-subtitle-1">Signature of Applicant <span class="red--text">*</span></div>
              <v-text-field
              :value="data.disputeSignature"
              color="grey"
              height="42"
              dense
              outlined
              hide-details="auto"
              ></v-text-field>
              <small class="text-caption">To sign, enter your name as it appears on your passport {{ data.disputeSignature}}</small>
            </v-col>
            <v-col cols="12" style="font-size: 17px">
              <div class="alert alert-warning" role="alert" style="margin-top: 15px;line-height: 25px;border-radius: 0px;padding: 15px 30px; background: #f5ca99; border-radius: 10px">
                <u><b>{{this.urlDecode(data.disputeSignature || '')}}</b></u>
                <br /> <br />                                   
                - Applicant Declaration agreed with on <b>{{this.formatDateTime3(data.declarationAgreedTimestamp)}}</b> from IP Address: <b>{{data.ipAddress}}</b> by <b><u>{{data.disputeSignature}}</u></b> using a device with digital fingerprint: <b>{{data.userAgent}}</b>
                  <br />  <br /> 
                - Third-Party Authorization agreed with on <b>{{this.formatDateTime3(data.authorizationAgreedTimestamp)}}</b> from IP Address: <b>{{data.ipAddress}}</b> by <b><u>{{data.disputeSignature}}</u></b>  using a device with digital fingerprint: <b>{{data.userAgent}}</b>
                  <br />  <br /> 
                - Electronically signed application on <b>{{this.formatDateTime3(data.electronicallySignedTimestamp)}}</b> from IP Address: <b>{{data.ipAddress}}</b> by <b><u>{{data.disputeSignature}}</u></b>  using a device with digital fingerprint: <b>{{data.userAgent}}</b></div>
            </v-col>
          </v-row>
        </div>
          <v-row justify="center" class="my-5">
            <v-btn color="#71869d" class="white--text pa-5 mx-1 text-capitalized" depressed @click="downloadConsentForm()">Download Consent Form</v-btn>
          </v-row>
          <v-row justify="center" class="my-5">
            <v-btn color="#71869d" class="white--text pa-5 mx-1 text-capitalized" depressed @click="viewCustomerCommunication()">View Customer Communication</v-btn>
          </v-row>
          <v-row justify="center" class="my-5">
            <v-btn color="#377dff" class="white--text pa-5 mx-1 text-capitalized" depressed :title="`Dear  ${(data.client || '').toUpperCase()},
                                
Thank you for the provided information.

Your dispute ${data.disputeToken} by ${(data.disputeSignature || '').toUpperCase()} has been successfully submitted to Stripe account ${(data.client || '').toUpperCase()} with compelling evidence.

We wish you a wonderful day!`" v-clipboard="`Dear  ${(data.client || '').toUpperCase()},
                                
Thank you for the provided information.

Your dispute ${data.disputeToken} by ${(data.disputeSignature || '').toUpperCase()} has been successfully submitted to Stripe account ${(data.client || '').toUpperCase()} with compelling evidence.

We wish you a wonderful day!`">Copy Solved Response</v-btn>
          </v-row>

        </div>
        <v-divider v-if="data.submitted == 0"></v-divider>
      </v-card-text>
      <v-card-actions class="pa-5 pb-8" v-if="data.submitted == 0">
        <v-spacer></v-spacer>
        <v-btn color="#00A34E" class="white--text pa-5 text-capitalized" depressed @click="markAsSubmitted()">Mark Dispute as Submitted</v-btn>
      </v-card-actions>
    </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialogConfirmation"
      width="520"
    >
      <v-card>
        <v-card-title class="text-subtitle-1">
          <strong v-if="action == 'generate_id_analyzer'">Generate ID Analyzer for Application #{{data.token}}</strong>
        </v-card-title>

        <v-card-text>
          <v-form ref="dialogForm">
            <div v-if="action == 'void'" class="subtitle-1">
              Are you sure you want to <strong>VOID</strong> Application <strong>#{{data.token}}</strong>? Please, provide reason below:
              <div class="mt-1">
                <v-textarea
                  v-model="void_reason"
                  placeholder="Void Reason"
                  color="#677788"
                  :rules="[formRules.required]"
                  outlined
                  rows="2"
                  hide-details="auto"
                ></v-textarea>
              </div>
            </div>
            <div v-else-if="action == 'unvoid'">
              Are you sure you want to <strong>UNVOID</strong> Application <strong>#{{data.token}}</strong> ?<br />
            </div>
            <div v-if="action == 'duplicate'">
              Are you sure you want to <strong>MAKE A DUPLICATE</strong> of Application <strong>#{{data.token}}</strong> ?<br />
            </div>
            <div v-if="action == 'delete'">
              Are you sure you want to <strong>DELETE</strong> Application <strong>#{{data.token}}</strong> ?<br />
            </div>
            <div v-if="action == 'paid'">
              Are you sure you want to mark the Application <strong>#{{data.token}}</strong> as <strong>PAID</strong>?<br />
            </div>
            <div v-if="action == 'generate_id_analyzer'">
              Are you sure you want to generate a new <strong>ID analyzer</strong> for the Application <strong>#{{data.token}}</strong>?<br />
            </div>
            <div v-if="action == 'decision_rejected'">
              Are you sure you want to mark the application as <strong>REJECTED</strong>?<br />
            </div>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            outlined
            class="mr-2"
            depressed
            @click="dialogConfirmation = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="#00A34E"
            class="white--text"
            depressed
            @click="confirmUnvoidApplication()"
            v-if="action == 'unvoid'"
          >
            Yes, Unvoid
          </v-btn>
          <v-btn
            color="pink"
            class="white--text"
            depressed
            @click="confirmVoidApplication()"
            v-else-if="action == 'void'"
          >
            Yes, Void
          </v-btn>
          <v-btn
            color="#377dff"
            class="white--text"
            depressed
            @click="confirmDuplicateApplication()"
            v-else-if="action == 'duplicate'"
          >
            Yes, MAKE DUPLICATE
          </v-btn>
          <v-btn
            color="red darken-2"
            class="white--text"
            depressed
            @click="confirmDeleteApplication()"
            v-else-if="action == 'delete'"
          >
            Yes, Delete
          </v-btn>
          <v-btn
            color="#377dff"
            class="white--text"
            depressed
            @click="confirmPaidApplication()"
            v-else-if="action == 'paid'"
          >
            Yes, Mark As Paid
          </v-btn>
          <v-btn
            color="#377dff"
            class="white--text"
            depressed
            @click="confirmGenerateIDAnalyzer()"
            v-else-if="action == 'generate_id_analyzer'"
          >
            Yes, Generate
          </v-btn>
          <v-btn
            color="#b02c00"
            class="white--text"
            depressed
            @click="confirmMarkDecisionAsRejected()"
            v-else-if="action == 'decision_rejected'"
          >
            Yes, Rejected
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
  </v-form>
  </div>
    <!-- <Evidence :data="data" v-if="capture" /> -->
</div>
</template>

<script>
// import Evidence from './Evidence.vue'
import html2canvas from 'html2canvas';
  export default {
    components: {
      // Evidence
    },
    data: () => ({
      data: {},
      isLoading: false,
      dialogConfirmation: false,
      app_key: 0,
      action: null,
      show_ads_power: false,
      edit_val: null,
      edit_prop: null,
      edit_day: null,
      edit_month: null,
      edit_year: null,
      edit_prop_date: null,
      days: ['01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31'],
      months: [
          {id: '01', desc: 'January'},
          {id: '02', desc: 'February'},
          {id: '03', desc: 'March'},
          {id: '04', desc: 'April'},
          {id: '05', desc: 'May'},
          {id: '06', desc: 'June'},
          {id: '07', desc: 'July'},
          {id: '08', desc: 'August'},
          {id: '09', desc: 'September'},
          {id: '10', desc: 'October'},
          {id: '11', desc: 'November'},
          {id: '12', desc: 'December'},
      ],
      years: [],
      years2: [],
      yearsAll: [],
      capture: false,
      loadingRandomAgent: false,
      loadingRandomTimestamp: false,
    }),
    watch: {
      data: {
        handler(){
            this.app_key++;
        },
        deep: true
      }
    },
    methods: {
        initialize(){
          this.isLoading = true;
          let data = {
          dispute_token: this.$route.params.ref,
          };
        this.axiosCall("/clients-disputes/getByToken", "POST", data).then((res) => {
          if (res.status == 201) {
            this.isLoading = false;
            let temp_data = res.data
            temp_data.data = JSON.parse(temp_data.data)
            this.data = temp_data;
            console.log(this.data)
          }
        });
        },
        edit(prop, val){
          this.edit_val = val;
          this.edit_prop = prop
        },
        editDate(prop, val){
          this.edit_day = val[0];
          this.edit_month = val[1];
          this.edit_year = val[2];
          this.edit_prop_date = prop
        },
        updateData(){
          if(this.$refs.form.validate()){
          if(this.edit_prop_date){
              this.data[this.edit_prop_date[0]] = this.edit_day;
              this.data[this.edit_prop_date[1]] = this.edit_month;
              this.data[this.edit_prop_date[2]] = this.edit_year;
          }
          else if(this.edit_prop + '_text' in this.data){
            let country = this.getCountry(this.edit_val);
            if(country != 'None'){
              // console.log("updated the" + this.edit_prop + '_text')
              this.data[this.edit_prop] = this.edit_val
              this.data[this.edit_prop + '_text'] = country;
            }
          }
          else{
          this.data[this.edit_prop] = this.edit_val
          }
          let data = {
            dispute_token: this.data.disputeToken,
            field: this.edit_prop,
            value: this.edit_val,
          };
          this.axiosCall("/clients-disputes/updateData", "POST", data).then((res) => {
            if (res.data.status == 200) {
              this.showToast("Dispute field [" + (this.edit_prop || this.edit_prop_date) + "] was successfully updated.", 'success')
              this.cancelEdit();
            }
            else{
              this.showToast(res.data.msg, 'error')
            }
          });
          }
        },
        cancelEdit(){
          this.edit_val = null;
          this.edit_prop = null;
          this.edit_day = null;
          this.edit_month = null;
          this.edit_year = null;
          this.edit_prop_date = null;
        },
        getRandomUserAgent(token){
          let data = {
            dispute_token: token,
          };
          this.loadingRandomAgent = true;
          this.axiosCall("/clients-disputes/getRandomUserAgent", "POST", data).then((res) => {
            if (res.data.status == 200) {
              this.loadingRandomAgent = false;
              this.showToast("Successfully got randomized user agent.", 'success')
            this.initialize();
            }
            else{
              this.showToast(res.data.msg, 'error')
            }
          });
        },
        downloadConsentForm() {
            const name = this.data.disputeSignature;
            let element = document.querySelector('#consentForm');
        
            html2canvas(element).then(canvas => {
                // Hide the canvas to avoid displaying it on the page
                canvas.style.display = 'none';
                document.body.appendChild(canvas);
        
                // Convert the canvas to a JPG image
                let image = canvas.toDataURL('image/jpg');
        
                // Create a download link
                let downloadLink = document.createElement('a');
                downloadLink.setAttribute('download', name + ' - Electronically Signed Agreement.jpg');
                downloadLink.setAttribute('href', image);
        
                // Trigger the download
                downloadLink.click();
        
                // Clean up
                canvas.remove();
            })
          },
        viewCustomerCommunication(){
          window.open(this.$router.resolve({ path: '/' + this.$store.state.user.role + '/evidence/' + this.data.disputeToken}).href, '_blank');
        },
        markAsSubmitted(){
          let data = {
          dispute_token: this.data.disputeToken
          };
        this.axiosCall("/clients-disputes/markAsSubmitted", "POST", data).then((res) => {
          if (res.data.status == 200) {
            this.showToast(res.data.msg, 'success')
            this.initialize();
          }
          else{
            this.showToast(res.data.msg, 'error')
          }
        });
        },
        randomizeTimestamp(token){
          let data = {
            dispute_token: token,
          };
          this.loadingRandomTimestamp = true;
          this.axiosCall("/clients-disputes/randomizeTimestamp", "POST", data).then((res) => {
            if (res.data.status == 200) {
              this.loadingRandomTimestamp = false;
              this.initialize()
              this.showToast("Successfully got randomized timestamps.", 'success')
            }
            else{
              this.showToast(res.data.msg, 'error')
            }
          });
        },
    },
    mounted(){
       this.initialize()
    },
  }
</script>
<style scoped>
.view-main-title {
  color: #4d6585 !important;
  font-weight: 500;
  font-size: 16px;
}
.view-title {
  color: #4d6585 !important;
  font-weight: 500;
}
.chip-status-1{
  background-color: #d7f7c2 !important;
  color: #1d7327 !important;
}
.chip-status-2{
  background-color: #cff5f6 !important;
  color: #1066c5 !important;
}
.passport-img {
  max-width: 1000px;
  text-align: center;
  margin: 0 auto;height: 717px;
  background-size: cover;
  background-repeat: no-repeat;
  border-image-source: linear-gradient(to left, #146908, #d7f7c2) !important;
  border: 10px solid;border-image-slice: 1;
  border-width: 20px;
}
.passport-img-failed {
  max-width: 1000px;
  text-align: center;
  margin: 0 auto;height: 717px;
  background-size: cover;
  background-repeat: no-repeat;
  border-image-source: linear-gradient(to left, #c4093c, #ffe7f2) !important;
  border: 10px solid;border-image-slice: 1;
  border-width: 20px;
}
.consent-form-style {
  padding: 0 20%;
}
.custom-tooltip {
  position: relative;
  color: #fff;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 14px;
  text-align: center;
}

.tooltip-caret {
  position: absolute;
  bottom: -14.5px; /* Adjust to align with the edge */
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 10px 0 10px; /* Creates a triangle */
  border-color: rgba(51, 51, 51, 0.7) transparent transparent transparent;
}
</style>