<template>
  <div class="pa-5">
    <v-card class="card-style rounded-1" >
    <v-row class="ma-2">
      <v-col cols="12" md="9" class="flex-items">
        <v-btn
          color="#38435a"
          fab
          small
          @click="add"
          class="elevation-0 mr-3"
        ><v-icon color="white">mdi-plus</v-icon></v-btn>
        <h2>Stripe Accounts</h2>
      </v-col>
      <v-col cols="12" md="3">
      <v-text-field
          v-model="search"
          prepend-inner-icon="search"
          label="Search account names"
          single-line
          hide-details
          color="#38435a"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-card class="mb-1 dt-container" elevation="0" outlined>
    <v-data-table
      :loading="loading"
       :headers="headers"
        :items="data"
        item-key="id"
        :options.sync="options"
        hide-default-footer
    >
    <template v-slot:[`item.name`]="{ item }">
      <span class="flex-items">
        <span>{{item.name}}</span>
        <v-chip class="chip-status-7 rounded mx-2 my-1 font-weight-bold text-wrap" small>
          <span>{{item.payment_processor}}</span>
        </v-chip>
      </span>
    </template>
    <template v-slot:[`item.mids`]="{ item }">
      <span v-if="item.max_revenue != 0">
      <span v-for="(val, mid) in item.mids" :key="mid">
        <v-chip
          :class="[val == 1 ? 'chip-status-1' : 'chip-status-3', 'rounded px-2 py-1 mr-2 my-1 font-weight-bold text-wrap']"
          x-small
        >
        <span>{{mid}}</span>
        </v-chip>
      </span>
      </span>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <span class="flex-items">
        <v-switch
          v-model="item.switch_enabled"
          @change="switchStripeAccount(item)"
          inset
        ></v-switch>
        <v-btn
          v-if="$store.state.user.role == 'admin'"
          outlined
          small
          class="mr-2"
          color="#166ecf"
          @click="edit(item)"
        >
        <v-icon class="mr-1">mdi-square-edit-outline</v-icon>
        Edit
        </v-btn>
      </span>   
    </template>
    </v-data-table>
    </v-card>
    <v-row class="ma-2" align="center" justify="center">
    <v-col cols="auto" class="flex-items">
        <span class="px-2">Row Per Page: </span>
        <span>
          <v-select
            dense
            outlined
            hide-details
            :value="options.itemsPerPage"
            style="max-width: 90px"
            class="rounded-lg"
            @change="options.itemsPerPage = parseInt($event, 10)"
            :items="perPageChoices"
          >
          </v-select>
        </span>
    </v-col>
    <v-col cols="auto" class="mx-auto text-truncate" no-gutters>
    Showing {{ data.length > 0 ? ((paginationData.currentPage - 1) * options.itemsPerPage) + 1 : 0 }} to {{ data.length > 0 ? ((paginationData.currentPage - 1) * options.itemsPerPage) + data.length : 0 }} of {{ paginationData.count }} entries
    </v-col>
    <v-col cols="auto">
        <v-pagination
            v-model="options.page"
            class="rounded-lg"
            :total-visible="7"
            color="#38435a"
            :length="paginationData.lastPage">
        </v-pagination>
    </v-col>
      </v-row>
    </v-card>
    <v-dialog
      v-model="dialogConfirmation"
      width="520"
      persistent
    >
      <v-card>
        <v-card-title class="text-subtitle-1">
          <strong>Confirmation</strong>
        </v-card-title>

        <v-card-text>
            <div class="subtitle-1">
              Are you sure you want to {{edit_item.switch_enabled ? 'enable' : 'disable'}} <strong>{{edit_item.name}}</strong>?
            </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            outlined
            class="mr-2"
            depressed
            @click="cancelSwitch()"
          >
            Cancel
          </v-btn>
          <v-btn
            color="#166ecf"
            class="white--text"
            depressed
            @click="confirmSwitchStripeAccount()"
            v-if="edit_item.switch_enabled"
            :loading="loadingConfirm"
          >
            Yes, Enable
          </v-btn>
          <v-btn
            color="#166ecf"
            class="white--text"
            depressed
            @click="confirmSwitchStripeAccount()"
            v-if="!edit_item.switch_enabled"
            :loading="loadingConfirm"
          >
            Yes, Disable
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogForm"
      width="800"
      scrollable
      persistent
    >
      <v-card>
        <v-card-title>
          <strong v-if="action == 'add'">Add Stripe Account</strong>
          <strong v-else-if="action == 'edit'">Update Stripe Account</strong>
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="closeFormDialog()"
          >
          <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pt-2" style="max-height: 900px;">
            <v-form ref="formRef">
              <v-row no-gutters>
                <v-col cols="12">
                    <div class="px-0 pb-1 text-subtitle-1">Stripe Account</div>
                    <span class="flex-items justify-center">
                    <v-text-field
                    v-if="action == 'add'"
                    v-model="formdata.name"
                    color="grey"
                    :rules="[formRules.required]"
                    dense
                    height="42"
                    outlined
                    :append-icon="account_available == true ? 'mdi-check-circle' : account_available == false ? 'mdi-close-circle' : ''"
                    ></v-text-field>
                    <v-text-field
                    v-else
                    :value="formdata.name"
                    color="grey"
                    dense
                    height="42"
                    disabled
                    outlined
                    ></v-text-field>
                    <v-btn
                      v-if="action == 'add'"
                      color="grey"
                      class="white--text mb-6 ml-1"
                      depressed
                      height="42"
                      @click="checkAvailability()"
                      :loading="loadingAvailable"
                    >
                      Check Availablility
                    </v-btn>
                    </span>
                </v-col>
                <v-col cols="12">
                    <div class="px-0 pb-1 text-subtitle-1">Stripe PK</div>
                    <v-text-field
                    v-if="action == 'add'"
                    v-model="formdata.stripe_pk"
                    color="grey"
                    :rules="[formRules.required]"
                    height="42"
                    dense
                    outlined
                    ></v-text-field>
                    <v-text-field
                    v-else
                    :value="formdata.stripe_pk"
                    color="grey"
                    dense
                    height="42"
                    disabled
                    outlined
                    ></v-text-field>
                </v-col>
                <v-col cols="12">
                    <div class="px-0 pb-1 text-subtitle-1">Stripe SK</div>
                    <v-text-field
                    v-if="action == 'add'"
                    v-model="formdata.stripe_sk"
                    color="grey"
                    :rules="[formRules.required]"
                    height="42"
                    dense
                    outlined
                    ></v-text-field>
                    <v-text-field
                    v-else
                    :value="formdata.stripe_sk"
                    color="grey"
                    dense
                    height="42"
                    disabled
                    outlined
                    ></v-text-field>
                </v-col>
                <v-col cols="12">
                    <div class="px-0 pb-1 text-subtitle-1">Stripe Accnt ID</div>
                    <span class="flex-items">
                    <v-text-field
                    v-if="action == 'add'"
                    v-model="formdata.stripe_account_id"
                    color="grey"
                    :rules="[formRules.required]"
                    height="42"
                    dense
                    outlined
                    ></v-text-field>
                    <v-text-field
                    v-else
                    :value="formdata.stripe_account_id"
                    color="grey"
                    dense
                    height="42"
                    disabled
                    outlined
                    ></v-text-field>
                    <v-btn
                      v-if="action == 'add'"
                      color="grey"
                      class="white--text mb-6 ml-1"
                      depressed
                      height="42"
                      @click="getStripeAccountLiveDetails()"
                      :loading="loadingGet"
                    >
                      GET
                    </v-btn>
                    </span>
                </v-col>
                <v-col cols="12">
                    <div class="px-0 pb-2 text-subtitle-1">Max Revenue</div>
                    <div class="flex-items">
                      <v-autocomplete
                      v-model="formdata.max_revenue_type"
                      @change="changeRevenue(formdata.max_revenue_type)"
                      color="grey"
                      :rules="[formRules.required]"
                      height="42"
                      :items="max_revenue_list"
                      item-text="desc"
                      item-value="val"
                      outlined
                      ></v-autocomplete>
                      <v-text-field
                      v-if="show_custom"
                      v-model="formdata.max_revenue_amount"
                      color="grey"
                      class="ml-1"
                      :rules="[formRules.required]"
                      placeholder="Max Revenue Amount"
                      height="42"
                      outlined
                      ></v-text-field>
                    </div>
                </v-col>
                <v-col cols="12" v-if="show_services">
                  <v-sheet color="grey lighten-4" class="pa-3 mb-3 rounded">
                    <div class="px-0 pb-0 text-subtitle-1">Services</div>
                    <v-row no-gutters class="pa-3">
                    <v-col cols="12" sm="6" md="3" v-for="(val, mid) in mids" :key="mid + '_' + val" class="text-no-wrap py-0">
                      <v-checkbox :label="mid" v-model="formdata.mids[mid]" hide-details></v-checkbox>
                    </v-col>
                    </v-row>
                </v-sheet>
                </v-col>
                <v-col cols="12">
                  <v-sheet color="grey lighten-4" class="pa-3 mb-3 rounded">
                    <div class="px-0 pb-0 text-subtitle-1">Target Nationalities</div>
                    <v-row no-gutters class="pa-3">
                    <v-col cols="12" sm="6" md="3" v-for="(val, n) in target_nationalities" :key="n + '_' + val" class="text-no-wrap py-0">
                      <v-checkbox :label="n.replaceAll('_', ' ')" v-model="formdata.target_nationalities[n]" hide-details></v-checkbox>
                    </v-col>
                    </v-row>
                </v-sheet>
                </v-col>
                <v-col cols="12">
                  <v-sheet color="grey lighten-4" class="pa-3 mb-3 rounded">
                    <div class="px-0 pb-0 text-subtitle-1">Exclude Nationalities</div>
                    <v-row no-gutters class="pa-3">
                    <v-col cols="12" sm="6" md="3" v-for="(val, n) in exclude_nationalities" :key="n + '_' + val" class="text-no-wrap py-0">
                      <v-checkbox :label="n.replaceAll('_', ' ')" v-model="formdata.exclude_nationalities[n]" hide-details></v-checkbox>
                    </v-col>
                    </v-row>
                </v-sheet>
                </v-col>
                <v-col cols="12" v-if="show_instant_payout">
                    <div class="px-0 pb-2 text-subtitle-1">Instant Payout</div>
                    <div class="flex-items">
                      <v-autocomplete
                      v-model="formdata.instant_payout_type"
                      color="grey"
                      @change="showInstant(formdata.instant_payout_type)"
                      :rules="[formRules.required]"
                      height="42"
                      :items="instant_payout_list"
                      item-text="desc"
                      item-value="val"
                      outlined
                      ></v-autocomplete>
                      <v-text-field
                      v-if="show_instant"
                      v-model="formdata.instant_payout_limit"
                      color="grey"
                      class="ml-1"
                      :rules="[formRules.required]"
                      placeholder="Instant Payout Limit"
                      height="42"
                      outlined
                      ></v-text-field>
                    </div>
                </v-col>
                <v-col cols="12" v-if="action == 'add'">
                  <v-sheet color="grey lighten-4" class="mb-3 rounded">
                    <v-row no-gutters class="px-3">
                    <v-col cols="12" class="py-0 pb-5">
                      <v-checkbox label="Add to disputes table (as own)" v-model="formdata.add_to_disputes" hide-details></v-checkbox>
                    </v-col>
                    <v-col cols="12" class="py-0" v-if="formdata.add_to_disputes">
                      <div class="px-0 pb-0 text-subtitle-1">Company Name</div>
                      <v-text-field
                      v-model="formdata.company_name"
                      color="grey"
                      class="ml-1"
                      :rules="[formRules.required]"
                      height="42"
                      outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="py-0" v-if="formdata.add_to_disputes">
                      <div class="px-0 pb-0 text-subtitle-1">Safepage</div>
                      <v-text-field
                      v-model="formdata.safepage"
                      color="grey"
                      class="ml-1"
                      :rules="[formRules.required]"
                      height="42"
                      outlined
                      placeholder="example.com"
                      ></v-text-field>
                    </v-col>
                    </v-row>
                </v-sheet>
                </v-col>
              </v-row>
            </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="pa-3">
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            outlined
            class="mr-2"
            depressed
            @click="closeFormDialog()"
          >
            Cancel
          </v-btn>
          <v-btn
            color="#166ecf"
            class="white--text mr-5"
            depressed
            @click="confirmUpdateStripeAccount()"
            v-if="action == 'edit'"
            :loading="loadingForm"
          >
          <v-icon class="mr-1">mdi-square-edit-outline</v-icon>
            Update
          </v-btn>
          <v-btn
            color="#00a34e"
            class="white--text mr-5"
            depressed
            @click="confirmAddStripeAccount()"
            v-else-if="action == 'add'"
            :loading="loadingForm"
          >
          <v-icon class="mr-1">mdi-check-circle</v-icon>
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</div>
</template>
<script>
  export default {
    components: {
    },
    data: () => ({
      search: '',
      headers: [
          { text: 'NAME', value: 'name' },
          { text: 'ACCNT_ID', value: 'stripe_account_id'},
          { text: 'MIDS', value: 'mids', width: 650, sortable: false},
          { text: 'MAX REV.', value: 'max_revenue', align: 'end'},
          { text: 'ACTIONS', value: 'actions', align: 'center', sortable: false, },
        ],
      data: [],
      perPageChoices: [
          {text:'5' , value: 5},
          {text:'10' , value: 10},
          {text:'20' , value: 20},
          {text:'50' , value: 50},
          {text:'100' , value: 100},
          {text:'250' , value: 250},
          {text:'500' , value: 500},
      ],
      options: {},
      paginationData: {},
      loading: false,
      dialogConfirmation: false,
      loadingConfirm: false,
      edit_item: {},
      dialogForm: false,
      formdata: {},
      action: '',
      max_revenue_list: [
        {val: 'disabled', desc: '0 (Account disabled)'},
        {val: 'unlimited', desc: 'Unlimited'},
        {val: 'custom', desc: 'Custom'},
      ],
      instant_payout_list: [
        {val: 'disabled', desc: 'Disabled (No priority)'},
        {val: 'enabled', desc: 'Enabled (Prioritize account)'},
      ],
      loadingForm: false,
      mids: {},
      target_nationalities: {},
      exclude_nationalities: {},
      show_custom: false,
      show_instant: false,
      show_instant_payout: false,
      loadingAvailable: false,
      account_available: null,
      loadingGet: false,
      show_services: false,
      addnl_action: '',
    }),
    watch: {
      options: {
        handler () {
         this.initialize();
        },
        deep: true,
      },
      search: {
        handler () {
          setTimeout(() => {
            this.options.page = 1;
            this.initialize();
          }, 600);
        }
      },
    },
    mounted() {
      // this.getMids();
      // this.getNationalities();
    },
    methods: {
      initialize (){
        this.loading = true;
        let page = this.options.page;
        let itemPerPage = this.options.itemsPerPage;
        let sortBy = this.options.sortBy.length > 0 ? this.options.sortBy[0] : '';
        let order = this.options.sortBy.length > 0 ? this.options.sortDesc[0] ? 'desc' : 'asc' : '';
        let search = this.search;
        this.axiosCall('/config/getStripeAccounts?page=' + page + '&itemPerPage=' + itemPerPage + '&sortBy=' + sortBy + '&order=' + order + '&searchKey=' + search, 'GET').then((res) => {
              this.loading = false
              this.data = res.data.data
              this.data.forEach(item => {
                item.stripe_account_revenue_enabled = (item.max_revenue == null || item.max_revenue > 0);
                item.active_accounts = 0;
                for (let key in item.mids) {
                  item.active_accounts += parseInt(item.mids[key])
                }
                item.switch_enabled = item.stripe_account_revenue_enabled && item.active_accounts > 0
              });
              // console.log(this.data)
              this.paginationData = res.data.pagination;
          })
      },
      async getMids(){
        await this.axiosCall('/config/getMediaProperties', 'GET').then((res) => {
          this.mids = {};
            for (const mid of res.data) {
              this.mids[mid.media_property] = 0;
            }
          })
      },
      async getNationalities(){
        await this.axiosCall('/nationalities/getGroupNames', 'GET').then((res) => {
          this.target_nationalities = {};
          this.exclude_nationalities = {};
            for (const gn of res.data) {
              this.target_nationalities[gn.group_name] = 0;
              this.exclude_nationalities[gn.group_name] = 0;
            }
            // console.log("haha", res.data)
          })
      },
      async getAccountDetails(){
        let data = {
          name: this.formdata.name
        }
        this.axiosCall('/config/getAccountDetails', 'POST', data).then((res) => {
              let result = {...this.formdata, ...res.data.data}
              this.formdata = result;
          })
      },
      cancelSwitch(){
        this.edit_item.switch_enabled = !this.edit_item.switch_enabled;
        this.dialogConfirmation = false;
      },
      switchStripeAccount(item){
        if(item.switch_enabled){
          this.addnl_action = 'enable';
          this.edit(item);
        }
        else{
        this.edit_item = item;
        this.dialogConfirmation = true
        }
       },
       confirmSwitchStripeAccount(){
        this.loadingConfirm = true;
        let data = {
          name: this.edit_item.name,
          type: this.edit_item.switch_enabled ? 'enabled' : 'disabled',
          };
        this.axiosCall("/config/setStripeAccount", "POST", data).then((res) => {
          if (res.data.status == 200) {
            this.edit_item = {};
            this.showToast(res.data.msg, 'success')
          }
          else{
            this.edit_item.switch_enabled = !this.edit_item.switch_enabled
            this.showToast(res.data.msg, 'error')
          }
          this.loadingConfirm = false;
          this.dialogConfirmation = false;
        });
       },
       closeFormDialog(){
        if(this.addnl_action == 'enable'){
          this.formdata.switch_enabled = !this.formdata.switch_enabled;

        }
        this.addnl_action = '';
        this.formdata.target_nationalities = '';
        this.formdata.exclude_nationalities = '';
        this.$refs.formRef.resetValidation();
        this.dialogForm = false
       },
       async edit(item){
        await this.getMids();
        await this.getNationalities();
        this.action = 'edit';
        this.formdata = item;
        if(item.max_revenue_type){
          this.formdata.max_revenue_type = item.max_revenue_type;
        }
        else{
          this.formdata.max_revenue_type = parseFloat(item.max_revenue) == 0 ? 'disabled' : parseFloat(item.max_revenue) > 0 ? 'custom' : 'unlimited';
        }
        if(item.max_revenue_type == 'custom' || parseFloat(item.max_revenue) > 0){
            this.show_custom = true;
            this.formdata.max_revenue_amount = item.max_revenue;
          }
          else{
            this.show_custom = false;
          }
        if(parseFloat(item.instant_payout_limit) > 0){
          this.show_instant = true;
          this.formdata.instant_payout_type = 'enabled';
        }
        else{
          if(parseFloat(item.instant_payout_limit) == 0){
            this.formdata.instant_payout_type = 'disabled';
          }
          this.show_instant = false;
        }
        if(this.formdata.max_revenue_type == 'disabled'){
          this.show_instant_payout = false
          this.show_services = false
        }
        else{
          this.show_instant_payout = true
          this.show_services = true
        }
        if(item.target_nationalities){
          // console.log(item.nationalities)
          let gn = item.target_nationalities.split(',')
          let n_temp = JSON.parse(JSON.stringify(this.target_nationalities))
          for (const n in this.target_nationalities) {
            if(gn.includes(n)){
              n_temp[n] = 1;
            }
            else{
              n_temp[n] = 0;
            }
          }
          this.formdata.target_nationalities = JSON.parse(JSON.stringify(n_temp))
        }
        else{
          this.formdata.target_nationalities = JSON.parse(JSON.stringify(this.target_nationalities))
        }
        if(item.exclude_nationalities){
          // console.log(item.nationalities)
          let gn = item.exclude_nationalities.split(',')
          let n_temp = JSON.parse(JSON.stringify(this.exclude_nationalities))
          for (const n in this.exclude_nationalities) {
            if(gn.includes(n)){
              n_temp[n] = 1;
            }
            else{
              n_temp[n] = 0;
            }
          }
          this.formdata.exclude_nationalities = JSON.parse(JSON.stringify(n_temp))
        }
        else{
          this.formdata.exclude_nationalities = JSON.parse(JSON.stringify(this.exclude_nationalities))
        }
        // console.log(this.formdata)
        // await this.getAccountDetails();
        this.dialogForm = true
       },
       async add(){
        await this.getMids();
        await this.getNationalities();
        this.action = 'add';
        this.account_available = null;
        this.formdata = {};
        this.formdata.name = 'stripe_';
        this.formdata.max_revenue_type = 'unlimited';
        this.formdata.mids = JSON.parse(JSON.stringify(this.mids));
        this.formdata.target_nationalities = JSON.parse(JSON.stringify(this.target_nationalities));
        this.formdata.exclude_nationalities = JSON.parse(JSON.stringify(this.exclude_nationalities));
        this.formdata.company_name = 'LLC'
        if(this.formdata.max_revenue_type == 'disabled'){
          this.show_instant_payout = false
          this.show_services = false
        }
        else{
          this.show_instant_payout = true
          this.show_services = true
        }
        // await this.getAccountDetails();
        this.dialogForm = true
       },
       confirmAddStripeAccount(){
        if(this.$refs.formRef.validate()){
        this.loadingForm = true;
        let services = [];
        for (const mid in this.formdata.mids) {
          services.push({
            'type': mid,
            'value': this.formdata.mids[mid] ? 1 : 0,
          })
        }
        let target_nationalities = [];
        for (const n in this.formdata.target_nationalities) {
          if(this.formdata.target_nationalities[n] == 1){
            target_nationalities.push(n)
          }
        }
        let exclude_nationalities = [];
        for (const n in this.formdata.exclude_nationalities) {
          if(this.formdata.exclude_nationalities[n] == 1){
            exclude_nationalities.push(n)
          }
        }
        let data = {
          name: this.formdata.name,
          stripe_pk: this.formdata.stripe_pk || '',
          stripe_sk: this.formdata.stripe_sk || '',
          stripe_account_id: this.formdata.stripe_account_id || '',
          max_revenue_type: this.formdata.max_revenue_type || '',
          max_revenue_amount: this.formdata.max_revenue_amount || '',
          services: JSON.stringify(services),
          instant_payout_type: this.formdata.instant_payout_type || '',
          instant_payout_limit: this.formdata.instant_payout_limit || '',
          add_to_disputes: this.formdata.add_to_disputes ? 1 : 0,
          company_name: this.formdata.company_name || '',
          safepage: this.formdata.safepage || '',
          target_nationalities: target_nationalities.join(','),
          exclude_nationalities: exclude_nationalities.join(','),
        };
        this.axiosCall("/config/addAccount", "POST", data).then((res) => {
          if (res.data.status == 201) {
            this.formdata = {
              mids: JSON.parse(JSON.stringify(this.mids)),
              target_nationalities: JSON.parse(JSON.stringify(this.target_nationalities)),
              exclude_nationalities: JSON.parse(JSON.stringify(this.exclude_nationalities)),
            };
            this.initialize()
            this.showToast(res.data.msg, 'success')
            this.$refs.formRef.reset();
            this.closeFormDialog();
          }
          else{
            this.showToast(res.data.msg, 'error')
          }
          this.loadingForm = false;
        });
        }
       },
       confirmUpdateStripeAccount(){
        if(this.$refs.formRef.validate()){
        this.loadingForm = true;
        let services = [];
        for (const mid in this.formdata.mids) {
          services.push({
            'type': mid,
            'value': this.formdata.mids[mid] ? 1 : 0,
          })
        }
        let target_nationalities = [];
        for (const n in this.formdata.target_nationalities) {
          if(this.formdata.target_nationalities[n] == 1){
            target_nationalities.push(n)
          }
        }
        let exclude_nationalities = [];
        for (const n in this.formdata.exclude_nationalities) {
          if(this.formdata.exclude_nationalities[n] == 1){
            exclude_nationalities.push(n)
          }
        }
        let data = {
          name: this.formdata.name,
          stripe_pk: this.formdata.stripe_pk || '',
          stripe_sk: this.formdata.stripe_sk || '',
          stripe_account_id: this.formdata.stripe_account_id || '',
          max_revenue_type: this.formdata.max_revenue_type || '',
          max_revenue_amount: this.formdata.max_revenue_amount || '',
          services: JSON.stringify(services),
          instant_payout_type: this.formdata.instant_payout_type || '',
          instant_payout_limit: this.formdata.instant_payout_limit || '',
          target_nationalities: target_nationalities.join(','),
          exclude_nationalities: exclude_nationalities.join(','),
        };
        this.axiosCall("/config/updateAccount", "POST", data).then((res) => {
          if (res.data.status == 200) {
            this.formdata = {
              mids: JSON.parse(JSON.stringify(this.mids)),
              target_nationalities: JSON.parse(JSON.stringify(this.target_nationalities)),
              exclude_nationalities: JSON.parse(JSON.stringify(this.exclude_nationalities)),
            };
            this.initialize()
            this.showToast(res.data.msg, 'success')
            this.$refs.formRef.reset();
            this.closeFormDialog();
          }
          else{
            this.showToast(res.data.msg, 'error')
          }
          this.loadingForm = false;
        });
        }
       },
       changeRevenue(type){
        if(type == 'custom'){
          this.show_custom = true;
        }
        else {
          this.show_custom = false
        }
        if(type == 'disabled' || this.formdata.max_revenue_amount < 0){
          this.show_instant_payout = false
          this.show_services = false
        }
        else{
          this.show_instant_payout = true
          this.show_services = true
        }
       },
       showInstant(type){
        if(type == 'enabled') {
          this.show_instant = true;
        }
        else {
          this.show_instant = false
        }
       },
       checkAvailability(){
        this.loadingAvailable = true;
        let data = {
          name: this.formdata.name,
          };
        this.axiosCall("/config/checkAccountAvailability", "POST", data).then((res) => {
          if (res.data.status == 200) {
            this.account_available = true;
            this.showToast(res.data.msg, 'success')
          }
          else{
            this.account_available = false;
            this.showToast(res.data.msg, 'error')
          }
          this.loadingAvailable = false;
        });
       },
       getStripeAccountLiveDetails(){
        if(this.formdata.stripe_sk){
        this.loadingGet = true;
        let data = {
          stripe_sk: this.formdata.stripe_sk,
          };
        this.axiosCall("/config/getStripeAccountLiveDetails", "POST", data).then((res) => {
          if (res.data.status == 200) {
            this.formdata.stripe_account_id = res.data.data.id;
            this.showToast(res.data.msg, 'success')
          }
          else{
            this.account_available = false;
            this.showToast(res.data.msg, 'error')
          }
          this.loadingGet = false;
        });
       }
       else{
        this.showToast('Please provide Stripe Secret Key.', 'error')
       }
       }
    }
  }
</script>
<style scoped>
.chip-status-1{
  background-color: #d7f7c2 !important;
  color: #2f8547 !important;
}
.chip-status-2{
  background-color: #cff5f6 !important;
  color: #1066c5 !important;
}
.chip-status-3{
  background-color: #ebeef1 !important;
  color: #616f7e !important;
}
.chip-status-4{
  background-color: #fcedb9 !important;
  color: #b02c00 !important;
}
.chip-status-5{
  background-color: #ffe7f2 !important;
  color: #c4093c !important;
}
.chip-status-6{
  background-color: #F0FFF8 !important;
  color: #18AB56 !important;
}
.chip-status-7{
  background-color: #fbe996 !important;
  color: #413a29 !important;
}
</style>