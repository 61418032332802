<template>
  <div class="pa-5">
    <v-card class="card-style rounded-1" >
    <v-row class="ma-2">
      <v-col cols="12" md="9" class="flex-items">
        <h2>Disputes</h2><h3 v-if="$store.state.user.role == 'admin'" class="ml-1">(Unpaid Disputes: <b style="font-size: 25px;">{{unpaid_disputes.unpaid_count}}</b>, as of {{convertToEst(unpaid_disputes.unpaid_timestamp) + ' EST'}})</h3>
        <v-btn v-if="$store.state.user.role == 'admin'" color="#09a5be" depressed dark small class="ml-2" @click="getDisputesSummary()">Summary</v-btn>
      </v-col>
      <v-col cols="12" md="3">
      <v-text-field
          v-model="search"
          prepend-inner-icon="search"
          label="Search disputes"
          single-line
          hide-details
          color="#38435a"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-card class="mb-1 dt-container" elevation="0" outlined>
    <v-data-table
      :loading="loading"
       :headers="headers"
        :items="data"
        item-key="id"
        :options.sync="options"
        hide-default-footer
    >
    <template v-slot:[`item.disputeToken`]="{ item }">
      <span>#{{item.disputeToken}}</span>
    </template>
    <template v-slot:[`item.client`]="{ item }">
      <span class="flex-items"><v-icon class="mb-1">mdi-home-outline</v-icon>{{item.client}}</span>
    </template>
    <template v-slot:[`item.status`]="{ item }">
      <v-chip
        class="chip-status-1 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap"
        v-if="item.submitted == 1"
        small
      >
       <span>Submitted</span>
      </v-chip>
      <v-chip v-else-if="item.status == 'pending'"
        class="chip-status-1 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap"
        small
      >
        <span>Pending</span>
      </v-chip>
      <v-chip v-else-if="item.status == 'awaiting_submission'"
        class="chip-status-2 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap"
        small
      >
      <span>Awaiting Submission</span>
    </v-chip>
    <span v-if="$store.state.user.role == 'admin'">
      <v-chip
        class="chip-status-1 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap"
        v-if="item.paid == 1"
        small
      >
       <span>Paid</span>
      </v-chip>
      <v-chip
        class="chip-status-4 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap"
        v-else-if="item.own == 0"
        small
      >
       <span>Unpaid</span>
      </v-chip>
    </span>
    </template>
    <template v-slot:[`item.note`]="{ item }">
        <span v-if="item.note"><i>{{mbStrimwidth(item.note.replaceAll("_", " "), 0, 50, "...")}}</i></span>
    </template>
        <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                outlined
                color="#166ecf"
                :to="'/' + $store.state.user.role + '/disputes/view/'+ item.disputeToken + '/'"
              >
              <v-icon class="mr-1">mdi-eye</v-icon>
              View
              </v-btn>
        </template>
    </v-data-table>
    </v-card>
    <v-row class="ma-2" align="center" justify="center">
    <v-col cols="auto" class="flex-items">
        <span class="px-2">Row Per Page: </span>
        <span>
          <v-select
            dense
            outlined
            hide-details
            :value="options.itemsPerPage"
            style="max-width: 90px"
            class="rounded-lg"
            @change="options.itemsPerPage = parseInt($event, 10)"
            :items="perPageChoices"
          >
          </v-select>
        </span>
    </v-col>
    <v-col cols="auto" class="mx-auto text-truncate" no-gutters>
    Showing {{ data.length > 0 ? ((paginationData.currentPage - 1) * options.itemsPerPage) + 1 : 0 }} to {{ data.length > 0 ? ((paginationData.currentPage - 1) * options.itemsPerPage) + data.length : 0 }} of {{ paginationData.count }} entries
    </v-col>
    <v-col cols="auto">
        <v-pagination
            v-model="options.page"
            class="rounded-lg"
            :total-visible="7"
            color="#38435a"
            :length="paginationData.lastPage">
        </v-pagination>
    </v-col>
      </v-row>
    </v-card>

    <v-dialog
      v-model="dialogSummary"
      width="800"
      scrollable
      persistent
    >
      <v-card>
        <v-card-title>
          <strong>Disputes Summary</strong>
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="dialogSummary = false"
          >
          <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pt-2" style="max-height: 900px;">
            <v-data-table
              :loading="loading"
              :headers="headers_summary"
                :items="data_summary"
                item-key="alias"
                :options.sync="options"
                hide-default-footer
            >
            <template v-slot:[`item.num`]="{ index }">
              <span>{{index + 1}}</span>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                color="#fcedb9"
                @click="markPaid(item)"
                style="color: #b35059;"
                class="font-weight-bold"
                small
              >
              Mark Paid
              </v-btn>
        </template>
            </v-data-table>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="pa-3">
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            outlined
            class="mr-2"
            depressed
            @click="dialogSummary = false"
          >
            close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogConfirmation"
      width="520"
      persistent
    >
      <v-card>
        <v-card-title class="text-subtitle-1">
          <strong>Confirmation</strong>
        </v-card-title>

        <v-card-text>
            <div class="subtitle-1">
              Are you sure you want to mark unpaid disputes as <strong>PAID</strong>?
            </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            outlined
            class="mr-2"
            depressed
            @click="dialogConfirmation = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="#166ecf"
            class="white--text"
            depressed
            @click="confirmMarkPaid()"
            :loading="loadingConfirm"
          >
            Yes, Mark Paid
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</div>
</template>
<script>
  export default {
    props: {
      tab: null,
    },
    components: {
    },
    data: () => ({
      search: '',
      headers: [
          { text: 'REF#', value: 'disputeToken' },
          { text: 'TIMESTAMP', value: 'disputeTimestamp'},
          { text: 'CLIENT', value: 'client'},
          { text: 'STATUS', value: 'status'},
          { text: 'NOTE', value: 'note', },
          { text: 'ACTIONS', value: 'actions' },
        ],
      data: [],
      perPageChoices: [
          {text:'5' , value: 5},
          {text:'10' , value: 10},
          {text:'20' , value: 20},
          {text:'50' , value: 50},
          {text:'100' , value: 100},
          {text:'250' , value: 250},
          {text:'500' , value: 500},
      ],
      options: {},
      paginationData: {},
      loading: false,
      formdata: [],
      work_dates_menu: false,
      fadeAwayMessage: {
      show: false,
      type: "success",
      header: "Successfully Added!",
      message: "",
      top: 10,
    },
    completeData: [],
    checkData: [],
    viewData: [],
    editData: [],
    unpaid_disputes: {},
    dialogSummary: false,
    headers_summary: [
          { text: '#', value: 'num' },
          { text: 'ALIAS', value: 'alias'},
          { text: 'UNPAID COUNT', value: 'unpaid_count'},
          { text: 'DUE AMOUNT', value: 'due_amount'},
          { text: '', value: 'actions' },
        ],
    data_summary: [],
    dialogConfirmation: false,
    loadingConfirm: false,
    data_paid: {},
    }),
    watch: {
      options: {
        handler () {
         this.initialize();
        },
        deep: true,
      },
      tab: {
        handler () {
        this.options.page = 1;
        this.initialize();
        },
        deep: true,
      },
      search: {
        handler () {
          setTimeout(() => {
            this.options.page = 1;
            this.initialize();
          }, 600);
        },
      },
    },
    created() {
      if(this.$store.state.user.role == 'admin'){
      this.headers = [
          { text: 'REF#', value: 'disputeToken' },
          { text: 'TIMESTAMP', value: 'disputeTimestamp'},
          { text: 'ALIAS', value: 'clients.alias'},
          { text: 'CLIENT', value: 'client'},
          { text: 'STATUS', value: 'status'},
          { text: 'NOTE', value: 'note' },
          { text: 'ACTIONS', value: 'actions' },
        ];
      }
      this.getUnpaidDisputes();
    },
    mounted() {
    },
    methods: {
      initialize (){
        this.loading = true;
        let page = this.options.page;
        let itemPerPage = this.options.itemsPerPage;
        let sortBy = this.options.sortBy.length > 0 ? this.options.sortBy[0] : '';
        let order = this.options.sortBy.length > 0 ? this.options.sortDesc[0] ? 'desc' : 'asc' : '';
        let search = this.search;
        this.axiosCall('/clients-disputes/getClientsDisputes?page=' + page + '&itemPerPage=' + itemPerPage + '&sortBy=' + sortBy + '&order=' + order + '&searchKey=' + search, 'GET').then((res) => {
              this.loading = false
              this.data = res.data.data
              console.log(this.data)
              this.paginationData = res.data.pagination;
          })
      },
      getUnpaidDisputes(){
        this.axiosCall("/clients-disputes/unpaidDisputes", "GET").then((res) => {
          this.unpaid_disputes = res.data
        });
       },
       getDisputesSummary(){
        this.axiosCall("/clients-disputes/getDisputesSummary", "GET").then((res) => {
          if(res.data){
            this.dialogSummary = true;
            this.data_summary = res.data
          }
        });
       },
       convertToEst(isoDate) {
          const date = new Date(isoDate);

          const day = String(date.getDate()).padStart(2, '0');
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const year = date.getFullYear();

          const hours = String(date.getHours()).padStart(2, '0');
          const minutes = String(date.getMinutes()).padStart(2, '0');
          const seconds = String(date.getSeconds()).padStart(2, '0');

          return `${month}-${day}-${year}, ${hours}:${minutes}:${seconds}`;
      },
      markPaid(data){
        this.data_paid = data;
        this.dialogConfirmation = true;
      },
      confirmMarkPaid(){
        this.loadingConfirm = true;
        let data = {
          alias: this.data_paid.alias,
          count: parseInt(this.data_paid.count),
          };
        this.axiosCall("/clients_disputes/markAsPaid", "POST", data).then((res) => {
          if (res.data.status == 200) {
            this.data_paid = {};
            this.showToast(res.data.msg, 'success')
          }
          else{
            this.showToast(res.data.msg, 'error')
          }
          this.loadingConfirm = false;
          this.dialogConfirmation = false;
        });
      }
    }
  }
</script>
<style scoped>
.chip-status-1{
  background-color: #d7f7c2 !important;
  color: #2f8547 !important;
}
.chip-status-2{
  background-color: #cff5f6 !important;
  color: #1066c5 !important;
}
.chip-status-3{
  background-color: #ebeef1 !important;
  color: #616f7e !important;
}
.chip-status-4{
  background-color: #fcedb9 !important;
  color: #b02c00 !important;
}
.chip-status-5{
  background-color: #ffe7f2 !important;
  color: #c4093c !important;
}
.chip-status-6{
  background-color: #F0FFF8 !important;
  color: #18AB56 !important;
}
</style>