<template>
<div class="mt-n12 pa-10">
<div>
<div class="row">
    <div class="col-lg-12" style="text-align: left;margin-top: 20px;">
        <v-btn @click="captureConsent(data.disputeSignature)" dark>Download Customer Communication</v-btn>
    </div>
</div><br><br><br>
<div id="communicationGrabber" style="max-width: 900px;">
    <span style="font: small/1.5 Arial,Helvetica,sans-serif"><b>From:</b> {{data.clients.name}} &lt;orders@{{data.clients.name}}&gt;<br>
    <b>Date:</b> {{emailTimestamp}}<br>
    <b>To:</b> <a :href="`mailto:${data.data.data.object.evidence.customer_email_address}`" target="_blank">{{data.disputeSignature}}</a> &lt;<a :href="`mailto:${data.data.data.object.evidence.customer_email_address}`" target="_blank">{{ data.data.data.object.evidence.customer_email_address }}</a>&gt;<br>
    <b>Subject:</b> Your order from {{ data.clients.name }} has been received!<br></span>
    <br>
    <blockquote type="cite">
        <table style="background-color:#f7f7f7" id="m_2684013898126309500outer_wrapper" width="100%" bgcolor="#F7F7F7">
            <tbody>
            <tr>
                <td></td>
                <td width="600">
                    <div width="100%" style="margin:0 auto;padding:70px 0;width:100%;max-width:600px" dir="ltr" id="m_2684013898126309500wrapper">
                        <table width="100%" height="100%" cellspacing="0" cellpadding="0" border="0">
                            <tbody>
                            <tr>
                                <td valign="top" align="center">
                                    <div id="m_2684013898126309500template_header_image"></div>
                                    <table style="background-color:#fff;border:1px solid #dedede;border-radius:3px" id="m_2684013898126309500template_container" width="100%" cellspacing="0" cellpadding="0" border="0" bgcolor="#fff">
                                        <tbody>
                                        <tr>
                                            <td valign="top" align="center">
                                                <table style="background-color:#96588a;color:#fff;border-bottom:0;font-weight:bold;line-height:100%;vertical-align:middle;font-family:&quot;Helvetica Neue&quot;,Helvetica,Roboto,Arial,sans-serif;border-radius:3px 3px 0 0" id="m_2684013898126309500template_header" width="100%" cellspacing="0" cellpadding="0" border="0" bgcolor="#96588A">
                                                    <tbody>
                                                    <tr>
                                                        <td style="padding:36px 48px;display:block" id="m_2684013898126309500header_wrapper">
                                                            <h1 bgcolor="inherit" style="font-family:&quot;Helvetica Neue&quot;,Helvetica,Roboto,Arial,sans-serif;font-size:30px;font-weight:300;line-height:150%;margin:0;text-align:left;color:#fff;background-color:inherit">Thank you for your order</h1>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top" align="center">
                                                <table id="m_2684013898126309500template_body" width="100%" cellspacing="0" cellpadding="0" border="0">
                                                    <tbody>
                                                    <tr>
                                                        <td style="background-color:#fff" id="m_2684013898126309500body_content" valign="top" bgcolor="#fff">
                                                            <table width="100%" cellspacing="0" cellpadding="20" border="0">
                                                                <tbody>
                                                                <tr>
                                                                    <td style="padding:48px 48px 32px" valign="top">
                                                                        <div style="color:#636363;font-family:&quot;Helvetica Neue&quot;,Helvetica,Roboto,Arial,sans-serif;font-size:14px;line-height:150%;text-align:left" id="m_2684013898126309500body_content_inner" align="left">
                                                                            <p style="margin:0 0 16px">Hi {{ data.disputeSignature }},</p>
                                                                            <p style="margin:0 0 16px">Just to let you know — we've received your order #{{orderNumber}}, and it is now being processed:</p>
                                                                            <h2 style="color:#96588a;display:block;font-family:&quot;Helvetica Neue&quot;,Helvetica,Roboto,Arial,sans-serif;font-size:18px;font-weight:bold;line-height:130%;margin:0 0 18px;text-align:left">[Order #{{orderNumber}}] ({{new Date(emailTimestamp).toUTCString().split(' ').slice(1, 4).join(' ').replace(',', '')}})</h2>
                                                                            <div style="margin-bottom:40px">
                                                                                <table style="color:#636363;border:1px solid #e5e5e5;vertical-align:middle;width:100%;font-family:'Helvetica Neue',Helvetica,Roboto,Arial,sans-serif" width="100%" cellspacing="0" cellpadding="6" border="1">
                                                                                    <thead>
                                                                                    <tr>
                                                                                        <th style="color:#636363;border:1px solid #e5e5e5;vertical-align:middle;padding:12px;text-align:left" scope="col" align="left">Product</th>
                                                                                        <th style="color:#636363;border:1px solid #e5e5e5;vertical-align:middle;padding:12px;text-align:left" scope="col" align="left">Quantity</th>
                                                                                        <th style="color:#636363;border:1px solid #e5e5e5;vertical-align:middle;padding:12px;text-align:left" scope="col" align="left">Price</th>
                                                                                    </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                    <tr>
                                                                                        <td style="color:#636363;border:1px solid #e5e5e5;padding:12px;text-align:left;vertical-align:middle;font-family:'Helvetica Neue',Helvetica,Roboto,Arial,sans-serif;word-wrap:break-word" align="left">VIP Membership</td>
                                                                                        <td style="color:#636363;border:1px solid #e5e5e5;padding:12px;text-align:left;vertical-align:middle;font-family:'Helvetica Neue',Helvetica,Roboto,Arial,sans-serif" align="left">1</td>
                                                                                        <td style="color:#636363;border:1px solid #e5e5e5;padding:12px;text-align:left;vertical-align:middle;font-family:'Helvetica Neue',Helvetica,Roboto,Arial,sans-serif" align="left"><span><span></span>{{ (parseFloat(data.data.data.object.amount)/100).toFixed(2) + " " + (data.data.data.object.currency || '').toUpperCase() }}</span></td>
                                                                                    </tr>
                                                                                    </tbody>
                                                                                    <tfoot>
                                                                                    <tr>
                                                                                        <th style="color:#636363;border:1px solid #e5e5e5;vertical-align:middle;padding:12px;text-align:left;border-top-width:4px" colspan="2" scope="row" align="left">Subtotal:</th>
                                                                                        <td style="color:#636363;border:1px solid #e5e5e5;vertical-align:middle;padding:12px;text-align:left;border-top-width:4px" align="left"><span><span></span>{{ (parseFloat(data.data.data.object.amount)/100).toFixed(2) + " " + (data.data.data.object.currency || '').toUpperCase() }}</span></td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <th style="color:#636363;border:1px solid #e5e5e5;vertical-align:middle;padding:12px;text-align:left" colspan="2" scope="row" align="left">Shipping:</th>
                                                                                        <td style="color:#636363;border:1px solid #e5e5e5;vertical-align:middle;padding:12px;text-align:left" align="left">Free shipping</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <th style="color:#636363;border:1px solid #e5e5e5;vertical-align:middle;padding:12px;text-align:left" colspan="2" scope="row" align="left">Payment method:</th>
                                                                                        <td style="color:#636363;border:1px solid #e5e5e5;vertical-align:middle;padding:12px;text-align:left" align="left">Credit Card (Stripe)</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <th style="color:#636363;border:1px solid #e5e5e5;vertical-align:middle;padding:12px;text-align:left" colspan="2" scope="row" align="left">Total:</th>
                                                                                        <td style="color:#636363;border:1px solid #e5e5e5;vertical-align:middle;padding:12px;text-align:left" align="left"><span><span></span>{{ (parseFloat(data.data.data.object.amount)/100).toFixed(2) + " " + (data.data.data.object.currency || '').toUpperCase() }}</span></td>
                                                                                    </tr>
                                                                                    </tfoot>
                                                                                </table>
                                                                            </div>
                                                                            <div style="margin-bottom:40px">
                                                                                <h2 style="color:#96588a;display:block;font-family:&quot;Helvetica Neue&quot;,Helvetica,Roboto,Arial,sans-serif;font-size:18px;font-weight:bold;line-height:130%;margin:0 0 18px;text-align:left">Subscription information</h2>
                                                                                <table style="color:#636363;border:1px solid #e5e5e5;vertical-align:middle;width:100%;font-family:'Helvetica Neue',Helvetica,Roboto,Arial,sans-serif;margin-bottom:0.5em" width="100%" cellspacing="0" cellpadding="6" border="1">
                                                                                    <thead>
                                                                                    <tr>
                                                                                        <th style="color:#636363;border:1px solid #e5e5e5;vertical-align:middle;padding:12px;text-align:left" scope="col" align="left">ID</th>
                                                                                        <th style="color:#636363;border:1px solid #e5e5e5;vertical-align:middle;padding:12px;text-align:left" scope="col" align="left">Start date</th>
                                                                                        <th style="color:#636363;border:1px solid #e5e5e5;vertical-align:middle;padding:12px;text-align:left" scope="col" align="left">End date</th>
                                                                                        <th style="color:#636363;border:1px solid #e5e5e5;vertical-align:middle;padding:12px;text-align:left" scope="col" align="left">Recurring total</th>
                                                                                    </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                    <tr>
                                                                                        <td style="color:#636363;border:1px solid #e5e5e5;vertical-align:middle;padding:12px;text-align:left" scope="row" align="left"><strong>#{{ orderNumber }}</strong></td>
                                                                                        <td style="color:#636363;border:1px solid #e5e5e5;vertical-align:middle;padding:12px;text-align:left" scope="row" align="left">{{new Date(emailTimestamp).toUTCString().split(' ').slice(1, 4).join(' ').replace(',', '')}}</td>
                                                                                        <td style="color:#636363;border:1px solid #e5e5e5;vertical-align:middle;padding:12px;text-align:left" scope="row" align="left">When cancelled</td>
                                                                                        <td style="color:#636363;border:1px solid #e5e5e5;vertical-align:middle;padding:12px;text-align:left" scope="row" align="left"><span><span></span>{{ (parseFloat(data.data.data.object.amount)/100).toFixed(2) + " " + (data.data.data.object.currency || '').toUpperCase() }}</span> / month<br>
                                                                                            <small>Next payment: {{new Date(new Date(emailTimestamp).setMonth(new Date(emailTimestamp).getMonth() + 1)).toUTCString().split(' ').slice(1, 4).join(' ').replace(',', '')}}</small></td>
                                                                                    </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                                <small>This subscription is set to renew automatically using your payment method on file. You can manage or cancel this subscription from your my account pagе.</small></div>
                                                                            <table style="width:100%;vertical-align:top;margin-bottom:40px;padding:0" id="m_2684013898126309500addresses" width="100%" cellspacing="0" cellpadding="0" border="0">
                                                                                <tbody>
                                                                                <tr>
                                                                                        <td v-if="data.clients.own == 0" style="text-align:left;font-family:'Helvetica Neue',Helvetica,Roboto,Arial,sans-serif;border:0;padding:0" width="50%" valign="top" align="left">
                                                                                            <h2 style="color:#96588a;display:block;font-family:&quot;Helvetica Neue&quot;,Helvetica,Roboto,Arial,sans-serif;font-size:18px;font-weight:bold;line-height:130%;margin:0 0 18px;text-align:left">Billing address</h2>
                                                                                            <address style="padding:12px;color:#636363;border:1px solid #e5e5e5">{{data.disputeSignature}}<br>
                                                                                                {{ data.data.data.object.evidence.billing_address.replace(/\n/g, '<br>').replace(/ , /g, ',') }}<br>
                                                                                                <a :href="`mailto:${data.data.data.object.evidence.customer_email_address}`" target="_blank">{{ data.data.data.object.evidence.customer_email_address }}</a></address>
                                                                                        </td>
                                                                                        <td v-if="data.clients.own == 0" style="text-align:left;font-family:'Helvetica Neue',Helvetica,Roboto,Arial,sans-serif;padding:0" width="50%" valign="top" align="left">
                                                                                            <h2 style="color:#96588a;display:block;font-family:&quot;Helvetica Neue&quot;,Helvetica,Roboto,Arial,sans-serif;font-size:18px;font-weight:bold;line-height:130%;margin:0 0 18px;text-align:left">Shipping address</h2>
                                                                                            <address style="padding:12px;color:#636363;border:1px solid #e5e5e5">{{ data.disputeSignature }}<br>
                                                                                                {{ data.data.data.object.evidence.billing_address.replace(/\n/g, '<br>').replace(/ , /g, ',') }}</address>
                                                                                        </td>

                                                                                        <td v-if="data.clients.own == 1" style="text-align:left;font-family:'Helvetica Neue',Helvetica,Roboto,Arial,sans-serif;padding:0" width="100%" valign="top" align="left">
                                                                                            <h2 style="color:#96588a;display:block;font-family:&quot;Helvetica Neue&quot;,Helvetica,Roboto,Arial,sans-serif;font-size:18px;font-weight:bold;line-height:130%;margin:0 0 18px;text-align:left">Shipping address</h2>
                                                                                            <address style="padding:12px;color:#636363;border:1px solid #e5e5e5">{{ data.disputeSignature }}
                                                                                                <br>Digital Delivery via email, sent to: <a href="">{{ data.disputeEmail}}</a>
                                                                                            </address>

                                                                                        </td>


                                                                                </tr>
                                                                                </tbody>
                                                                            </table>
                                                                            <p style="margin:0 0 16px">Thank you for shoping with us @ {{ data.clients.name }}.<br>Your support is greatly appreciated!</p>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td valign="top" align="center">
                                    <table id="m_2684013898126309500template_footer" width="100%" cellspacing="0" cellpadding="10" border="0">
                                        <tbody>
                                        <tr>
                                            <td style="padding:0;border-radius:6px" valign="top">
                                                <table width="100%" cellspacing="0" cellpadding="10" border="0">
                                                    <tbody>
                                                    <tr>
                                                        <td style="border-radius:6px;border:0;color:#8a8a8a;font-family:&quot;Helvetica Neue&quot;,Helvetica,Roboto,Arial,sans-serif;font-size:12px;line-height:150%;text-align:center;padding:24px 0" id="m_2684013898126309500credit" colspan="2" valign="middle" align="center">
                                                            <p style="margin:0 0 16px">{{ data.clients.name }} — Built with <a style="color:#96588a;font-weight:normal;text-decoration:underline" href="https://woocommerce.com" rel="noreferrer nofollow noopener" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://woocommerce.com&amp;source=gmail&amp;ust=1691210433726000&amp;usg=AOvVaw3tRk74GKHRMsudTVRgEiqH">WooCommerce</a></p>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </td>
                <td></td>
            </tr>
            </tbody>
        </table><div class="yj6qo"></div><div class="adL">
        </div></blockquote><div class="adL">
        <br></div></div>
</div>
</div>
</template>
<script>
import html2canvas from 'html2canvas';
  export default {
    props: {
        data: {},
        // capture: null
    },
    data: () => ({
        orderNumber: null,
        emailTimestamp: null,
        disputeJson: null
    }),
    watch: {
      data: {
        handler(data){
            this.orderNumber = 14472 + parseInt(data.id, 10);
            const emailDate = new Date(new Date(data.chargeTimestamp).getTime() + (Math.floor(Math.random() * 31) + 15) * 1000);
            this.emailTimestamp = `${emailDate.toUTCString().split(' ')[2]} ${emailDate.getUTCDate()}, ${emailDate.getUTCFullYear()}, ${(emailDate.getUTCHours() % 12 || 12)}:${String(emailDate.getUTCMinutes()).padStart(2, '0')} ${emailDate.getUTCHours() >= 12 ? 'PM' : 'AM'}`;
            this.disputeJson = JSON.parse(data.data.replace(/\n/g, ' '));
        },
        deep: true
      },
    //   capture: {
    //     handler(data){
    //         if(data == true){
    //         this.captureConsent(this.data.disputeSignature)
    //         }
    //     },
    //   }
    },
    methods: {
        captureConsent(name) {
            let element = document.querySelector('#communicationGrabber');
        
            html2canvas(element).then(canvas => {
                // Hide the canvas to avoid displaying it on the page
                canvas.style.display = 'none';
                document.body.appendChild(canvas);
        
                // Convert the canvas to a JPG image
                let image = canvas.toDataURL('image/jpg');
        
                // Create a download link
                let downloadLink = document.createElement('a');
                downloadLink.setAttribute('download', name + ' - Customer Communication.jpg');
                downloadLink.setAttribute('href', image);
        
                // Trigger the download
                downloadLink.click();
        
                // Clean up
                canvas.remove();
            })
          },
          initialize(){
          let data = {
          dispute_token: this.$route.params.ref,
          };
            this.axiosCall("/clients-disputes/getByToken", "POST", data).then((res) => {
            if (res.status == 201) {
                let temp_data = res.data
                temp_data.data = JSON.parse(temp_data.data)
                this.data = temp_data;
            }
            });
        }
    },
    mounted(){
        if(this.$route.params.ref){
        this.initialize();
        }
    },
  }
</script>